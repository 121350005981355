<template>
  <div class="tab-pane active in" id="tab4-3">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="!self || (!showEdit && rndCapacityId)">
      <div
        class="row"
        v-for="(key, index) in Object.keys(rndCapacity)"
        :key="key"
      >
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>
        <div
          class="col-md-6 d-flex justify-content-end align-items-center"
          @click="showEdit = true"
          v-if="self && index == 0"
        >
          <i class="fas fa-edit "></i>
        </div>
        <div class="row" v-for="value in rndCapacity[key]" :key="value.name">
          <div class="pt-4 pb-4" v-if="value.name !== 'Picture'">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>

    <form v-else class="form account-details-form" @submit.prevent="save">
      <div v-for="key in Object.keys(rndCapacity)" :key="key">
        <h4 class="title title-password ls-25 font-weight-bold">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>
        <div class="row">
          <div
            class="col-md-6"
            v-for="value in rndCapacity[key]"
            :key="value.name"
          >
            <div class="form-group" v-if="value.name !== 'Picture'">
              <label for=""
                ><h5>
                  {{
                    value.name
                      .split(/(?=[A-Z])/)
                      .reduce((s, str) => s + str + ' ', '')
                  }}
                  *
                </h5></label
              >
              <input
                type="text"
                id=""
                name=""
                required
                class="form-control form-control-md"
                maxlength="150"
                pattern="[a-zA-Z0-9-._\s]+"
                title="text and number only"
                v-model="value.des"
              />
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-dark btn-rounded btn-sm mb-4">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const { mapGetters } = createNamespacedHelpers('auth');
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  data() {
    return {
      showEdit: false,
      loading: false,
      rndCapacityId: '',
      sellerId: this.$route.params.id,
      rnd: {
        ProductionCertification: [
          {
            name: 'Picture',
            des:
              'https://sc04.alicdn.com/kf/H92ff603788f94f87b546933ccb611bccL.jpg',
          },
        ],
        Certification: [
          { name: 'Certification Name', des: 'ISO9001' },
          {
            name: 'Issued By',
            des: 'Quality Assurance Centre of China Association',
          },
        ],
        AwardsCertification: [
          { name: 'Name', des: 'Verified Supplier Certificate' },
          { name: 'Issued By', des: 'TUV' },
        ],
      },
    };
  },
  computed: {
    self() {
      return this.userInfo.id === this.sellerId;
    },
    rndCapacity() {
      if (this.rndCapacityBySellerId) {
        const { _id, customerid, ...co } = this.rndCapacityBySellerId;
        this.setRndCapacityId(_id);
        return co;
      }

      return {};
    },
    rndCapacityBySellerId() {
      if (this.rndCapacities) {
        return this.rndCapacities[this.sellerId || this.userInfo.id];
      }
      return null;
    },
    ...mapGetters(['userInfo']),
    ...profileMapGetters(['rndCapacities']),
  },
  mounted() {
    if (this.rndCapacityId == '') {
      this.getRndCapacity();
    }
  },
  methods: {
    setRndCapacityId(id) {
      if (id) this.rndCapacityId = id;
    },
    getRndCapacity() {
      this.loading = true;
      ProfileService.getRndCapacity(this.sellerId || this.userInfo.id)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addRndCapacity', cover);
          } else {
            co = this.rnd;
            co.customerid = this.sellerId || this.userInfo.id;
            this.$store.dispatch('companyProfile/addRndCapacity', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async save() {
      const payload = {
        ...this.rndCapacity,
        customerid: this.userInfo.id,
      };
      try {
        this.loading = true;
        if (!this.rndCapacityId || this.rndCapacityId == '') {
          console.log('create quality');
          await ProfileService.createRndCapacity(payload);
        } else {
          await ProfileService.updateRndCapacity(payload, this.rndCapacityId);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.showEdit = false;
      this.getRndCapacity();
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
