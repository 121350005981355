<template>
  <div>
    <!-- Start of Header -->
    <header class="header">
      <div class="header-middle">
        <div class="container">
          <div class="header-left mr-md-4">
            <a
              href="javascript:void(0);"
              class="mobile-menu-toggle w-icon-hamburger"
            ></a>
            <router-link to="/productcatalog" class="logo ml-lg-0">
              <!-- <a href="" > -->
              <img src="/images/logo.png" alt="logo" width="145" height="45" />
              <!-- </a> -->
            </router-link>
          </div>
          <div class="header-right ml-4">
            <div class="d-xs-show d-lg-flex align-items-center dropdown">
              <a
                href="javascript:void(0);"
                @click.prevent
                class="user-account ml-lg-0"
              >
                <div class="ml-7">
                  Hello, {{ userName }}
                  <span>Account & Lists</span>
                </div>
              </a>
              <div class="dropdown-box pt-4 pb-4">
                <a
                  href="javascript:void(0);"
                  @click.prevent="logoutHandler"
                  class="dropdown-item"
                  >{{ loginButton }}</a
                >
                <router-link
                  :to="'/profile/' + userInfo.id"
                  class="dropdown-item"
                  v-if="!isAdmin"
                  >Profile</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header-bottom sticky-content fix-top sticky-header">
        <div class="container">
          <div class="inner-wrap">
            <div class="header-left">
              <nav class="main-nav">
                <ul class="menu active-underline" v-if="!isAdmin">
                  <li class="has-submenu">
                    <a>Catalogue <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li>
                        <router-link to="/add-new-product"
                          >Add Products</router-link
                        >
                      </li>

                      <li>
                        <router-link to="/product-drafts"
                          >Complete Your Drafts</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/selling-applications"
                          >View Selling Applications</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="has-submenu">
                    <a>Inventory <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li>
                        <router-link to="/manage-inventory"
                          >Manage Inventory</router-link
                        >
                      </li>

                      <!-- <li>
                      <router-link to="/manage-product-documents"
                        >Manage Product Documents</router-link
                      >
                    </li> -->
                      <li>
                        <router-link to="/category-request"
                          >New Category Request</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="has-submenu">
                    <a>Orders <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li class="active">
                        <router-link to="/orders">Orders List</router-link>
                      </li>
                      <li class="active" style="display:none;">
                        <router-link to="/seller/order-issues"
                          >Orders Issues</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="active">
                    <router-link to="/seller/prescriptions"
                      >Prescriptions</router-link
                    >
                  </li>
                </ul>
                <!-- admin menus -->
                <ul class="menu active-underline" v-else>
                  <!-- <li>
                  <router-link to="/admin/dashboard" class="active-menu"
                    >DASHBOARD</router-link
                  >
                </li> -->

                  <!-- <li class="has-submenu">
                  <a>Catalogue <i class="fa fa-angle-down"></i></a>
                  <ul class="submenu">
                    <li>
                      <router-link to="/add-new-product"
                        >Add Products</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/product-drafts"
                        >Complete Your Drafts</router-link
                      >
                    </li>
                   
                  </ul>
                </li> -->
                  <li class="has-submenu">
                    <a>Category <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li>
                        <router-link to="/admin/addcategory"
                          >Add Category</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/admin/manage-category"
                          >Manage Category</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/admin/category-requests"
                          >New Category Request</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="has-submenu">
                    <a>Products <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li>
                        <router-link to="/admin/manage-products"
                          >Manage Product</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="has-submenu">
                    <a>Seller <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li>
                        <router-link to="/admin/manage-sellers"
                          >Manage Seller</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/admin/document-master"
                      >Document Master</router-link
                    >
                  </li>

                  <li class="has-submenu">
                    <a>Orders <i class="fa fa-angle-down"></i></a>
                    <ul class="submenu">
                      <li class="active">
                        <router-link to="/orders">Orders List</router-link>
                      </li>
                      <li class="active">
                        <router-link to="/seller/order-issues"
                          >Orders Issues</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="active">
                    <router-link to="/seller/prescriptions"
                      >Prescriptions</router-link
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
    <mobile-menu />
  </div>
  <!-- End of Header -->
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import MobileMenu from "./MobileMenu.vue";
const { mapGetters } = createNamespacedHelpers("auth");
export default {
  components: { MobileMenu },
  data() {
    return {
      categories: [
        "All Products",
        "cipla",
        "Dabur",
        "Hansaplast",
        "Mankind",
        "TrueBasics",
        "Accu-check",
        "Abott",
        "Zydus",
      ],
      searchKey: "",
      selectedCategory: "All Products",
    };
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo", "isAdmin"]),

    loginButton() {
      let btn = this.isLogin ? "Sign Out" : "Sign In";
      return btn;
    },
    userName() {
      return this.isLogin ? this.userInfo.username : "";
    },
  },
  methods: {
    logoutHandler() {
      if (this.isLogin) {
        console.log(this.userInfo);
        this.$store.dispatch("auth/isLogin", false);
        this.$store.dispatch("resetAll");
        window.location = window.location.origin;
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style scoped>
.dropdown > a {
  line-height: 1.1;
  padding: 3px 0;
}
</style>
