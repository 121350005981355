<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="bg-white order-panel">
              <h2>Your Account</h2>
              <div class="row">
                <div class="col-md-3">
                  <router-link to="/trackorder">
                    <div class="account-box account">
                      <div class="img-box">
                        <img src="/images/order-box.png" />
                      </div>
                      <h2>Your Ordes</h2>
                      <p>Track, Return or Buy Things Again</p>
                    </div>
                  </router-link>
                </div>

                <div class="col-md-3">
                  <router-link to="/login-security">
                    <div class="account-box account">
                      <div class="img-box">
                        <img src="/images/lock.png" />
                      </div>
                      <h2>Login & Security</h2>
                      <p>Track, Return or Buy Things Again</p>
                    </div>
                  </router-link>
                </div>

                <div class="col-md-3">
                  <router-link to="/address">
                    <div class="account-box account">
                      <div class="img-box">
                        <img src="/images/map-marker.png" />
                      </div>
                      <h2>Your Addresses</h2>
                      <p>Edit addresses for orde</p>
                    </div>
                  </router-link>
                </div>

                <div class="col-md-3">
                  <router-link to="/order-issues">
                    <div class="account-box account">
                      <div class="img-box">
                        <img src="/images/order-issue.png" />
                      </div>
                      <h2>Raise Order Issue</h2>
                      <p>View all raise order issue request</p>
                    </div>
                  </router-link>
                </div>

                <div class="col-md-3">
                  <router-link to="/my-prescriptions">
                    <div class="account-box account">
                      <div class="img-box">
                        <img src="/images/prescription-icon.png" />
                      </div>
                      <h2>Prescriptions</h2>
                      <p>View all Prescription</p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
