import OrderService from '../../services/order-service';
export default {
  namespaced: true,
  state: {
    orders: [],
    orderId: '',
  },
  getters: {
    orders(state) {
      return state.orders;
    },
    orderId(state) {
      return state.orderId;
    },
  },
  mutations: {
    addOrders(state, order) {
      console.log('pushing order');
      state.orders.push(order);
    },
    addOrderId(state, id) {
      state.orderId = id;
    },
  },
  actions: {
    async addOrder({ commit, rootState, dispatch }, customerId) {
      const address = rootState.address.address.shipping;
      const data = {
        customer_id: customerId,
        address_type: 1,
        first_name: address.name,
        last_name: '',
        address1: address.address,
        address2: '',
        state: address.state,
        country: address.country,
        phone_number: address.contact,
        email: address.email,
      };
      console.log('data add order', data);
      try {
        let res = await OrderService.addOrder(data);
        if (res.data.STATUS == 'SUCCESS') {
          const order = res.data.DATA;
          console.log(order);
          // commit('addOrder', order);
          res = await dispatch('updateOrder', {
            customerId,
            orderId: order._id,
          });
          commit('addOrderId', order._id);
          return res;
        }
      } catch (err) {
        console.log(err);
      }
      return 'FAILURE';
    },
    async updateOrder({ commit, rootState }, { customerId, orderId }) {
      const address = rootState.address.address.billing;
      const data = {
        customer_id: customerId,
        address_type: 2,
        first_name: address.name,
        last_name: '',
        address1: address.address,
        address2: '',
        state: address.state,
        country: address.country,
        phone_number: address.contact,
        email: address.email,
        payment_method_type: 1,
      };
      let res = await OrderService.updateOrder(orderId, data);
      // if (res.data.STATUS == 'SUCCESS') {
      //   const order = res.data.DATA;
      //   // commit('addOrder', order);
      // }
      return res;
    },
    addOrders({ commit }, order) {
      commit('addOrders', order);
    },
  },
};
