<template>
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="center-wrapper">
        <div class="row">
          <div class="col-md-12">
            <form class="form form-panel" style="min-height: 400px">
              <h4>Select a Product Category</h4>
              <p>
                Choosing the best product type ensures that you see the most
                appropriate data fields for your product. Browse the product
                types directly or use search.
              </p>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group category">
                    <label for=""
                      >Search
                      <span><a href="#">What is Product Type?</a></span></label
                    >
                    <input
                      type="text"
                      v-model="categoryModel"
                      class="form-control form-control-md"
                      placeholder="Search for a category"
                    /><button
                      type="button"
                      @click="searchCategory(this.categoryModel)"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row mb-2"
                v-for="cate in filteredCategories"
                :key="cate._id"
              >
                <a class="ml-4" @click="onSelectCategory(cate._id)">
                  {{ cate.ParentsMap }}
                </a>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group category">
                    <label for=""
                      >Browse
                      <span><a href="#">What is Product Type?</a></span></label
                    >
                    <treeselect
                      v-model="selectedCategory"
                      :multiple="false"
                      :options="options"
                      placeholder="Select a category"
                      noChildrenText="No Sub-Category"
                      :always-open="alwaysOpen"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";

import { createNamespacedHelpers } from "vuex";
import CategoryService from "@/services/category-service";
import Spinner from "@/components/Spinner.vue";

const { mapGetters } = createNamespacedHelpers("category");

export default {
  components: {
    Treeselect,
    Spinner,
  },
  data() {
    return {
      loading: false,
      categoryModel: "",
      showSearchCateegories: false,
      selectedCategory: null,
      mappingCategory: "",
      filteredCategories: [],
    };
  },
  watch: {
    selectedCategory(val) {
      this.onSelectCategory(val);
    },
  },
  computed: {
    ...mapGetters(["categories", "categoryAllChildren"]),
    options() {
      let x = this.categories.map((ct) => ct);
      return this.getAllCatChild(x);
      // let y = [];
      // let z = [];
      // for (let i = 0; i < x.length; i++) {
      //   x[i].children = [];
      //   x[i].id = x[i]._id;
      //   x[i].label = x[i].name;
      //   if (x[i].is_main_category || !x[i].parent_category_id) {
      //     x[i].parent_category_id = "0";
      //   }
      //   if (x[i].parent_category_id === x[i]._id) break;
      //   if (x[i]._id === this.selectCat) break;
      //   if (x[i].is_main_category) {
      //     y.push(x[i]);
      //   } else {
      //     z.push(x[i]);
      //   }
      // }
      // z.forEach((item) => {
      //   this.insert(y, item);
      // });
      // return y;
    },
  },
  mounted() {
    this.getAllCategory();
  },
  methods: {
    getAllCatChild(data){
      var result = [];
      for (var par of data) {
        if (par.is_main_category) {
          result.push({ ...par, children: this.get_childrens(data, par), id: par._id, label: par.name });
        }
      }
      return result;
    },
    get_childrens(data, item) {
      return data.filter((v) => v.parent_category_id === item._id).map((x) => {
        return data.filter((v) => v.parent_category_id === x._id).length > 0 ? { ...x, id: x._id, label: x.name, children: this.get_childrens(data, x) } : { ...x, id: x._id, label: x.name, children: [] };
      })
    },
    async getAllCategory() {
      this.loading = true;
      try {
        let res = await CategoryService.getAllCategory();
        let data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch("category/addCategories", data);
        }
        res = await CategoryService.getCategoryAllChildren();
        data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch("category/addCategoryAllChildren", data);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    searchCategory(searchKey) {
      this.categoryModel = "";
      if (!searchKey || searchKey === "") {
        return;
      }
      this.filteredCategories = this.categoryAllChildren.filter((ct) =>
        ct.name.toLowerCase().includes(searchKey.toLowerCase())
      );
      // console.log(this.filteredCategories);
    },

    getAllParentsOfCategory(id) {
      let mapping = "";
      if (!id || id === "0") return mapping;
      let found = false;
      let ct = {};
      for (let i = 0; i < this.categories.length; i++) {
        ct = this.categories[i];
        if (ct._id === id) {
          mapping = `${ct.name}>>${mapping}`;
          found = true;
          break;
        }
      }
      if (found) return this.getAllParentsOfCategory(ct.parent_category_id);
      else return mapping;
    },
    onSelectCategory(id) {
      const cat = this.categoryAllChildren.find((ct) => ct._id === id);
      this.$emit("next", cat);
    },
    insert(arr, value) {
      arr.forEach((item) => {
        if (item) {
          if (item._id === value.parent_category_id) {
            if (item["children"]) item["children"] = [];
            item["children"].push(value);
            // return false;
          } else {
            if (item["children"] && item["children"].length > 0)
              this.insert(item["children"], value);
            // return true;
          }
        }
      });
    },
  },
};
</script>

<style></style>
