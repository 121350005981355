<template>
  <div class="controls variant-data pb-6">
    <div class="variant-inner">
      <label for=""
        >{{ variantType }} <i data-title="" class="fa fa-question-circle-o"></i
      ></label>
      <input
        class="form-control"
        type="text"
        name="variant_name"
        required
        v-model="variantValue"
      />
    </div>
    <div class="variant-inner">
      <label for=""
        >Name of New Variant
        <i data-title="" class="fa fa-question-circle-o"></i
      ></label>
      <input
        class="form-control"
        type="text"
        name="variant_value"
        v-model="variantName"
      />
    </div>

    <div class="variant-inner">
      <label for=""
        >Min Sale Price <i data-title="" class="fa fa-question-circle-o"></i
      ></label>
      <input
        class="form-control"
        type="number"
        name="min_sale_price"
        v-model="variantMinPrice"
      />
    </div>

    <div class="variant-inner">
      <label for=""
        >Default Price <i data-title="" class="fa fa-question-circle-o"></i
      ></label>
      <input
        class="form-control"
        type="number"
        name="default_price"
        v-model="variantDefaultPrice"
      />
    </div>
    <div v-for="(tier, ind) in variantTierPrice" :key="ind">
      <div class="variant-inner">
        <label for=""
          >Quantity Upto <i data-title="" class="fa fa-question-circle-o"></i
        ></label>
        <input
          type="number"
          id=""
          name="quantity"
          class="form-control form-control-md"
          v-model="tier.quantity"
        />
      </div>

      <div class="variant-inner">
        <div class="form-group">
          <label for=""
            >Price <i data-title="" class="fa fa-question-circle-o"></i
          ></label>
          <input
            type="number"
            id=""
            name="price"
            class="form-control form-control-md"
            v-model="tier.price"
          />
        </div>
      </div>
    </div>
    <div class="variant-inner">
      <label for=""
        >Quantity Upto <i data-title="" class="fa fa-question-circle-o"></i
      ></label>
      <input
        type="number"
        id=""
        name="quantity"
        class="form-control form-control-md"
        v-model="tierPrice.quantity"
      />
    </div>

    <div class="variant-inner">
      <div class="form-group">
        <label for=""
          >Price <i data-title="" class="fa fa-question-circle-o"></i
        ></label>
        <input
          type="number"
          id=""
          name="price"
          class="form-control form-control-md"
          v-model="tierPrice.price"
        />
      </div>
    </div>
    <div
      class="row mb-6"
      style="position:absolute;width: 96px;margin-left: 6px;right:10%"
    >
      <div class="col-md-12">
        <div class="form-group">
          <button
            type="button"
            id="variant-append"
            name="variant-append"
            class="btn btn-dark btn-rounded btn-sm variant-append"
            @click="addMoreTierPrice"
          >
            Add More Tier Price
          </button>
        </div>
      </div>
    </div>
    <div class="variant-inner">
      <label for=""
        >Images <i data-title="" class="fa fa-question-circle-o"></i
      ></label>
      <input
        class="form-control"
        type="file"
        name="images"
        accept="image/*"
        @change="onFileSelect"
      />
    </div>
    <div class="variant-inner  ml-4 mr-4">
      <div class="row">
        <image-uploader
          v-for="(image, i) in images"
          :key="i"
          :url="image.url"
          :index="i"
          :showDelete="true"
          @delete-file="onFileDelete"
        />
      </div>
    </div>
    <a
      @click="$emit('remove', index)"
      class="remove_this btn btn-dark btn-rounded red-bg btn-sm"
      style="position:absolute;width: 96px;margin-left: 6px;right:10%"
      >remove</a
    >
  </div>
</template>

<script>
import ImageUploader from '../../util/ImageUploader.vue';
export default {
  components: { ImageUploader },
  props: ['attr', 'index'],
  data(props) {
    return {
      variantType: props?.attr?.name,
      variantValue: props?.attr?.description,
      variantName: props?.attr?.values[0].name,
      variantMinPrice: props?.attr?.values[0].seller_min_price,
      variantDefaultPrice: props?.attr?.values[0].default_price,
      variantTierPrice: props?.attr?.values[0].tier_price,

      images: props?.attr?.values[0].image_url || [],

      tierPrice: {
        quantity: null,
        price: null,
      },
    };
  },
  watch: {
    variantName(value) {
      this.$emit('variantName', { value, index: this.index });
    },
    variantMinPrice(value) {
      console.log('watch');
      this.$emit('variantMinPrice', { value, index: this.index });
    },
    variantDefaultPrice(value) {
      this.$emit('variantDefaultPrice', { value, index: this.index });
    },
    images: {
      handler(value, oldVal) {
        console.log('images changed');
        this.$emit('variantImages', { value, index: this.index });
      },
      deep: true,
    },
    variantTierPrice: {
      handler(value, oldVal) {
        this.$emit('variantTierPrice', { value, index: this.index });
      },
      deep: true,
    },
  },
  methods: {
    onFileSelect(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = '';
        this.$wkToast('Please Select file less than 1mb');
        return;
      }
      this.images.push({
        file,
        url: URL.createObjectURL(file),
      });
    },
    onFileDelete(value) {
      const indexValue = this.images.findIndex((image) => image.url === value);
      if (indexValue > -1) {
        this.images.splice(indexValue, 1);
      }
    },

    addMoreTierPrice() {
      if (!this.tierPrice.quantity || this.tierPrice.quantity > 1000000)
        return this.$wkToast(
          'Please Enter Variant Tier Price Quantity less than 1000000',
          {
            className: ['my-toast', 'wk-info'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
            transition: 'transition',
          }
        );
      if (!this.tierPrice.price || this.tierPrice.price > 1000000)
        return this.$wkToast(
          'Please Enter Variant Tier Price less than 1000000',
          {
            className: ['my-toast', 'wk-alert'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
            transition: 'transition',
          }
        );

      this.variantTierPrice.push({
        quantity: this.tierPrice.quantity,
        price: this.tierPrice.price,
      });
      this.tierPrice.quantity = null;
      this.tierPrice.price = null;
    },
  },
};
</script>

<style></style>
