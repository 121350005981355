<template>
  <main class="main">
    <div class="container">
      <section class="home">
        <div class="home-row">
          <div class="d-flex">
            <div>
              <h2>To begin adding products</h2>
              <h1>Find your products in World Medical Trade catalog</h1>
            </div>
            <div class="bannerBg">
              <img src="/images/selectproductbg.png" alt="" />
            </div>
          </div>
          <form @submit.prevent="findProduct">
            <input
              type="text"
              v-model="findproduct"
              maxlength="100"
              pattern="[a-zA-Z0-9-._\s]+"
              title="text and number only"
              placeholder="Product Name, UPC, EAN, ISBIN, or ASIN"
            /><button type="submit" class="productsearch">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </form>
          <div class="productlink" v-if="!isAdmin">
            <a @click.prevent="newProduct"
              >I am adding a Product to sale on WMT</a
            >
            <a @click.prevent="uploadProducts"
              >I'm uploading a file to add multiple products</a
            >
          </div>
        </div>
        <div>
          <spinner v-if="loading" />
          <h2 class="home-row-title" v-if="!loading">{{ products.MESSAGE }}</h2>
          <div class="home-product-listing" v-if="!loading">
            <div
              class="hproduct"
              v-for="product in products.DATA"
              :key="product._id"
            >
              <ProductCard :productrs="product" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import ProductCard from '@/components/seller/products/ProductCard.vue';
import UserService from '@/services/user.service.js';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '../Spinner.vue';
const { mapGetters } = createNamespacedHelpers('product');
const authMapGetters = createNamespacedHelpers('auth').mapGetters;

export default {
  components: {
    ProductCard,
    Spinner,
  },
  data() {
    return {
      products: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['productSearchKey']),
    ...authMapGetters(['isAdmin']),
  },
  methods: {
    newProduct() {
      this.$router.push('/add-new-product');
    },
    uploadProducts() {
      this.$router.push('/addproductsfile');
    },
    findProduct() {
      this.loading = true;
      UserService.searchProducts(this.findproduct).then(
        (response) => {
          if (response.data.DATA.length > 0) {
            this.products = response.data;
            console.log('product search', this.products);
          } else this.products = [];
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
    },
  },
};
</script>
<style scoped>
.bannerBg {
  position: absolute;
  right: 3vw;
  /* bottom: 3vw; */
}
section.home {
  position: relative;
  min-height: 451px;
  /* background: #f7feff; */
  padding: 8vw;
}
.home-row {
  background: transparent;
  padding: 0;
  border-radius: 0;
  margin: 20px 0;
  width: 60%;
}
.home-row input {
  width: 92%;
  padding: 10px 20px;
  border: 1px solid #eaeded;
  height: 56px;
}
button.productsearch {
  background: #eaeded;
  height: 56px;
  width: 8%;
  border: 1px solid #c8caca;
  color: #000;
  color: #c8caca;
  font-size: 24px;
  line-height: 10px;
  box-sizing: border-box;
  position: relative;
  top: 4px;
  cursor: pointer;
}
.productlink {
  margin: 60px 0;
}
.productlink a {
  color: #333;
  text-decoration: underline;
  margin: 0 30px;
  cursor: pointer;
}
</style>
