<template>
  <main class="main bg-white">
    <div class="full-wrapper">
      <h4>Complete Your Draft</h4>
      <div class="row">
        <div class="col-md-2">
          <div class="left-panel">
            <p>Display</p>
            <ul>
              <li>
                <input
                  type="radio"
                  name="draft"
                  @change="withIssue = !withIssue"
                />
                Complete with Issues
                <i data-title="" class="fa fa-question-circle-o"></i>
              </li>
              <li>
                <input
                  type="radio"
                  name="draft"
                  @change="withIssue = !withIssue"
                  checked
                />
                Incolplete Listing
                <i data-title="" class="fa fa-question-circle-o"></i>
              </li>
            </ul>
            <a href=""
              >All
              {{ withIssue ? "With Issue Listing" : "Incomplete Listing" }} ({{
                completeWithIssueDrafts(withIssue).length
              }}) <i data-title="" class="fa fa-question-circle-o"></i
            ></a>
          </div>
        </div>
        <div class="col-md-10">
          <div class="right-panel">
            <div class="search">
              <form>
                <select class="search-control" v-model="searchOption">
                  <option value="product_name">Product Name</option>
                  <option value="sku">SKU Prefix</option>
                </select>
                <input
                  type="text"
                  placeholder="Search SKU/Product"
                  class="search-control"
                  v-model="search"
                />
                <button>
                  <i class="fa fa-search"></i>
                </button>
              </form>
              <button v-if="selected.length" @click="deleteDrafts">
                <i class="fa fa-trash red-btn"></i>
              </button>
              <p v-show="completeWithIssueDrafts(withIssue).length">
                Showing 1 to {{ completeWithIssueDrafts(withIssue).length }} out
                of {{ completeWithIssueDrafts(withIssue).length }}
              </p>
            </div>
            <div class="search-data">
              <table cellpadding="0" cellspacing="0" class="data-table">
                <tr>
                  <th></th>
                  <th>
                    Product name
                    <span>SKU</span>
                  </th>
                  <th>
                    Date Modified
                    <span>Time</span>
                  </th>
                  <th colspan="2">Issue Description</th>
                </tr>
                <tr v-for="(draft, index) in products" :key="index">
                  <td>
                    <input
                      type="checkbox"
                      :id="index"
                      v-model="selected"
                      :value="draft.product_name"
                    />
                  </td>
                  <td>
                    <div class="detail">
                      {{ draft.product_name }}
                      <span><b>SKU:</b> {{ draft.sku }}</span>
                    </div>
                  </td>
                  <td>
                    {{
                      draft.created_on_utc_date ||
                      new Date().toLocaleDateString()
                    }}
                  </td>
                  <td>
                    Listing required addtional information
                    <button type="button" @click="editDraft(draft._id)">
                      Edit Draft
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ProductDraftService from "../../../services/product-draft-service";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: draftGetters } = createNamespacedHelpers("productDrafts");
const { mapGetters: authGetters } = createNamespacedHelpers("auth");
export default {
  data() {
    return {
      withIssue: false,
      selected: [],
      search: "",
      searchOption: "product_name",
      prods: [],
    };
  },
  computed: {
    products() {
      let vm = this;
      return this.search != ""
        ? this.prods.filter((prod) => prod[vm.searchOption] === vm.search)
        : this.prods;
    },
    ...draftGetters(["drafts"]),
    ...authGetters(["userInfo"]),
  },
  mounted() {
    this.getDraftProduct();
  },
  methods: {
    getDraftProduct() {
      ProductDraftService.getVendorDraft(this.userInfo.id).then((res) => {
        this.prods = res.data.DATA;
      });
    },
    completeWithIssueDrafts(withIssue) {
      let temp = this.drafts.filter((draft) =>
        withIssue ? draft.completeWithIssue : !draft.completeWithIssue
      );
      // return temp;
      return temp.filter((draft) => {
        let isInclude = false;
        if (this.searchOption === "name") {
          isInclude = draft.product?.name?.toLowerCase().includes(this.search);
        } else {
          isInclude = draft.seller?.sku?.toLowerCase().includes(this.search);
        }

        return isInclude ? true : false;
      });
    },
    deleteDrafts() {
      debugger;
      const draft = this.drafts.find((s) => {
        return this.selected.find((sel) => sel === s.product.name)
          ? true
          : false;
      });

      if (draft) {
        this.$store.dispatch("productDrafts/deleteDraft", draft);
      }
      this.selected = [];
    },
    editDraft(id) {
      if (id) {
        this.$router.push("/product/draft/" + id);
      }
    },
  },
};
</script>

<style></style>
