import axios from 'axios';

const API_URL = 'https://wmt-sellergateway.herokuapp.com/api/';

class SellerService {
  getAllSeller() {
    return axios.get(API_URL + 'allseller');
  }

  getSellerById(id) {
    return axios.get(API_URL + 'seller/' + id);
  }

  updateSeller(id, payload) {
    return axios.put(API_URL + 'seller/' + id, payload);
  }
}

export default new SellerService();
