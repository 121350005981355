<template>
  <main class="main bg-white">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-5 mb-4 center-div">
            <form @submit.prevent="addAddress">
              <div class="bg-white cart-panel">
                <div class="address-heading">Add a new address</div>
                <div class="new-address">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Address Type</label>
                        <select v-model="addressType">
                          <option
                            v-for="type in addressTypes"
                            :key="type"
                            :value="type"
                            >{{ type }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Country/Region</label>
                        <select id="country" name="country" v-model="country">
                          <option value="India">India</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Full Name (First and Last Name)</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          maxlength="100"
                          title="Alpha Numeric With Space"
                          v-model="name"
                          pattern="[a-zA-Z0-9-._\s]+"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Address</label>
                        <input
                          type="text"
                          name="address"
                          placeholder="Flat, House no., Building, Company, Apartment"
                          maxlength="150"
                          v-model="address"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>City</label>
                        <input
                          type="text"
                          name="city"
                          v-model="city"
                          readonly
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>State</label>
                        <input
                          type="text"
                          name="state"
                          v-model="state"
                          readonly
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Pincode</label>
                        <input
                          type="text"
                          name="pincode"
                          placeholder="6 digits [0-9] PIN code"
                          maxlength="6"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                          required
                          v-model="pincode"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Mobile number</label>
                        <div class="mask-code">
                          <span>+91</span>
                          <input
                            type="tel"
                            name="contact"
                            placeholder="contact"
                            v-model="mobile"
                            maxlength="10"
                            :pattern="regMobile.pattern"
                            :oninput="regMobile.oninput"
                            :title="regMobile.title"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="add-address">
                        <label>Email</label>
                        <input
                          type="email"
                          name="Email"
                          :pattern="regEmail"
                          required
                          v-model="email"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="add-address">
                        <button type="submit" name="">
                          {{ addressId ? 'Update' : 'Add' }} Address
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mobileField, emailPattern } from '@/util/regexUtil';
import { v4 as uuidv4 } from 'uuid';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('address');

export default {
  data() {
    return {
      addressId: this.$route.params.id,
      name: null,
      email: null,
      address: null,
      city: 'Gurugram',
      state: 'Haryana',
      country: 'India',
      pincode: null,
      mobile: null,
      addressTypes: ['Home', 'Office', 'Other'],
      addressType: 'Home',
      regMobile: mobileField,
      regEmail: emailPattern,
      loading: false,
      defaultAddress: false,
    };
  },

  computed: {
    ...mapGetters(['addresses']),
  },

  created() {
    if (this.addressId) {
      const addr = this.addresses.find((a) => a.id === this.addressId);
      if (addr) {
        this.name = addr.name;
        this.email = addr.email;
        this.address = addr.address;
        this.city = addr.city;
        this.state = addr.state;
        this.country = addr.country;
        this.pincode = addr.pincode;
        this.mobile = addr.mobile;
        this.addressType = addr.type;
        this.defaultAddress = addr.default;
      }
    }
  },

  methods: {
    addAddress() {
      if (this.loading) return;
      try {
        this.loading = true;
        debugger;
        const id = this.addressId || uuidv4();

        const payload = {
          id,
          name: this.name,
          address: this.address,
          city: this.city,
          state: this.state,
          country: this.country,
          pincode: this.pincode,
          email: this.email,
          mobile: this.mobile,
          type: this.addressType,
          default: this.addresses.length == 0 ? true : this.defaultAddress,
        };
        this.$store.dispatch(
          `address/${this.addressId ? 'updateAddress' : 'addAddress'}`,
          payload
        );
        this.$router.go(-1);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style>
.mask-code {
  /* padding: 3px; */
  width: 100%;
  border: 1px solid #888c8c;
  border-radius: 3px;
  /* box-shadow: 0 1px 2px rgb(15 17 17 / 15%) inset; */
  outline: 0;
  display: flex;
  align-items: center;
}
.mask-code input[type='tel'] {
  background-color: transparent;
  border: 0px solid transparent;
  display: table-cell;
  width: 90%;
}

/* .mask-code input:focus {
  box-shadow: 0px 0px 0px 0px transparent;
  outline: 0;
} */

/* input:focus {
  -webkit-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  -moz-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  outline: 0;
} */
</style>
