<template>
  <div class="tab-pane active in" id="tab4-3">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="!self || (!showEdit && productionCapacityId)">
      <div
        class="row"
        v-for="(key, index) in Object.keys(productionCapacity)"
        :key="key"
      >
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>
        <div
          class="col-md-6 d-flex justify-content-end align-items-center"
          @click="showEdit = true"
          v-if="self && index == 0"
        >
          <i class="fas fa-edit "></i>
        </div>
        <div
          class="row"
          v-for="value in productionCapacity[key]"
          :key="value.name"
        >
          <div class="pt-4 pb-4">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>

    <form v-else class="form account-details-form" @submit.prevent="save">
      <div v-for="key in Object.keys(productionCapacity)" :key="key">
        <h4 class="title title-password ls-25 font-weight-bold">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>
        <div class="row">
          <div
            class="col-md-6"
            v-for="value in productionCapacity[key]"
            :key="value.name"
          >
            <div class="form-group">
              <label for=""
                ><h5>
                  {{
                    value.name
                      .split(/(?=[A-Z])/)
                      .reduce((s, str) => s + str + ' ', '')
                  }}
                  *
                </h5></label
              >
              <input
                type="text"
                required
                maxlength="150"
                pattern="[a-zA-Z0-9-._\s]+"
                title="text and number only"
                class="form-control form-control-md"
                v-model="value.des"
              />
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-dark btn-rounded btn-sm mb-4">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const { mapGetters } = createNamespacedHelpers('auth');
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  data() {
    return {
      showEdit: false,
      loading: false,
      sellerId: this.$route.params.id,
      productionCapacityId: '',
      production: {
        ProductionEquipment: [
          { name: 'Name', des: 'Vacuum Reactor' },
          { name: 'No', des: 'Confidential' },
        ],
        FactoryInformation: [
          { name: 'Factory Size', des: '30,000-50,000 square meters' },
          {
            name: 'Factory Country/Region',
            des:
              'No. 1269, Chibi Ave., Chibi City, Xianning City, Hubei Province, China',
          },
        ],
        AnnualProductionCapacity: [
          { name: 'Product Name', des: 'Empty Capsules' },
          { name: 'Production Line Capacity', des: '45,000,000 Pcs Per Day' },
        ],
        ProductionLine: [
          {
            name: 'Production Line',
            des: 'Fully automatic Capsule Production Line',
          },
          { name: 'Supervisor', des: '6' },
        ],
      },
    };
  },
  computed: {
    self() {
      return this.userInfo.id === this.sellerId;
    },
    productionCapacity() {
      if (this.productionCapacityBySellerId) {
        const { _id, customerid, ...co } = this.productionCapacityBySellerId;
        this.setProductionCapacityId(_id);
        return co;
      }

      return {};
    },
    productionCapacityBySellerId() {
      if (this.productionCapacities) {
        return this.productionCapacities[this.sellerId || this.userInfo.id];
      }
      return null;
    },
    ...mapGetters(['userInfo']),
    ...profileMapGetters(['productionCapacities']),
  },
  mounted() {
    if (this.productionCapacityId == '') {
      this.getProductionCapacity();
    }
  },
  methods: {
    setProductionCapacityId(id) {
      if (id) this.productionCapacityId = id;
    },
    getProductionCapacity() {
      this.loading = true;
      ProfileService.getProductionCapacity(this.sellerId || this.userInfo.id)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addProductionCapacity', cover);
          } else {
            co = this.production;
            co.customerid = this.sellerId || this.userInfo.id;
            this.$store.dispatch('companyProfile/addProductionCapacity', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async save() {
      const payload = {
        ...this.productionCapacity,
        customerid: this.userInfo.id,
      };
      try {
        this.loading = true;
        if (!this.productionCapacityId || this.productionCapacityId == '') {
          console.log('create production');
          await ProfileService.createProductionCapacity(payload);
        } else {
          await ProfileService.updateProductionCapacity(
            payload,
            this.productionCapacityId
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.showEdit = false;
      this.getProductionCapacity();
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
