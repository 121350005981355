import { createRouter, createWebHistory } from 'vue-router';
import Signup from '../components/signup/Signup.vue';

import NotFound from '../views/NotFound.vue';

//admin

//customer
import CustomerHome from '../views/customer/CustomerHome';
import CustomerLogin from '../views/customer/CustomerLogin';
import CustomerSignup from '../views/customer/CustomerSignup';

import Cart from '../views/customer/Cart';
import CartCheckout from '../views/customer/CartCheckout';
import ShippingAddress from '../views/customer/ShippingAddress';
import ThanksForOrder from '../views/customer/ThanksForOrder';
import Product from '../views/product/frontend/Product.vue';
import TrackOrder from '../views/orders/frontend/TrackOrder.vue';
import SearchProducts from '../views/product/frontend/SearchProducts.vue';
import AboutWmt from '../views/customer/AboutWmt.vue';
import HelpCenter from '../views/customer/HelpCenter.vue';
import PolicyRules from '../views/customer/PolicyRules.vue';
import Category from '../views/customer/Category.vue';
import PrescriptionUpload from '../views/prescription/frontend/PrescriptionUpload.vue';
import AddPrescription from '../views/prescription/frontend/AddPrescription.vue';
import AllCategory from '../views/customer/AllCategory.vue';
import MyAddress from '../views/customer/MyAddress.vue';
import AddAddress from '../views/customer/AddAddress.vue';
import MyAccount from '../views/customer/MyAccount.vue';
import LoginSecurity from '../views/customer/LoginSecurity.vue';
import CustomerOrderIssues from '../views/orders/frontend/CustomerOrderIssues.vue';
import CustomerPrescriptions from '../views/prescription/frontend/CustomerPrescriptions.vue';
import CustomerOrderDetail from '../views/orders/frontend/CustomerOrderDetail';

//admin
import AdminLogin from '../views/admin/AdminLogin';
import AdminSignup from '../views/admin/AdminSignup';
import AddNewCategory from '../views/admin/AddNewCategory';

import DashBoard from '../views/admin/DashBoard.vue';
import CategoryRequests from '../views/admin/CategoryRequests.vue';
import DocumentMaster from '../views/admin/DocumentMaster.vue';
import ManageCategory from '../views/admin/ManageCategory.vue';
import ManageProduct from '../views/admin/ManageProduct.vue';
import ManageSeller from '../views/admin/ManageSeller.vue';
import ManageProductSellers from '../views/admin/ManageProductSellers.vue';

//seller
import SellerLogin from '../views/seller/SellerLogin';
import SellerSignup from '../views/seller/SellerSignup';
import Profile from '../views/seller/Profile.vue';
import ProductCatalog from '../views/product/backend/ProductCatalog';
import AddNewProduct from '../views/product/backend/AddNewProduct';
import UpdateProduct from '../views/product/backend/UpdateProduct';
import ActiveProductEdit from '../views/product/backend/ActiveProductEdit';
import Home from '../views/seller/Home';
import Orders from '../views/orders/backend/Orders';
import PrescriptionList from '../views/prescription/backend/PrescriptionList';
import ProductDrafts from '../views/product/backend/ProductDrafts';
import SellingApplications from '../views/seller/SellingApplications';
import AddProductsFile from '../views/product/backend/AddProductsFile.vue';
import CreateCategoryRequest from '../views/seller/CreateCategoryRequest';
import ManageInventory from '../views/seller/ManageInventory';
import OrderDetail from '../views/orders/backend/OrderDetail';
import OrderIssues from '../views/orders/backend/OrderIssues';

const routes = [
  { path: '/404', name: 'NotFound', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '/404' },

  //customer related

  // { path: '/', redirect: '/customer' },

  {
    path: '/',
    name: 'CustomerHome',
    component: CustomerHome,
  },

  {
    path: '/login',
    name: 'CustomerLogin',
    component: CustomerLogin,
  },
  {
    path: '/signup',
    name: 'CustomerSignup',
    component: CustomerSignup,
  },

  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
  },

  {
    path: '/order-issues',
    name: 'CustomerOrderIssues',
    component: CustomerOrderIssues,
  },

  {
    path: '/my-prescriptions',
    name: 'CustomerPrescriptions',
    component: CustomerPrescriptions,
  },

  {
    path: '/login-security',
    name: 'LoginSecurity',
    component: LoginSecurity,
  },

  {
    path: '/cart',
    name: 'cart',
    component: Cart,
  },
  {
    path: '/cart/address',
    name: 'address',
    component: ShippingAddress,
  },

  {
    path: '/address',
    name: 'MyAddress',
    component: MyAddress,
  },

  {
    path: '/address/add',
    name: 'AddAddress',
    component: AddAddress,
  },
  {
    path: '/address/edit/:id',
    name: 'EditAddress',
    component: AddAddress,
  },

  {
    path: '/cart/checkout',
    name: 'checkout',
    component: CartCheckout,
  },
  {
    path: '/thanksfororder',
    name: 'thanks',
    component: ThanksForOrder,
  },
  {
    path: '/product/:id',
    name: 'CustomerProduct',
    component: Product,
  },
  {
    path: '/product/search/:name',
    name: 'SearchProducts',
    component: SearchProducts,
  },

  {
    path: '/category/:category',
    name: 'Category',
    component: Category,
  },
  {
    path: '/trackorder',
    name: 'TrackOrder',
    component: TrackOrder,
  },

  {
    path: '/order/:id',
    name: 'CustomerOrderDetail',
    component: CustomerOrderDetail,
  },

  { path: '/about-wmt', name: 'AboutWmt', component: AboutWmt },
  { path: '/help-center', name: 'HelpCenter', component: HelpCenter },
  { path: '/policy-rules', name: 'PolicyRules', component: PolicyRules },
  {
    path: '/prescription-upload',
    name: 'PrescriptionUpload',
    component: PrescriptionUpload,
  },
  {
    path: '/prescription-required',
    name: 'AddPrescription',
    component: AddPrescription,
  },
  {
    path: '/allcategory',
    name: 'AllCategory',
    component: AllCategory,
  },

  //seller related
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/seller',
    name: 'SellerHome',
    component: Home,
  },

  {
    path: '/seller/login',
    name: 'SellerLogin',
    component: SellerLogin,
  },
  {
    path: '/seller/signup',
    name: 'SellerSignup',
    component: SellerSignup,
  },

  {
    path: '/seller/profile/:id',
    name: 'SellerProfile',
    component: Profile,
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile,
  },

  {
    path: '/seller/order-issues',
    name: 'OrderIssues',
    component: OrderIssues,
  },

  //admin
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
  },

  {
    path: '/admin/signup',
    name: 'AdminSignup',
    component: AdminSignup,
  },

  {
    path: '/admin/addcategory',
    name: 'Add Category',
    component: AddNewCategory,
    props: true,
  },

  {
    path: '/admin/category-requests',
    name: 'Category Requests',
    component: CategoryRequests,
  },
  {
    path: '/admin/manage-products',
    name: 'Manage Product',
    component: ManageProduct,
  },
  {
    path: '/admin/manage-product-sellers/:id',
    name: 'Manage Product Sellers',
    component: ManageProductSellers,
  },
  {
    path: '/admin/dashboard',
    name: 'DashBoard',
    component: DashBoard,
  },
  {
    path: '/admin/document-master',
    name: 'Document Master',
    component: DocumentMaster,
  },
  {
    path: '/admin/manage-category',
    name: 'Manage Category',
    component: ManageCategory,
  },
  {
    path: '/admin/manage-sellers',
    name: 'Manage Seller',
    component: ManageSeller,
  },

  //backend

  {
    path: '/add-new-product',
    name: 'Add New Product',
    component: AddNewProduct,
    props: true,
  },

  {
    path: '/productcatalog',
    name: 'ProductCatalog',
    component: ProductCatalog,
  },

  {
    path: '/product/draft/:draft_id',
    name: 'Edit Draft',
    component: AddNewProduct,
  },

  {
    path: '/update-product/:id',
    name: 'Update Product',
    component: UpdateProduct,
    props: true,
  },
  {
    path: '/active-product-edit/:id',
    name: 'Active Product Edit',
    component: ActiveProductEdit
  },

  {
    path: '/seller/orders',
    name: 'SellerOrders',
    component: Orders,
  },

  {
    path: '/admin/orders',
    name: 'AdminOrders',
    component: Orders,
  },

  {
    path: '/customer/orders',
    name: 'CustomerOrders',
    component: Orders,
  },

  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
  },

  {
    path: '/order/:id',
    name: 'OrderDetail',
    component: OrderDetail,
  },

  {
    path: '/seller/prescriptions',
    name: 'Prescription List',
    component: PrescriptionList,
  },
  {
    path: '/seller/product-drafts',
    name: 'SellerProductDrafts',
    component: ProductDrafts,
  },
  {
    path: '/product-drafts',
    name: 'Product Drafts',
    component: ProductDrafts,
  },

  {
    path: '/seller/selling-applications',
    name: 'SellerViewSellingApplications',
    component: SellingApplications,
  },

  {
    path: '/selling-applications',
    name: 'ViewSellingApplications',
    component: SellingApplications,
  },

  {
    path: '/seller/addproductsfile',
    name: 'SellerAddProductsFile',
    component: AddProductsFile,
  },

  {
    path: '/admin/addproductsfile',
    name: 'AdminAddProductsFile',
    component: AddProductsFile,
  },
  {
    path: '/addproductsfile',
    name: 'AddProductsFile',
    component: AddProductsFile,
  },

  {
    path: '/category-request',
    name: 'CreateCategoryRequest',
    component: CreateCategoryRequest,
  },
  {
    path: '/seller/category-request',
    name: 'SellerCreateCategoryRequest',
    component: CreateCategoryRequest,
  },

  {
    path: '/manage-inventory',
    name: 'Manage Inventory',
    component: ManageInventory,
  },

  {
    path: '/seller/manage-inventory',
    name: 'SellerManageInventory',
    component: ManageInventory,
  },

  //

  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },

  // Admin Section
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

export default router;
