import axios from 'axios';

const API_URL = 'https://wmt-document-service.herokuapp.com/api/';

class DocumentMasterService {
  createDocument(payload) {
    return axios.post(API_URL + 'documentmaster/create', payload);
  }

  updateDocument(id, payload) {
    return axios.put(API_URL + 'documentmaster/' + id, payload);
  }
  getAllDocuments() {
    return axios.get(API_URL + 'documentmaster');
  }
  getDocumentsById(id) {
    return axios.get(API_URL + 'documentmaster/' + id);
  }
}

export default new DocumentMasterService();
