<template>
  <add-product-category @next="openProductInfoForm" v-if="!next && !isUpdate" />
  <product-info-form
    v-else
    @cancel="next = false"
    :category="category"
    :name="name"
  />
</template>

<script>
import AddProductCategory from "@/components/seller/products/AddProductCategory.vue";
import ProductInfoForm from "@/components/seller/products/ProductInfoForm.vue";
import CategoryService from "@/services/category-service";
import ProductDraftService from "../../../services/product-draft-service";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("category");
const draftMapGetters = createNamespacedHelpers("productDrafts").mapGetters;
const authMapGetters = createNamespacedHelpers("auth").mapGetters;

export default {
  components: { AddProductCategory, ProductInfoForm },
  props: ["name", "category_ids"],
  data() {
    return {
      next: false,
      category: {},
      draftId: this.$route.params.draft_id,
    };
  },
  computed: {
    isUpdate() {
      return this.name ? true : false;
    },
    ...mapGetters(["categoryAllChildren"]),
    ...draftMapGetters(["drafts"]),
    ...authMapGetters(["userInfo"]),
  },

  async mounted() {
    // await this.getAllCategory();
    if (this.draftId) {
      this.getDraft();
    }
  },
  methods: {
    getDraft() {
      ProductDraftService.getVendorDraftById(this.userInfo.id, this.draftId)
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            this.category = this.categoryAllChildren.find((cat) => {
              return cat._id === res.data.DATA.category;
            });
            if (this.category) this.openProductInfoForm(this.category);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // const draft = this.drafts.find((d) => {
      //   return d.id.toString() === this.draftId;
      // });
      // debugger;
      // if (draft) {
      //   console.log({ categoryId: draft.categoryId });
      //   this.category = this.categoryAllChildren.find((cat) => {
      //     return cat._id === draft.categoryId;
      //   });
      //   if (this.category) this.openProductInfoForm(this.category);
      // }
    },

    async getAllCategory() {
      try {
        let res = await CategoryService.getAllCategory();
        let data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch("category/addCategories", data);
        }
        res = await CategoryService.getCategoryAllChildren();
        data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch("category/addCategoryAllChildren", data);
        }
        this.findUpdateCategory();
      } catch (err) {
        console.log(err);
      }
    },
    findUpdateCategory() {
      this.category = this.categoryAllChildren.find(
        (cat) => cat._id === this.category_ids[0]
      );
      // console.log("find", this.category);
    },
    openProductInfoForm(category) {
      this.category = category;
      this.next = true;
    },
  },
};
</script>

<style></style>
