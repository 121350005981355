<template>
  <!-- Start of Main-->
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="bg-white about">
              <h3>Help Center</h3>
              <p>Comming Soon...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
