<template>
  <main class="main bg-white">
    <div v-if="loading" class="loader-container ">
      <spinner />
    </div>
    <div class="" v-else-if="orderData">
      <div class="full-wrapper">
        <h4>Order Detail</h4>
        <div class="row">
          <div class="col-md-12">
            <div class="right-panel border-left-0">
              <div class="order-div">
                <p><b>Order ID:</b> {{ orderId }}</p>
              </div>
              <div class="order-btn">
                <div class="row">
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="back-order-btn order-btn"
                      @click="$router.go(-1)"
                    >
                      Back to Order List
                    </button>
                  </div>
                  <div class="col-md-9 text-right">
                    <button type="button" class="order-btn" @click="printSlip">
                      Print Packing Slip
                    </button>
                    <button type="button" class="order-btn">
                      Confirm Shipment
                    </button>
                    <button type="button" class="order-btn">
                      Cancel Order
                    </button>
                  </div>
                </div>
              </div>
              <div class="order-shipping-detail">
                <div class="row">
                  <div class="col-md-6">
                    <div class="shipping-detail">
                      <h2>Order Summary</h2>
                      <div class="row">
                        <div class="col-md-7">
                          <p>
                            <b>Ship by:</b>
                            {{ orderData.payment_method.created_on_utc_date }},
                            2021
                          </p>
                          <p><b>Deliver by:</b> {{ weekFromToday }}, 2021</p>
                          <p>
                            <b>Purchase Date:</b>
                            {{ orderData.payment_method.created_on_utc_date }}
                          </p>
                        </div>
                        <div class="col-md-5">
                          <p><b>Shipping Service:</b> Standard</p>
                          <p>
                            <b>Order Status:</b>
                            <!-- {{ orderData.order_status }} -->
                            Pending
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="buyer-detail">
                      <h2>Ship To</h2>
                      <div class="row">
                        <div class="col-md-12">
                          <p>
                            {{ getShippingAddress }}
                          </p>

                          <p><b>Address Type:</b> Residential</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-detail-data">
                <table cellpadding="0" cellspacing="0" class="data-table">
                  <tr>
                    <th>Image</th>
                    <th>Product Name</th>
                    <th>More Information</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Unit Price</th>
                    <th class="text-center">Total Price</th>
                  </tr>
                  <tr
                    v-for="item in orderData.order_item"
                    :key="item.product_id"
                  >
                    <td>
                      <img :src="item.imgurl[0]" />
                    </td>
                    <td>
                      <a href="">{{ item.product_name }}</a>
                    </td>
                    <td>
                      <p><b>Condition:</b> New</p>
                      <!-- <p><b>Listing ID:</b> 518XF555</p> -->
                    </td>
                    <td class="text-center">
                      {{ item.quantity }}
                    </td>
                    <td class="text-center">
                      <i class="fa fa-inr"></i> {{ item.price }}
                    </td>
                    <td class="text-center">
                      <i class="fa fa-inr"></i> {{ item.price * item.quantity }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import OrderService from '@/services/order-service';
import Spinner from '../../../components/Spinner.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('address');

export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      orderData: null,
      orderId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters(['getShippingAddress']),
    weekFromToday() {
      return moment()
        .add(7, 'days')
        .format('MMMM Do YYYY');
    },
    day2FromToday() {
      return moment()
        .add(2, 'days')
        .format('MMMM Do YYYY');
    },
  },
  mounted() {
    this.getOrderDetail();
  },
  methods: {
    async getOrderDetail() {
      try {
        this.loading = true;
        const res = await OrderService.getOrderDetail(this.orderId);
        const data = res.data.DATA;
        if (data) {
          this.orderData = data;
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    printSlip() {
      window.print();
    },
  },
};
</script>

<style>
.loader-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
