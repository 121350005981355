<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="bg-white order-panel">
              <h2>Your Addresses</h2>
              <div class="row">
                <div class="col-md-3">
                  <router-link to="/address/add">
                    <div class="address-box address-tile">
                      <i class="fa fa-plus"></i>
                      <h2 class="a-color-tertiary">Add address</h2>
                    </div>
                  </router-link>
                </div>

                <div class="col-md-3" v-for="addr in addresses" :key="addr.id">
                  <div
                    class="address-box address "
                    :class="addr.default ? 'default-address' : ''"
                  >
                    <p>
                      <b>{{ addr.type }}</b>
                    </p>
                    <p>{{ addr.name }}</p>
                    <p>{{ addr.email }}</p>
                    <p>{{ addr.mobile }}</p>
                    <p>{{ addr.address }}</p>
                    <p>{{ addr.city }}, {{ addr.state }}, {{ addr.pincode }}</p>
                    <p>{{ addr.country }}</p>

                    <div class="action">
                      <router-link :to="'/address/edit/' + addr.id"
                        >Edit</router-link
                      >&nbsp;|&nbsp;
                      <button @click="removeAddress(addr.id)">Remove</button
                      >&nbsp;|&nbsp;
                      <button
                        v-if="!addr.default"
                        type="button"
                        @click="setDefault(addr.id)"
                      >
                        Set a Default
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('address');

export default {
  computed: {
    ...mapGetters(['addresses']),
  },
  methods: {
    removeAddress(id) {
      this.$store.dispatch('address/deleteAddress', id);
    },
    setDefault(id) {
      this.$store.dispatch('address/setDefaultAddress', id);
    },
  },
};
</script>

<style></style>
