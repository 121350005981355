import insert from '../../util/categoryUtil';

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      categoryAllChildren: [],
    };
  },
  getters: {
    categories(state) {
      return state.categories;
    },
    categoryAllChildren(state) {
      return state.categoryAllChildren;
    },
    options(state) {
      let x = [...state.categories];
      let y = [];
      let z = [];
      for (let i = 0; i < x.length; i++) {
        x[i].children = [];
        x[i].id = x[i]._id;
        x[i].label = x[i].name;
        if (!x[i].parent_category_id) {
          x[i].parent_category_id = '0';
        }
        if (x[i].parent_category_id === x[i]._id) break;
        if (x[i].parent_category_id === '0') {
          y.push(x[i]);
        } else {
          z.push(x[i]);
        }
      }
      z.forEach((item) => {
        insert(y, item);
      });
      return y;
    },
  },
  mutations: {
    reset(state) {
      state.categories = [];
      state.categoryAllChildren = [];
    },
    addCategories(state, data) {
      state.categories = data;
    },
    addCategoryAllChildren(state, data) {
      state.categoryAllChildren = data;
    },
  },
  actions: {
    addCategories({ commit }, data) {
      commit('addCategories', data);
    },
    addCategoryAllChildren({ commit }, data) {
      commit('addCategoryAllChildren', data);
    },
  },
};
