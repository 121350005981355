<template>
  <form method="post">
    <div class="bg-white cart-panel">
      <div class="breadcrumb-login">
        <a href="my-account.html">Your Account</a> >
        <a href="login-security.html">Login & Security</a> > Change Your
        Mobile/Phone
      </div>
      <div class="address-heading">Change Your Mobile/Phone</div>
      <div class="login-box">
        <div class="security last">
          <div class="row">
            <div class="col-md-12">
              <p>
                If you want to change the Mobile/Phone Number associated with
                your WMT customer account, you may do so below. Be sure to Click
                the
                <b>Save Changes</b> when your are done.
              </p>
              <label>Mobile/Phone</label>
              <input type="text" name="" value="7651925690" required />
              <button type="Submit">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {};
</script>

<style></style>
