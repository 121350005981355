<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="bg-white about">
              <h3>About WMT</h3>

              <p>
                <b>Who we are - India's most convenient online pharmacy</b
                ><br />
                WMT, India Ki Pharmacy, is one of India's most trusted
                pharmacies, experience in dispensing quality medicines. At WMT,
                we help you look after your own health effortlessly as well as
                take care of loved ones wherever they may reside in India. You
                can buy and send medicines from any corner of the country - with
                just a few clicks of the mouse.
              </p>

              <p>
                <b
                  >What we do – Offer fast online access to medicines with
                  convenient home delivery</b
                ><br />
                At WMT, we make a wide range of prescription medicines and other
                health products conveniently available all across India. Even
                second and third tier cities and rural villages can now have
                access to the latest medicines. Since we also offer generic
                alternatives to most medicines, online buyers can expect
                significant savings.
              </p>

              <p>
                <b>CONVENIENCE</b><br />
                Heavy traffic, lack of parking, monsoons, shop closed,
                forgetfulness… these are some of the reasons that could lead to
                skipping of vital medications. Since taking medicines regularly
                is a critical component of managing chronic medical conditions,
                it's best not to run out of essential medicines. Just log on to
                WMT, place your order online and have your medicines delivered
                to you – without leaving the comfort of your home.
              </p>
              <p>
                What's more, with easy access to reliable drug information, you
                get to know all about your medicine at WMT, and once you're a
                WMT customer, you'll get regular refill reminders, so you'll
                never again come up short of medicines.
              </p>

              <p>
                <b>ONE-STOP SHOPv</b> At WMT, we not only provide you with a
                wide range of medicines listed under various categories, we also
                offer a wide choice of OTC products including wellness products,
                vitamins, diet/fitness supplements, herbal products, pain
                relievers, diabetic care kits, baby/mother care products, beauty
                care products and surgical supplies.
              </p>

              <p>
                <b>TRUST</b><br />
                WMT continues a legacy of 100 years of success in the
                pharmaceutical industry. We are committed to provide safe,
                reliable and affordable medicines as well as a customer service
                philosophy that is worthy of our valued customers' loyalty. We
                offer a superior online shopping experience, which includes ease
                of navigation and absolute transactional security.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
