<template>
  <add-category />
</template>

<script>
import AddCategory from '@/components/seller/addcategory/AddCategory.vue';
export default {
  components: { AddCategory },
};
</script>

<style></style>
