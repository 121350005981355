<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <!-- document fields -->
                    <div class="col-lg-5">
                      <div class="document-field">
                        <p>Documents Required for seller to sale product:</p>
                        <div class="row mt-4">
                          <div class="col-md-12">
                            <div class="form-group">
                              <input
                                name=""
                                class="form-control form-control-md"
                                placeholder="Document Name"
                                required
                                v-model="documentName"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Description</label>
                              <textarea
                                name=""
                                id="editor-1"
                                v-model="documentDescription"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group mt-3">
                              <button
                                type="button"
                                class="form-control form-control-md"
                                @click="addDocument"
                              >
                                ADD
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- document fields end -->

                    <div class="col-lg-7">
                      <div class="document-field">
                        <p>Documents Lists:</p>
                        <table
                          cellpadding="0"
                          cellspacing=""
                          class="document-list"
                        >
                          <tr>
                            <th>#</th>
                            <th>Document Name</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>

                          <tr v-for="(req, index) in documents" :key="req.name">
                            <td>{{ index + 1 }}.</td>
                            <td>{{ req.name }}</td>
                            <td>{{ req.description }}</td>
                            <td>
                              <button
                                type=""
                                class="action-button blue-btn"
                                @click="editDocument(index)"
                              >
                                <i class="fa fa-edit"></i>
                              </button>
                              <button
                                type=""
                                class="action-button red-btn"
                                @click="deleteDocument(index)"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
<script>
import DocumentMasterService from '../../services/document-master';
import { warning, success } from '../../util/ToastUtil';
import Spinner from '../../components/Spinner.vue';

export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      documents: [],
      status: false,
      documentName: '',
      documentDescription: '',
      docId: null,
    };
  },
  mounted() {
    this.getAllDocuments();
  },
  methods: {
    async getAllDocuments() {
      try {
        this.loading = true;
        const res = await DocumentMasterService.getAllDocuments();
        console.log({ res });
        this.documents = res.data.DATA;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    validation() {
      if (this.documentName === '' || this.documentDescription === '') {
        this.$wkToast('Please Fill all the fields', warning);
        return false;
      }
      return true;
    },
    async addDocument() {
      if (!this.validation()) {
        return;
      }
      this.loading = true;
      try {
        const payload = {
          name: this.documentName,
          description: this.documentDescription,
          active: true,
          deleted: false,
        };
        const res = this.docId
          ? await DocumentMasterService.updateDocument(this.docId, payload)
          : await DocumentMasterService.createDocument(payload);
        if (res.status == 200) {
          this.$wkToast(
            `Document ${!this.docId ? 'created' : 'updated'} Successfully`,
            success
          );
          this.documentName = '';
          this.documentDescription = '';
          await this.getAllDocuments();
        }
      } catch (err) {
        console.log(err);
        this.$wkToast(
          `Document ${!this.docId ? 'creation' : 'updation'} Failed`,
          warning
        );
      }
      this.loading = false;
    },
    editDocument(index) {
      const doc = this.documents[index];
      this.documentName = doc.name;
      this.documentDescription = doc.description;
      this.docId = doc._id;
    },
    deleteDocument(index) {
      this.loading = true;
      setTimeout(() => {
        this.documents.splice(index, 1);
        this.loading = false;
      }, 1000);
    },
  },
};
</script>
