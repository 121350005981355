<template>
  <!-- Start of Mobile Menu -->
  <div class="mobile-menu-wrapper">
    <div class="mobile-menu-overlay"></div>
    <!-- End of .mobile-menu-overlay -->

    <a href="#" class="mobile-menu-close"><i class="close-icon"></i></a>
    <!-- End of .mobile-menu-close -->

    <div class="mobile-menu-container scrollable mobile-nav">
      <!-- <div class="tab">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a href="#main-menu" class="nav-link active">Main Menu</a>
          </li>
          <li class="nav-item">
            <a href="#categories" class="nav-link">Categories</a>
          </li>
        </ul>
      </div> -->
      <div class="tab-content">
        <div class="tab-pane active" id="main-menu">
          <ul class="mobile-menu mobile-nav" v-if="!isAdmin">
            <li><router-link to="/productcatalog">Home</router-link></li>
            <li>
              <a href="#">Catalogue</a>
              <ul>
                <li>
                  <router-link to="/add-new-product">Add Products</router-link>
                </li>
                <li>
                  <router-link to="/product-drafts"
                    >Complete Your Drafts</router-link
                  >
                </li>
                <li>
                  <router-link to="/selling-applications"
                    >View Selling Applications</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Inventory</a>
              <ul>
                <li>
                  <router-link to="/manage-inventory"
                    >Manage Inventory</router-link
                  >
                </li>
                <!-- <li>
                  <router-link to="/manage-product-documents"
                    >Manage Product Documents</router-link
                  >
                </li> -->
                <li>
                  <router-link to="/category-request"
                    >New Category Request</router-link
                  >
                </li>
              </ul>
            </li>
            <li><router-link to="/orders">Orders</router-link></li>
            <!-- <li><router-link to="/prescriptions">Prescriptions</router-link></li> -->
          </ul>
          <ul class="mobile-menu mobile-nav" v-else>
            <li><router-link to="/productcatalog">Home</router-link></li>
            <li>
              <a href="#">Catalogue</a>
              <ul>
                <li>
                  <router-link to="/add-new-product">Add Products</router-link>
                </li>
                <li>
                  <router-link to="/product-drafts"
                    >Complete Your Drafts</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Category</a>
              <ul>
                <li>
                  <router-link to="/admin/addcategory"
                    >Add Category</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/manage-category"
                    >Manage Category</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/category-requests"
                    >New Category Request</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <a href="">Products</a>
              <ul>
                <li>
                  <router-link to="/admin/manage-products"
                    >Manage Products</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <a href="">Seller</a>
              <ul>
                <li>
                  <router-link to="/admin/manage-sellers"
                    >Manage Seller</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/admin/document-master"
                >Document Master</router-link
              >
            </li>
            <li><router-link to="/orders">Orders</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Mobile Menu -->
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('auth');

export default {
  computed: {
    ...mapGetters(['isAdmin']),
  },
};
</script>

<style></style>
