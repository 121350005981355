<template>
  <div>
    <main class="main">
      <div v-if="loading" class="loader-container">
        <spinner />
      </div>
      <div v-else-if="isError" class="loader-container">
        <h5>There is no orders to show</h5>
      </div>
      <div v-else class="container">
        <div class="cart">
          <div class="row">
            <!-- orders -->
            <div class="col-md-9 mb-4">
              <div class="bg-white order-panel">
                <h2>Your Order Detail</h2>
                <div class="order">
                  <div class="order-header">
                    <div class="row">
                      <div class="col-md-3">
                        <h3>Shipping Address</h3>
                        <p>
                          {{ order?.shipping_address?.first_name }}
                          {{ order?.shipping_address?.last_name }}
                        </p>
                        <p>
                          {{ order?.shipping_address?.address1 }},
                          {{ order?.shipping_address?.address2 }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <h3>Payment method</h3>
                        <p>
                          {{
                            order?.payment_method?.payment_method_type === 1
                              ? "Cash"
                              : "Card/Online"
                          }}
                        </p>
                      </div>
                      <div class="col-md-4 text-right">
                        <h3>Order Summary</h3>
                        <p>Item(s) Subtotal:</p>
                        <p>Shipping:</p>
                        <p>Total:</p>
                        <p><b>Grand Total:</b></p>
                      </div>
                      <div class="col-md-2 text-right">
                        <b>&nbsp;</b>
                        <p><i class="fa fa-inr"></i> {{ subtotal }}</p>
                        <p><i class="fa fa-inr"></i> 0.00</p>
                        <p><i class="fa fa-inr"></i> {{ total }}</p>
                        <p>
                          <b><i class="fa fa-inr"></i> {{ gt_total }}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="order-detail">
                    <template v-for="(product, index) in order.products" :key="index">
                      <div class="row">
                        <div class="col-md-9">
                          <h3 class="order-delevery-day">
                            Delivered 28-Aug-2021
                          </h3>
                          <div class="row">
                            <div class="col-md-2">
                              <img
                                :src="product.imgurl[0]"
                                class="product-img"
                              />
                            </div>
                            <div class="col-md-10">
                              <a>{{product.product_basic_info[0].page_title}}</a>
                              <p>Sold by: uptodate products</p>
                              <p><i class="fa fa-inr"></i> {{order.order_item[index].price}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <button
                            id="raise-order-issue"
                            class="track-package"
                            @click="showOrderIssue = true"
                          >
                            <b>Raise Order Issue</b>
                          </button>
                          <button class="buying">Leave seller feedback</button>
                          <button class="buying">Write a product review</button>
                        </div>
                      </div>
                      <hr v-if="index < (order.products.length-1)"/>
                    </template>
                    <!-- <div class="row">
                      <div class="col-md-9">
                        <h3 class="order-delevery-day">
                          Delivered 28-Aug-2021
                        </h3>

                        <div class="row">
                          <div class="col-md-2">
                            <img
                              src="/images/product/5.jpg"
                              class="product-img"
                            />
                          </div>
                          <div class="col-md-10">
                            <a href="">Fast&Up 100%…</a>
                            <p>Sold by: uptodate products</p>
                            <p><i class="fa fa-inr"></i> 495.00</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <button id="raise-order-issue" class="track-package">
                          <b>Raise Order Issue</b>
                        </button>
                        <button class="buying">Leave seller feedback</button>
                        <button class="buying">Write a product review</button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- right panel -->
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-12 col-xs-6 mb-4">
                  <div class="bg-white cart-panel-right-sponsored">
                    <h2>Still looking for these essentials?</h2>
                    <!-- item -->
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <img src="/images/product/5.jpg" class="product-img" />
                      </div>
                      <div class="col-md-8">
                        <p class="product-name">Fast&Up 100%…</p>
                        <p class="rating">
                          <i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star gray"></i>
                        </p>
                        <p class="price">&#8377; 1999.00</p>
                        <button class="addtocart-btn">Add to Cart</button>
                      </div>
                    </div>
                    <!-- item end -->

                    <!-- item -->
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <img src="/images/product/6.jpg" class="product-img" />
                      </div>
                      <div class="col-md-8">
                        <p class="product-name">Horlicks Health &…</p>
                        <p class="rating">
                          <i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star gray"></i>
                        </p>
                        <p class="price">&#8377; 412.00</p>
                        <button class="addtocart-btn">Add to Cart</button>
                      </div>
                    </div>
                    <!-- item end -->

                    <!-- item -->
                    <div class="row mb-4">
                      <div class="col-md-4">
                        <img src="/images/product/6.jpg" class="product-img" />
                      </div>
                      <div class="col-md-8">
                        <p class="product-name">Horlicks Health &…</p>
                        <p class="rating">
                          <i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star gray"></i>
                        </p>
                        <p class="price">&#8377; 412.00</p>
                        <a href="" class="buying">See all buying options</a>
                      </div>
                    </div>
                    <!-- item end -->

                    <!-- item -->
                    <div class="row">
                      <div class="col-md-4">
                        <img src="/images/product/7.jpg" class="product-img" />
                      </div>
                      <div class="col-md-8">
                        <p class="product-name">MuscleBlaze Biozyme…</p>
                        <p class="rating">
                          <i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star yellow"></i
                          ><i class="fa fa-star gray"></i>
                        </p>
                        <p class="price">&#8377; 4499.00</p>
                        <a href="" class="buying">See all buying options</a>
                      </div>
                    </div>
                    <!-- item end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <order-issue-modal v-if="showOrderIssue" @shut="showOrderIssue = false" />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import OrderIssueModal from "@/components/customer/modal/OrderIssueModal.vue";
import OrderService from "@/services/order-service";
export default {
  components: { Spinner, OrderIssueModal },
  data() {
    return {
      showOrderIssue: false,
      loading: false,
      isError: false,
      order: {},
      shipping_charge : 40
    };
  },
  computed: {
    subtotal() {
      return Object.keys(this.order).length > 0
        ? this.order.order_item.map((v) => v.price * v.quantity).reduce((a, b) => a + b, 0)
        : 0;
    },
    total() {
      return Object.keys(this.order).length > 0
        ? this.order.order_item.map((v) => v.price * v.quantity).reduce((a, b) => a + b, 0)
        : 0;
    },
    gt_total() {
      var tot =  Object.keys(this.order).length > 0
        ? this.order.order_item.map((v) => v.price * v.quantity).reduce((a, b) => a + b, 0)
        : 0;
        return tot + this.shipping_charge;
    },
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.loading = true;
      OrderService.getOrderById(this.$route.params.id)
        .then((res) => {
          this.loading = false;
          this.order = res.data.DATA;
          if (Object.keys(this.order).length == 0) {
            this.isError = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isError = true;
        });
    },
  },
};
</script>

<style></style>
