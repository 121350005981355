import axios from 'axios';

const API_URL = `${process.env.VUE_APP_PRODUCT_SERVICE_API}api/`;//'https://wmt-seller-product.herokuapp.com/api/';
// const API_URL = 'http://localhost:3010/api/';

class ProductDraftService {
    /**
     * 
     * @param {String} vendor_id unique vendor object id
     * @param {Array} product_draft product draft array of object generated from csv
     * @returns return promise of axios
     */
    uploadCsvData(vendor_id, product_draft) {
        return axios.post(`${API_URL}seller/products_draft/${vendor_id}`, product_draft);
    }

    /**
     * 
     * @param {string} vendor_id unique vendor object id
     * @returns axios promise result
     */
    getVendorDraft(vendor_id){
        return axios.get(`${API_URL}seller/products_draft/${vendor_id}`);
    }

    /**
     * 
     * @param {string} vendor_id unique vendor object id
     * @param {string} draft_id unique product draft object id
     * @returns axios promise result
     */
    getVendorDraftById(vendor_id, draft_id){
        return axios.get(`${API_URL}seller/products_draft/${vendor_id}/${draft_id}`);
    }

    deleteVendorDraftById(vendor_id, draft_id){
        return axios.delete(`${API_URL}seller/products_draft/${vendor_id}/${draft_id}`);
    }
}

export default new ProductDraftService();