<template>
  <div class="info">
    <p>Explore Daily Offers On Medicine, Skin Care, Women Care, Covid Essentials
    And Medical Equipments</p>
    <a href="" @click="handleShop">Shop Now</a>
  </div>
</template>

<script>
export default {
  methods: {
    handleShop() {
      this.$wkToast('Comming Soon');
    },
  },
};
</script>
