import axios from 'axios';
require('dotenv').config();
// const API_URL = 'https://wmt-seller-product.herokuapp.com/api/';
const API_URL = `${process.env.VUE_APP_PRODUCT_SERVICE_API}api/`;
const CR_API_URL = `${process.env.VUE_APP_SELLER_CATEGORY_REQUEST_SERVICE_API}api/`;

class CategoryService {
  getAllCategory() {
    return axios.get(API_URL + 'allcategory');
  }
  getCategoryRequests() {
    return axios.get(API_URL + 'category/requests');
  }
  createCategory(payload) {
    return axios.post(API_URL + 'category/create', payload);
  }
  updateCategory(id, payload) {
    return axios.put(API_URL + 'category/' + id, payload);
  }
  getCategoryAllChildren() {
    return axios.get(API_URL + 'category/all/child');
  }

  createCategoryRequest(payload) {
    return axios.post(CR_API_URL + 'catgoryrequest/create', payload);
  }

  updateCategoryRequest(id, payload) {
    return axios.put(CR_API_URL + 'catgoryrequest/' + id, payload);
  }
  getAllCategoryRequests() {
    return axios.get(CR_API_URL + 'categoryrequests');
  }
  getCategoryRequestsById(id) {
    return axios.get(CR_API_URL + 'categoryrequest/' + id);
  }
  getCategoryRequestsByVendorId(vendor_id) {
    return axios.get(CR_API_URL + 'categoryrequest/', {
      params: {
        vendor_id,
      },
    });
  }
}

export default new CategoryService();
