import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.VUE_APP_PRODUCT_SERVICE_API}api/`;

const AUTH_API_URL = 'https://wmt-rbac.herokuapp.com/api/';

class UserService {
  signIn(payload) {
    return axios.post(AUTH_API_URL + 'auth/signin', payload);
  }

  signUp(payload) {
    return axios.post(AUTH_API_URL + 'auth/signup', payload);
  }

  userDetails(payload) {
    const token = payload;
    return axios.get(AUTH_API_URL + 'userdetails', { headers: { 'jwt-Token': payload } });
  }

  getUsers() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }
  getProducts() {
    return axios.get(API_URL + 'products', { headers: authHeader() });
  }
  getProductById(productId) {
    return axios.get(API_URL + 'product/' + productId, {
      headers: authHeader(),
    });
  }

  getProductByPageTitle(title) {
    return axios.get(`${API_URL}product_by_title/${encodeURI(title)}`, {
      headers: authHeader(),
    });
  }

  getProductBySlug(slug) {
    return axios.get(`${API_URL}product_by_slug/${slug}`, {
      headers: authHeader(),
    });
  }

  searchProducts(key) {
    return axios.get(API_URL + 'product/search/' + key, {
      headers: authHeader(),
    });
  }
  getCategories() {
    return axios.get(API_URL + 'allcategory', { headers: authHeader() });
  }
}

export default new UserService();
