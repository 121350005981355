import { createApp } from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import './styles/style.css';
import router from './router';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './store/modules/auth';
import product from './store/modules/product';
import category from './store/modules/category';
import companyProfile from './store/modules/companyProfile';
import productDrafts from './store/modules/product-drafts';
import sellerDocuments from './store/modules/seller-documents';
import cart from './store/modules/cart';
import order from './store/modules/order';
import address from './store/modules/address';

//Toast

import Toast from 'vue3-toast-single';
import 'vue3-toast-single/dist/toast.css';


import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'
import VueGoogleMaps from '@fawmi/vue-google-maps'

// Create a new store instance.
const store = createStore({
  plugins: [createPersistedState()],
  modules: {
    auth,
    product,
    category,
    companyProfile,
    productDrafts,
    sellerDocuments,
    cart,
    order,
    address,
  },
  actions: {
    resetAll({ commit }) {
      commit('auth/resetAll');
      commit('cart/resetAll');
      // commit('category/reset');
      commit('companyProfile/resetAll');
    },
  },
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueUniversalModal, {
  teleportTarget: '#modals'
});
app.use(Toast, { verticalPosition: 'bottom', duration: 1500 });
app.use(VueGoogleMaps, { load: { key: process.env.VUE_APP_GOOGLE_MAP_KEY, libraries: 'places' } });
app.use(VueAxios, axios);
app.mount('#app');
