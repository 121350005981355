<template>
  <div>
    <div class="mb-4">
      <div class="container bg-white">
        <div class="row">
          <div class="logohead">
            <img @click="logoJump" src="../../assets/images/logo.png" />
          </div>
        </div>
        <div class="row py-3">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12 mb-4">
                <carousel :items-to-show="1" :wrapAround="true" :autoplay="3000" :transition="400" >
                  <slide v-for="slide in 2" :key="slide">
                    <div style="width:60%;margin-top:40px;">
                      <img :src="'/images/login/img-' + slide + '.png'" alt="" />
                    </div>
                  </slide>
                  <template #addons>
                    <navigation />
                  </template>
                </carousel>
              </div>

            </div>
          </div>
          <div class="col-md-4">
            <form @submit.prevent="loginHandler" class="formbox">
              <div class="alert alert-danger" role="alert" v-if="isValidUser">
                Please check your user id and password.
              </div>
              <div class="loginform">
                <h1 class="text-left">Login</h1>

                <div class="email-login">
                  <label for="email">E-mail ID</label>
                  <input
                    type="text"
                    placeholder=""
                    name="uname"
                    required
                    v-model="email"
                    v-if="loginWithEmail"
                  />
                  <input
                    type="number"
                    placeholder="Enter mobile no"
                    name="uname"
                    required
                    v-model="mobileno"
                    v-else
                  />
                  <label for="psw">
                    Password
                    <a
                      v-if="false"
                      href="#"
                      style="
                        font-size: 11px;
                        text-decoration: none;
                        margin-left: 140px;
                      "
                      >Forget Password.?</a
                    >
                  </label>
                  <div class="show-password">
                    <input
                      :type="passwordFieldType"
                      placeholder="Enter Password"
                      name="psw"
                      maxlength="32"
                      required
                      v-model="password"
                    />
                    <button
                      type="button"
                      class="password-icon"
                      @click.prevent="showHidePassword"
                    >
                      <i
                        class="fa fa-eye"
                        v-if="showPassword"
                        aria-hidden="true"
                      ></i>
                      <i class="fa fa-eye-slash" v-else aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <label for="checkbox" style="margin-bottom:5px;display:block;">
                  <span style="font-size: 13px; font-weight: 500">
                    <input id="checkbox" type="checkbox" v-model="userconfirm" />
                    &nbsp;Stay signed in
                  </span>
                </label>
                <div>
                  <button class="signbtn">Sign in</button>
                </div>

                <div>
                  <!--<p>
                    <a
                      v-if="false"
                      @click.prevent="loginWithEmail = !loginWithEmail"
                      style="font-size: 11px; text-decoration: none"
                      >{{ loginWithEmail ? "Mobile number" : "Email" }} sign in</a
                    >
                  </p>-->
                  <!-- <div>
                  <router-link
                    style="font-size:14px; text-align:right; text-decoration:underline;"
                    :to="sellerLogin ? '/seller/login' : '/login'"
                  >
                    {{ sellerLogin ? 'Seller Login' : 'Customer Login' }}
                  </router-link>
                </div>-->
                <div>
                  <!-- <router-link to="/signup"> -->
                  <button style="width:94%;background:transparent;border:0;cursor:pointer;color:#666;" id="accountbtn" @click="openSignup">New on WMT? <span style="color:#00aeef;">Sign Up</span></button>
                  <!-- </router-link> -->
                </div>


                </div>

                <hr style="width: 95%; margin-left: -5px" />
                  <p class="or text-center" style="margin-top:-28px;">
                    <span style="background:#fff;">OR</span>
                  </p>
                  <div style="text-align: center;">Don't Have Seller Account? 
                    <router-link
                      style="font-size: 14px;text-decoration: none;"
                      to="/seller/signup">Click Here
                      </router-link>
                  </div>

                <!-- <div class="thirdparty">
                Sign in with :
                <a href="#"><img src="../../assets/images/fb.png"/></a>
                <a href="#"><img src="../../assets/images/go.png"/></a>
                <a href="#"><img src="../../assets/images/in.png"/></a>
                <a href="#"><img src="../../assets/images/tw.png"/></a>
              </div>-->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

import { createNamespacedHelpers } from "vuex";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
//import SellerService from "../../services/seller-service";
import Spinner from "../Spinner.vue";
const { mapGetters } = createNamespacedHelpers("auth");
export default {
  components: { Spinner,    Carousel,
    Slide, },

  data() {
    return {
      email: "",
      mobileno: "",
      password: "",
      userconfirm: true,
      isValidUser: false,
      loading: false,
      showPassword: false,
      loginWithEmail: true,

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isLogin", "isVendor"]),
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    showWarningToast(msg) {
      this.$wkToast(msg, {
        className: ["my-toast", "wk-alert"],
        horizontalPosition: "right",
        verticalPosition: "top",
        duration: 3000,
        transition: "transition",
      });
    },

    showSuccessToast(msg) {
      this.$wkToast(msg, {
        className: ["my-toast", "wk-success"],
        horizontalPosition: "right",
        verticalPosition: "top",
        duration: 3000,
        transition: "transition",
      });
    },

    logoJump() {
      debugger;
      window.location = window.location.origin;
    },

    openProductCatalog() {
      window.location = this.isVendor
        ? window.location.origin + "/productcatalog"
        : window.location.origin;
    },

    async loginHandler() {
      try {
        if (!this.validated()) {
          return;
        }
        this.loading = true;
        const payload = {
          password: this.password,
          email: this.email,
        };
        this.loginWithEmail
          ? (payload.email = this.email)
          : (payload.phonenumber = this.mobileno);
        // const res = await this.$store.dispatch('auth/userLogin', payload);
        let login_res = await AuthService.login(payload);
        const role = login_res.roles;
        let seller_res = await UserService.userDetails(login_res.jwtToken);
        let data = seller_res.data;
        data.role = role;
        data.firstname
          ? (data.username = data.firstname)
          : (data.username = data.email.split("@")[0]);
        this.$store.dispatch("auth/setUserInfo", data);
        this.$store.dispatch("auth/isLogin", true);
        this.loading = false;
        if (this.isLogin) {
          this.$router.push("/productcatalog");
          this.openProductCatalog();
        }
      } catch (err) {
        console.log({ Error: err });
        this.loading = false;
        if (err.response) this.showWarningToast(err.response.data.message);
        else this.showWarningToast("User Login Failed");
      }
    },

    openSignup() {
      this.$router.push("/signup");
    },

    showHidePassword() {
      return (this.showPassword = !this.showPassword);
    },

    validated() {
      if (this.loginWithEmail) {
        if (!this.isEmailValid(this.email)) {
          this.showWarningToast("Please Enter valid email");
          return false;
        }
      } else {
        if (!this.isMobileValid(this.mobileno)) {
          this.showWarningToast("Please enter correct mobile number");
          return false;
        }
      }
      if (this.password == "") {
        this.showWarningToast("Please Enter Password");
        return false;
      }
      if (this.password.length < 8) {
        this.showWarningToast("Password must not be less than 8 characters");
        return false;
      }

      if (this.password.length > 32) {
        this.showWarningToast(
          "Password must not be greater than 32 characters"
        );
        return false;
      }

      return true;
    },

    isEmailValid(email) {
      return email == "" ? false : this.reg.test(email) ? true : false;
    },

    isMobileValid(mobile) {
      let reg = /^\d{10}$/;
      return mobile.match(reg);
    },
  },
};
</script>
<style scoped>
.loading-container {
  width: 100%;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  padding: 10px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  width: 94%;
}

input:focus {
  -webkit-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  -moz-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  outline: 0;
}

select {
  padding: 8px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
  font-size: 12px;
}

select:focus {
  padding: 8px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
}
.show-password {
  position: relative;
}
.password-icon {
  position: absolute;
  right: 25px;
  top: 11px;
  background: transparent;
  border: 0px;
  padding: 5px;
}
</style>
