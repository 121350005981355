<template>
  <div class="tab-pane active in" id="tab4-2">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="tradeCapability">
      <div class="row" v-for="key in Object.keys(tradeCapability)" :key="key">
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>

        <div
          class="row"
          v-for="value in tradeCapability[key]"
          :key="value.name"
        >
          <div class="pt-4 pb-4">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-15">Not Available</div>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  props: ['vendorId'],
  data() {
    return {
      loading: false,
      tradeCapabilityId: '',

      trade: {
        BusinessTerms: [
          {
            name: 'Accepted Delivery Terms',
            des:
              'FOB, CFR, CIF, EXW, FAS, CIP, CPT, DDP, DDU, Express Delivery',
          },
        ],
        MainMarketProducts: [
          { name: 'Main Markets', des: 'North America' },
          { name: 'Total Revenue(%)', des: '20.00%' },
        ],
        TradeAbility: [
          { name: 'Language Spoken', des: 'English' },
          { name: 'No. of Employees in Trade Department', des: '6-10 People' },
        ],
      },
    };
  },
  computed: {
    tradeCapability() {
      if (this.tradeCapabilityBySellerId) {
        const { _id, customerid, ...co } = this.tradeCapabilityBySellerId;
        this.setTradeCapabiltyId(_id);
        return co;
      }

      return null;
    },
    tradeCapabilityBySellerId() {
      if (this.tradeCapabilities) {
        return this.tradeCapabilities[this.vendorId];
      }
      return null;
    },
    ...profileMapGetters(['tradeCapabilities']),
  },
  mounted() {
    if (this.tradeCapabilityId == '') {
      this.getTradeCapability();
    }
  },
  methods: {
    setTradeCapabiltyId(id) {
      if (id) this.tradeCapabilityId = id;
    },
    getTradeCapability() {
      this.loading = true;
      ProfileService.getTradeCapability(this.vendorId)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addTradeCapability', cover);
          } else {
            co = null; //this.trade;
            co.customerid = this.vendorId;
            this.$store.dispatch('companyProfile/addTradeCapability', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
