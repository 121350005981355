<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="document-field">
                        <p>All Products:</p>
                        <h6
                          v-if="products.length == 0"
                          class="d-flex justify-content-center align-items-center"
                        >
                          Products Not Found
                        </h6>
                        <table
                          v-else
                          cellpadding="0"
                          cellspacing=""
                          class="seller-list"
                        >
                          <tr>
                            <th>#</th>
                            <th width="10%">Image</th>
                            <th>Name</th>
                            <th>Price(&#8377; )</th>
                            <th>SKU</th>
                            <th>QTY</th>
                            <!-- <th>Seller</th> -->
                            <th>
                              Status
                            </th>
                            <th>
                              Seller Status
                            </th>
                            <th width="7%">Action</th>
                          </tr>

                          <tr
                            v-for="(product, index) in products"
                            :key="product._id"
                          >
                            <td>{{ index + 1 }}.</td>
                            <td>
                              <img
                                :src="
                                  product.imgurl
                                    ? product.imgurl[0]
                                    : '/no-image.jpg'
                                "
                                aria-hidden="true"
                                :alt="product.imgurl"
                                width="50"
                                height="50"
                                style="cursor:pointer"
                                @click="
                                  editProduct(
                                    product.name,
                                    product.category,
                                    product._id
                                  )
                                "
                              />
                            </td>
                            <td>
                              <h6
                                @click="
                                  editProduct(
                                    product.name,
                                    product.category,
                                    product._id
                                  )
                                "
                                style="cursor:pointer"
                              >
                                {{ product.name }}
                              </h6>
                            </td>
                            <td>
                              {{
                                getSellerInfo(
                                  product['Seller_Specific_Detail'][0],
                                  'default_price'
                                )
                              }}
                            </td>
                            <td>
                              {{
                                getSellerInfo(
                                  product['Seller_Specific_Detail'][0],
                                  'sku'
                                )
                              }}
                            </td>
                            <td>
                              {{
                                getSellerInfo(
                                  product['Seller_Specific_Detail'][0],
                                  'quantity'
                                )
                              }}
                            </td>
                            <!-- <td>
                              {{
                                getSellerInfo(
                                  product['Seller_Specific_Detail'][0],
                                  'customer_id'
                                )
                              }}
                            </td> -->
                            <td>
                              {{ product.is_approved ? 'Approved' : 'Pending' }}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="action-button blue-btn"
                                @click="updateProduct_Seller_Status(product, !product.Seller_Specific_Detail[0].active)"
                              >
                              {{ product.Seller_Specific_Detail[0].active ? 'Disable' : 'Enable' }}
                              </button>
                            </td>
                            <td>
                              <router-link v-if="product.is_approved" :to="`/active-product-edit/${product._id}`">
                                <i class="fa fa-edit" title="Edit"></i>
                              </router-link>
                              <button v-else
                                type=""
                                class="action-button blue-btn"
                                @click="
                                  editProduct(
                                    product.name,
                                    product.category,
                                    product._id
                                  )
                                "
                              >
                                <i
                                  class="fa fa-edit"
                                  title="
                                    Edit
                                  "
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
<script>
import UserService from '../../services/user.service';
import Spinner from '../../components/Spinner.vue';
import ProductService from '../../services/product-service';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('auth');
export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      prods: [],
      status: false,
    };
  },
  computed: {
    products() {
      return this.prods.filter(
        (prod) =>
          !prod.deleted &&
          prod.Seller_Specific_Detail &&
          prod.Seller_Specific_Detail.length
      );
    },
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getAllProducts();
  },
  methods: {
    productImageSrc(product){
      
    },
    filterProducts() {
      this.prods = this.prods.filter((prod) => {
        if (prod.Seller_Specific_Detail && prod.Seller_Specific_Detail.length) {
          // return prod.Seller_Specific_Detail.some(seller => seller.customer_id === this.userInfo.id)
          const seller = prod.Seller_Specific_Detail;
          let found = false;
          for (let i = 0; i < seller.length; i++) {
            if (seller[i].customer_id === this.userInfo.id) {
              found = true;
              break;
            }
          }
          return found;
        }
        return false;
      });
    },
    async getAllProducts() {
      try {
        this.loading = true;
        const res = await ProductService.getProductsByVendor(this.userInfo.id);
        // const res = await UserService.getProducts();
        const data = res.data.DATA;
        if (data && data.length) {
          this.prods = data.map((d) => {
            const product = { ...d.product_basic_info[0] };
            product.Seller_Specific_Detail = [];
            product.Seller_Specific_Detail.push(d);
            product['imgurl'] = d.imgurl.filter(x=>x);
            product['imgurl'] = product['imgurl'].length > 0 ? product['imgurl'] : '';
            return product;
          });
        }
        // console.log(this.prods);
        // this.prods.forEach((product) => {
        //   if (!product.imgurl.length) {
        //     product.imgurl = product['Seller_Specific_Detail'][0].imgurl;
        //   }
        // });
        // this.filterProducts();
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    getSellerInfo(obj, key) {
      return obj ? obj[`${key}`] : '';
    },
    editProduct(name, category, id) {
      this.$router.push({
        path: '/update-product/' + id,
      });
    },
    async updateProduct(product, is_approved, deleted) {
      let payload = { category_ids: product.category, is_approved, deleted };
      try {
        this.loading = true;
        await ProductService.updateProduct(product._id, payload);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateProduct_Seller_Status(product, active) {
      let payload = { vendor_id:this.userInfo.id, active };
      try {
        this.loading = true;
        await ProductService.updateProductSeller(product.Seller_Specific_Detail[0]._id, payload);
        this.getAllProducts();
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.seller-list {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.seller-list td,
.seller-list th {
  border: 1px solid #ddd;
  padding: 8px;
}
.seller-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #77abf0;
  color: white;
}
</style>
