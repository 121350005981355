<template>
  <div class="tab-pane active" id="tab4-6">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>

    <div v-else-if="!self || !showEdit">
      <div v-for="(doc, index) in documents" :key="doc._id">
        <div class="row">
          <div class="col-md-6">
            <h5>{{ doc.name }}</h5>
          </div>
          <div
            class="col-md-6 d-flex justify-content-end align-items-center"
            @click="showEdit = true"
            v-if="self && index == 0"
          >
            <i class="fas fa-edit "></i>
          </div>
        </div>
        <div class="row">
          <image-uploader class="ml-4" />
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="(doc, index) in documents" :key="doc">
        <div class="row mt-4">
          <div class="col-md-6">
            <div class="form-group">
              <label for=""
                ><h5>{{ doc.name }}</h5></label
              >
              <input-file :index="index" @onFileChange="onFileSelect" />
            </div>
          </div>
        </div>
        <div class="row" v-if="images[index]">
          <image-uploader
            :url="images[index].url"
            :index="0"
            showDelete="true"
            class="ml-4"
          />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-dark btn-rounded btn-sm mt-4 mb-4"
        @click="save"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ImageUploader from '@/components/util/ImageUploader.vue';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import DocumentMasterService from '@/services/document-master';
import InputFile from '@/components/util/InputFile.vue';

const { mapGetters } = createNamespacedHelpers('auth');

export default {
  components: { ImageUploader, Spinner, InputFile },
  data() {
    return {
      showEdit: false,
      loading: false,
      sellerId: this.$route.params.id,
      documents: [],
      images: {},
    };
  },
  computed: {
    self() {
      return this.sellerId === this.userInfo.id;
    },
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getAllDocuments();
  },
  methods: {
    onFileSelect({ index, file }) {
      if (file) {
        const url = URL.createObjectURL(file);
        this.images[index] = { file, url };
      }
    },
    async getAllDocuments() {
      try {
        this.loading = true;
        const res = await DocumentMasterService.getAllDocuments();

        console.log({ res });
        this.documents = res.data.DATA;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
