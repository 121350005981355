import S3 from 'aws-s3';
import shortId from 'short-uuid';
import Crypto, { WordArray } from "crypto-js";

const dirName = 'CategoryImages';

function s3BaseUrl() {
  return 'https://wmt-productbuckets3.s3.ap-south-1.amazonaws.com';
}
export function s3NewFileName() {
  return Math.random()
    .toString()
    .slice(2);
}

const config = {
  bucketName: 'wmt-productbuckets3',
  dirName: dirName /* optional */,
  region: 'ap-south-1',
  accessKeyId: 'AKIA4AZK7JMCMNTJTA4H',
  secretAccessKey: '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',
  s3Url: s3BaseUrl() /* optional */,
};

export const s3NewFileUrl = `${s3BaseUrl()}/${dirName}`;

export const S3Client = new S3(config);

export const s3Upload = async function (file, newFileName) {
  const dateISOString = new Date(+new Date() + 864e5).toISOString();
  const xAmzDate = dateISOString.split("-").join("").split(":").join("").split(".").join("");
  const dateYMD = dateISOString.split("T")[0].split("-").join("");

  const policy = () => {
    return {
      expiration: dateISOString,
      conditions: [
        { acl: "public-read" },
        { bucket: config.bucketName },
        ["starts-with", "$key", `${config.dirName ? config.dirName + "/" : ""}`],
        ["starts-with", "$Content-Type", ""],
        ["starts-with", "$x-amz-meta-tag", ""],
        { "x-amz-algorithm": "AWS4-HMAC-SHA256" },
        {
          "x-amz-credential": `${config.accessKeyId}/${dateYMD}/${config.region
            }/s3/aws4_request`
        },
        { "x-amz-date": xAmzDate },
        { "x-amz-meta-uuid": "14365123651274" },
        { "x-amz-server-side-encryption": "AES256" }
      ]
    };
  };
  //Returns a base64 policy;
  const PolicySign = new Buffer(JSON.stringify(policy())).toString("base64").replace(/\n|\r/, "");

  const getSignature = (config, date, policyBase64) => {
    const getSignatureKey = (key, dateStamp, regionName) => {
      const kDate = Crypto.HmacSHA256(dateStamp, "AWS4" + key);
      const kRegion = Crypto.HmacSHA256(regionName, kDate);
      const kService = Crypto.HmacSHA256("s3", kRegion);
      const kSigning = Crypto.HmacSHA256("aws4_request", kService);
      return kSigning;
    };
  
    const signature = (policyEncoded) => {
      return Crypto.HmacSHA256(
        policyEncoded,
        getSignatureKey(config.secretAccessKey, date, config.region)
      ).toString(Crypto.enc.Hex);
    };
    return signature(policyBase64);
  };

  const fd = new FormData();
  let fileExtension = file.type.split('/')[1];
  if (fileExtension === "svg+xml") {
    fileExtension = "svg";
  }
  const fileName = `${newFileName || shortId.generate()}.${fileExtension}`;
  const key = `${config.dirName ? config.dirName + "/" : ""}${fileName}`;
  const url = config.s3Url;

  fd.append("key", key);
  fd.append("acl", "public-read");
  fd.append("Content-Type", file.type);
  fd.append("x-amz-meta-uuid", "14365123651274");
  fd.append("x-amz-server-side-encryption", "AES256");
  fd.append(
    "X-Amz-Credential",
    `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`
  );
  fd.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
  fd.append("X-Amz-Date", xAmzDate);
  fd.append("x-amz-meta-tag", "");
  fd.append("Policy", PolicySign);
  fd.append(
    "X-Amz-Signature",
    getSignature(config, dateYMD, PolicySign)
  );
  fd.append("file", file);

  const data = await fetch(url, { method: "post", body: fd });
  if (!data.ok) return Promise.reject(data);
  return Promise.resolve({
    bucket: config.bucketName,
    key: `${config.dirName ? config.dirName + "/" : ""}${fileName}`,
    location: `${url}/${config.dirName ? config.dirName + "/" : ""}${fileName}`,
    status: data.status
  });
};
