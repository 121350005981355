<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel"></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
