<template>
  <main class="main">
    <div class="container">
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height:200px;"
      >
        <spinner />
      </div>
      <div
        v-else-if="products.length == 0"
        class="d-flex justify-content-center align-items-center"
        style="height:200px;"
      >
        <h3>Products Not Found</h3>
      </div>
      <div v-else>
        <product-list :products="products" />
      </div>
    </div>
  </main>
</template>

<script>
import ProductList from '@/components/customer/products/ProductList.vue';
import Spinner from '@/components/Spinner.vue';
import UserService from '@/services/user.service.js';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('product');
export default {
  components: {
    ProductList,
    Spinner,
  },
  data() {
    return {
      products: '',
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['productSearchKey']),
  },
  mounted() {
    console.log(this.productSearchKey);
    this.searchProducts(this.productSearchKey);
  },
  watch: {
    productSearchKey: function(val) {
      this.searchProducts(this.productSearchKey);
    },
  },
  methods: {
    searchProducts(key) {
      console.log(key);
      this.loading = true;
      UserService.searchProducts(key).then(
        (response) => {
          this.products = response.data.DATA;
          console.log(this.products);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
