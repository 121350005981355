<template>
  <div class="tab-pane active" id="tab4-6">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>

    <div v-else  class="p-15">
      <div v-for="key in Object.keys(factoryinspectionReport)" :key="key">
        <div class="row">
          <div class="col-md-6">
            <h5>{{ key }}</h5>
          </div>
        </div>
        <div class="row">
          <image-uploader
            v-for="url in factoryinspectionReport[key]"
            :key="url"
            :url="url"
            class="ml-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUploader from '@/components/util/ImageUploader.vue';
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { ImageUploader, Spinner },
  props: ['vendorId'],
  data() {
    return {
      showEdit: false,
      loading: false,
      factoryInspectionId: '',
      ireports: {
        SupplierAssesment: [],
        MainProductLinesVerificationReport: [],
      },
    };
  },
  computed: {
    factoryinspectionReport() {
      if (this.factoryInspectionReportBySellerId) {
        const {
          _id,
          imgurl,
          customerid,
          ...co
        } = this.factoryInspectionReportBySellerId;
        this.setfactoryInspectionId(_id);
        return co;
      }

      return {};
    },
    factoryInspectionReportBySellerId() {
      if (this.factoryInspectionReports) {
        return this.factoryInspectionReports[this.vendorId];
      }
      return null;
    },
    ...profileMapGetters(['factoryInspectionReports']),
  },
  mounted() {
    if (this.factoryInspectionId == '') {
      this.getFactoryInspecction();
    }
  },
  methods: {
    setfactoryInspectionId(id) {
      if (id) this.factoryInspectionId = id;
    },
    async getFactoryInspecction() {
      try {
        this.loading = true;
        const res = await ProfileService.getFactoryInspecctionBySellerId(
          this.vendorId
        );
        console.log({ res });
        let co = res.data.DATA;
        if (co) {
          const {
            created_on_utc_date,
            modification_notes,
            __v,
            deleted,
            ...cover
          } = co;
          this.$store.dispatch(
            'companyProfile/addFactoryInspectionReport',
            cover
          );
        } else {
          co = { ...this.ireports };
          co.customerid = this.vendorId;
          this.$store.dispatch('companyProfile/addFactoryInspectionReport', co);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
