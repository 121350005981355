<template>
  <main class="main bg-white">
    <div class="full-wrapper">
      <h4>Prescriptions</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="right-panel">
            <div class="search">
              <form>
                <input
                  type="text"
                  name=""
                  placeholder="Search by Cuetomer Name"
                  class="search-control"
                />
                <button name=""><i class="fa fa-search"></i></button>
              </form>
            </div>
            <div class="appication-data">
              <table cellpadding="0" cellspacing="0" class="data-table">
                <tr>
                  <th style="width:60% !important">
                    Customer Name
                    <span>Case ID</span>
                  </th>
                  <th style="width:10% !important">Prescription</th>
                  <th style="width:10% !important">Date<span>Time</span></th>
                  <th style="width:10% !important">Status</th>
                  <th style="width:10% !important">Update Status</th>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <a href=""><img src="/images/prescription-icon.jpg"/></a>
                  </td>
                  <td>13/10/21<span>02:56 PM</span></td>
                  <td>Pending</td>
                  <td>
                    <select name="" class="update-control">
                      <option value="">Pending</option>
                      <option value="">Approved</option>
                      <option value="">Reject</option>
                    </select>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
