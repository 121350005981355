<template>
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <div class="col-lg-5">
                      <p class="category-panel-heading">Manage Category</p>
                      <div class="form-group">
                        <input
                          type="text"
                          id=""
                          name=""
                          placeholder="Category Name"
                          class="form-control form-control-md"
                          required
                          v-model="categoryName"
                        />
                      </div>

                      <!-- required fields -->
                      <div class="required-field">
                        <p>Required fields to add product in this category:</p>
                        <chip
                          v-for="(field, index) in requiredFields"
                          :key="index"
                          :text="field.required_field_name"
                          :index="index"
                          @close="deleteRequiredField"
                        />

                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group">
                              <input
                                type="text"
                                id=""
                                name=""
                                placeholder="Field Name"
                                class="form-control form-control-md"
                                required
                                v-model="fieldName"
                              />
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="form-group">
                              <select
                                name=""
                                class="form-control form-control-md"
                                required
                                v-model="fieldType"
                              >
                                <option value="text">Type</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Calander</option>
                                <option value="table">Table</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <button
                                type="button"
                                class="form-control form-control-md"
                                @click="addRequiredField"
                              >
                                ADD
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- required fields end -->

                      <!-- document fields -->
                      <div class="document-field">
                        <p>
                          Documents Required for seller to sale in this
                          category:
                        </p>
                        <div class="row mt-4">
                          <div class="col-md-10">
                            <div class="form-group">
                              <select
                                name=""
                                class="form-control form-control-md"
                                required
                                v-model="document"
                              >
                                <option value="" disabled>
                                  Select Document
                                </option>
                                <option
                                  v-for="doc in masterDocuments"
                                  :value="doc.name"
                                  :key="doc._id"
                                >
                                  {{ doc.name }}
                                </option>
                                <!-- <option value="Drug License"
                                  >Drug License</option
                                > -->
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <button
                                type="button"
                                class="form-control form-control-md"
                                @click="addRequiredDocument"
                              >
                                ADD
                              </button>
                            </div>
                          </div>
                        </div>
                        <chip
                          v-for="(document, index) in requiredDocuments"
                          :key="index"
                          :text="document.required_field_name"
                          :index="index"
                          @close="deleteRequiredDocuments"
                        />
                      </div>
                      <!-- document fields end -->

                      <div class="form-group main-cat mt-3 mb-3">
                        <input
                          type="checkbox"
                          id=""
                          name=""
                          class="main-check"
                          v-model="mainCategory"
                        />
                        Is Main Category
                      </div>

                      <div class="main-category" v-if="mainCategory">
                        <image-uploader
                          v-if="url"
                          :url="url"
                          index="0"
                          showDelete="true"
                          @delete-file="deleteUrl"
                        />
                        <!-- </div> -->
                        <div class="fileUpload">
                          <span
                            ><img src="/images/up.png" /> UPLOAD CATEGORY
                            IMAGE</span
                          >
                          <input
                            type="file"
                            class="upload"
                            @change="onFileSelect"
                          />
                        </div>
                        <div class="form-group mt-3">
                          Is in Direct Home Menu
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            class="main-check"
                            v-model="homeMenu"
                          />
                        </div>
                      </div>

                      <div class="" v-else>
                        <p>{{ mapping }}</p>
                        <div class="cat-heading">Under Category</div>
                        <treeselect
                          v-model="value"
                          :multiple="false"
                          :options="options"
                          placeholder="Selec a category"
                          noChildrenText="No Sub-Category"
                          @select="onSelectCategory"
                        />
                      </div>

                      <!-- sub category end-->
                    </div>
                    <div class="col-lg-7 editor" :style="cssVars">
                      <p class="category-panel-heading">
                        Suggested Template for Products in this Category
                      </p>
                      <ckeditor
                        :editor="editor"
                        v-model="editorData"
                        :config="editorConfig"
                      />

                      <button
                        type="button"
                        class="btn btn-dark btn-rounded btn-sm mt-5 pull-right"
                        @click="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import CategoryService from "@/services/category-service";
import { createNamespacedHelpers } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Spinner from "@/components/Spinner.vue";
import {
  S3Client,
  s3NewFileName,
  s3NewFileUrl,
  s3Upload,
} from "@/util/s3upload";
import Chip from "./Chip.vue";
import ImageUploader from "@/components/util/ImageUploader.vue";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { warning, success } from "@/util/ToastUtil";
import DocumentMasterService from "@/services/document-master";

const { mapGetters } = createNamespacedHelpers("category");

const MAX_SIZE = 100000;
const MAX_WIDTH = 1000;
const MAX_HEIGHT = 1000;

export default {
  props: ["catId", "parentCategoryId"],
  components: {
    Spinner,
    ckeditor: CKEditor.component,
    Chip,
    ImageUploader,
    Treeselect,
  },
  data(props) {
    return {
      loading: false,
      categoryName: "",
      selectCat: "0",
      categoryKey: "",
      subCategoryKey: "",
      description: "",

      url: "",
      file: "",

      //ckeditor
      editor: ClassicEditor,
      editorData: "<html><p>Content of editor </p></html>",
      editorConfig: {
        //
      },
      fieldName: "",
      fieldType: "text",
      requiredFields: [],

      document: "",
      requiredDocuments: [],

      mainCategory: true,
      homeMenu: false,
      mappingCategory: "",

      value: props?.parentCategoryId || null,

      image: {
        size: "",
        height: "",
        width: "",
      },
      masterDocuments: [],
    };
  },
  computed: {
    isToUpdate() {
      return this.catId ? true : false;
    },
    ...mapGetters(["categories"]),
    parentCategories() {
      this.categories.forEach((ct) => {
        ct.showDropDown = false;
        if (!ct.parent_category_id) ct.parent_category_id = "0";
      });
      return this.categories.filter(
        (ct) => !ct.parent_category_id || ct.parent_category_id === "0"
      );
    },
    mapping() {
      this.resetMappingCategory();
      if (this.value) this.getAllParentsOfCategory(this.value);
      return this.mappingCategory;
    },
    options() {
      let x = this.categories.map((ct) => ct);
      return this.getAllCatChild(x);
      // let y = [];
      // let z = [];
      // for (let i = 0; i < x.length; i++) {
      //   x[i].children = [];
      //   x[i].id = x[i]._id;
      //   x[i].label = x[i].name;
      //   if (x[i].is_main_category || !x[i].parent_category_id) {
      //     x[i].parent_category_id = "0";
      //   }
      //   if (x[i].parent_category_id === x[i]._id) continue;
      //   if (x[i]._id === this.selectCat) continue;
      //   if (x[i].is_main_category) {
      //     y.push(x[i]);
      //   } else {
      //     z.push(x[i]);
      //   }
      // }
      // z.forEach((item) => {
      //   this.insert(y, item);
      // });
      // console.log({ y });
      // return y;
    },

    cssVars() {
      return {
        "--ckeditor-height": "500px",
      };
    },
  },
  mounted() {
    this.getAllCategory();
    this.getDocuments();
  },
  methods: {
    getAllCatChild(data){
      var result = [];
      for (var par of data) {
        if (par.is_main_category) {
          result.push({ ...par, children: this.get_childrens(data, par), id: par._id, label: par.name });
        }
      }
      return result;
    },
    get_childrens(data, item) {
      return data.filter((v) => v.parent_category_id === item._id).map((x) => {
        return data.filter((v) => v.parent_category_id === x._id).length > 0 ? { ...x, id: x._id, label: x.name, children: this.get_childrens(data, x) } : { ...x, id: x._id, label: x.name, children: [] };
      })
    },
    async getDocuments() {
      this.loading = true;
      try {
        const res = await DocumentMasterService.getAllDocuments();
        if (res.status == 200) {
          this.masterDocuments = res.data.DATA;
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },

    showDropDown(ct) {
      this.categories.map((cat) => (cat.showDropDown = false));
      ct.showDropDown = !ct.showDropDown;
      if (!ct.parent_category_id || ct.parent_category_id === "0") {
        this.mapping = ct.name;
      } else {
        console.log(ct.name);
        if (ct.name || ct.name !== "")
          this.mapping = `${this.mapping}>>${ct.name}`;
      }
      this.selectCat = ct._id;
    },

    resetMappingCategory() {
      this.mappingCategory = "";
    },
    getAllParentsOfCategory(id) {
      if (!id || id === "0") return;
      let found = false;
      let ct = {};
      for (let i = 0; i < this.categories.length; i++) {
        ct = this.categories[i];
        if (ct._id === id) {
          this.mappingCategory !== ""
            ? (this.mappingCategory = `${ct.name} >> ${this.mappingCategory}`)
            : (this.mappingCategory = ct.name);
          found = true;
          break;
        }
      }
      if (found) this.getAllParentsOfCategory(ct.parent_category_id);
    },
    getChildrenCategories(categoryId) {
      return this.categories.filter((ct) => {
        if (ct.parent_category_id && ct.parent_category_id === categoryId) {
          ct.hasChildren = true;
          return true;
        } else {
          ct.hasChildren = false;
          return false;
        }
      });
    },
    deleteUrl() {
      (this.file = null), (this.url = null);
    },
    onFileSelect(e) {
      const file = e.target.files[0];

      //file validation

      if (!file || file.type.indexOf("image/") !== 0) {
        this.$wkToast("Select file is not supported", warning);
        e.target.value = "";
        return;
      }
      this.image.size = file.size;
      if (this.image.size > MAX_SIZE) {
        this.$wkToast(
          `The image size (${this.image.size}) is too much (max is ${MAX_SIZE}).`,
          warning
        );
        return;
      }

      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          console.log(this.image);
          if (this.image.width > MAX_WIDTH) {
            this.$wkToast(
              `The image width (${this.image.width}) is too much (max is ${MAX_WIDTH}).`,
              warning
            );
            e.target.value = "";
            return;
          }
          if (this.image.height > MAX_HEIGHT) {
            e.target.value = "";
            this.$wkToast(
              `The image height (${this.image.height}) is too much (max is ${MAX_HEIGHT}).`,
              warning
            );
            return;
          }
          this.file = file;
          this.url = URL.createObjectURL(this.file);
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
    },

    addRequiredField() {
      if (this.fieldType === "" || this.fieldName === "")
        return alert("Select Field Type");
      this.requiredFields.push({
        required_field_name: this.fieldName,
        required_field_type: this.fieldType,
      });
      this.fieldName = "";
      this.fieldType = "text";
    },
    addRequiredDocument() {
      if (this.document === "") return alert("Select any document");
      this.requiredDocuments.push({
        required_field_name: this.document,
        required_field_type: "document",
      });
    },
    deleteRequiredField(index) {
      if (index > -1) {
        this.requiredFields.splice(index, 1);
      }
    },

    deleteRequiredDocuments(index) {
      if (index > -1) {
        this.requiredDocuments.splice(index, 1);
      }
    },
    getAllCategory() {
      if (!this.categories.length) {
        this.loading = true;
        CategoryService.getAllCategory()
          .then((res) => {
            const data = res.data.DATA;
            if (data.length) {
              this.$store.dispatch("category/addCategories", data);
            }
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
      if (this.catId) {
        try {
          const cate = this.categories.find((ct) => ct._id === this.catId);
          if (cate) {
            this.categoryName = cate.name;
            this.selectCat = cate._id;
            this.requiredDocuments = cate.mandatory_for_seller;
            this.requiredFields = cate.mandatory_for_product;
            this.mainCategory = cate.is_main_category;
            this.homeMenu = cate.is_direct_home_menu;

            if (cate.product_template) {
              setTimeout(() => {
                this.editorData = cate.product_template;
              }, 1000);
            }

            if (cate.imgurl && cate.imgurl.length > 0) {
              this.url = cate.imgurl[0];
            }
            if (this.mainCategory) {
              this.value = null;
              cate.parent_category_id = "0";
            }
            if (
              typeof cate.parent_category_id !== undefined &&
              cate.parent_category_id !== 0
            )
              this.value = this.cate?.parent_category_id;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },

    async submit() {
      if (!this.validation()) {
        return;
      }

      if (!this.mainCategory) this.homeMenu = false;
      this.loading = true;
      await this.uploadToS3();
      var payload = {
        name: this.categoryName,
        description: "general escription of category",

        mandatory_for_product: this.requiredFields,
        mandatory_for_seller: this.requiredDocuments,

        is_main_category: this.mainCategory,
        is_direct_home_menu: this.homeMenu,
        active: true,
        imgurl: this.mainCategory ? [this.url] : [],
        product_template: this.editorData,
      };
      if (!this.mainCategory) {
        payload.parent_category_id = this.value;
      } else {
        payload.parent_category_id = "";
      }
      payload.imgurl = payload.imgurl.filter((x) => x);
      await this.createCategory(payload);
    },
    async createCategory(payload) {
      try {
        // console.log(payload);
        this.loading = true;
        let res = this.catId
          ? await CategoryService.updateCategory(this.catId, payload)
          : await CategoryService.createCategory(payload);
        // console.log({ res });
        if (res.data.STATUS === "SUCCESS") {
          const text = this.catId ? "Updated" : "Created";
          this.$wkToast(`Category ${text} Successfully`, success);
          res = await CategoryService.getAllCategory();
          const data = res.data.DATA;
          // console.log(data);
          if (data.length) {
            this.$store.dispatch("category/addCategories", data);
          }

          this.$router.push("/productcatalog");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$wkToast(`Category Createtion Failed`, warning);
      }
    },
    async uploadToS3() {
      if (this.mainCategory && this.file) {
        let fileExtension = this.file.type.split("/")[1];
        if (fileExtension === "svg+xml") {
          fileExtension = "svg";
        }
        const s3NewFile = s3NewFileName();
        try {
          var result = await s3Upload(this.file, s3NewFile);
          // console.log({result});
        } catch (err) {
          // console.log({err});
        } finally {
          const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
          this.url = imageurl;
        }
      }
    },

    validation() {
      if (!this.categoryName || this.categoryName === "") {
        this.$wkToast("Please enter category name", warning);
        return false;
      }
      if (!this.mainCategory && (!this.value || this.value === "")) {
        this.$wkToast("Please select any under category", warning);
        return false;
      }
      return true;
    },

    insert(arr, value) {
      arr.forEach((item) => {
        if (item) {
          if (item._id === value.parent_category_id) {
            if (item["children"]) item["children"] = [];
            item["children"].push(value);
            // return false;
          } else {
            if (item["children"] && item["children"].length > 0)
              this.insert(item["children"], value);
            // return true;
          }
        }
      });
    },
    onSelectCategory() {
      const id = this.value;
      const cat = this.categories.find((c) => c._id === id);
      if (cat) {
        const requiredFields = cat.mandatory_for_product.map((item) => {
          return {
            required_field_name: item.required_field_name,
            required_field_type: item.required_field_type,
          };
        });

        const requiredDocuments = cat.mandatory_for_seller.map((item) => {
          return {
            required_field_name: item.required_field_name,
            required_field_type: item.required_field_type,
          };
        });
        this.requiredFields = requiredFields;
        this.requiredDocuments = requiredDocuments;
      }
    },
  },
};
</script>
<style>
.main-category {
  display: block;
}

.category-panel-heading {
  font-weight: 500 !important;
  font-size: 14px;
  border-bottom: 1px solid #2a9cf5;
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  background: #2a9cf5;
}

.ck-editor__editable {
  height: var(--ckeditor-height);
}
</style>
