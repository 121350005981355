<template>
  <div class="bg-white-div">
    <div class="row">
      <div class="col-md-12">
        <h2>Featured Brand <span> <a @click.prevent="viewAllHandler">View More</a></span></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-1.png" alt="" />
        <span>Dabar</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-2.png" alt="" />
        <span>Cipla</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-3.png" alt="" />
        <span>Hansaplast</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-4.png" alt="" />
        <span>Mankind</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-5.png" alt="" />
        <span>Truebasics</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-6.png" alt="" />
        <span>Accu-Chek</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-7.png" alt="" />
        <span>Abbott</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-8.png" alt="" />
        <span>Zydus</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-9.png" alt="" />
        <span>Mankind</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-10.png" alt="" />
        <span>Mankind</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-11.png" alt="" />
        <span>Mankind</span>
      </div>
      </div>
      <div class="col-3 col-md-1">
       <div class="text-center brand">
        <img src="/images/brand/brand-12.png" alt="" />
        <span>Mankind</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brands: {
        title: 'Feature brands',
        brandlist: [
          {
            name: 'Dabur',
            avatar: '/images/brand/brand-1.png',
          },
          {
            name: 'Cipla',
            avatar: '/images/brand/brand-2.png',
          },
          {
            name: 'Hansaplast',
            avatar: '/images/brand/brand-3.png',
          },
          {
            name: 'Mankind',
            avatar: '/images/brand/brand-4.png',
          },
          {
            name: 'TrueBasics',
            avatar: '/images/brand/brand-5.png',
          },
          {
            name: 'Accu-check',
            avatar: '/images/brand/brand-6.png',
          },
          {
            name: 'Abott',
            avatar: '/images/brand/brand-7.png',
          },
          {
            name: 'Zydus',
            avatar: '/images/brand/brand-8.png',
          },
        ],
      },
    };
  },
  methods: {
    viewAllHandler() {
      this.$wkToast('Comming Soon');
    },
  },
};
</script>
