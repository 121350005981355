<template>
  <div>
    <div
      id="select-location-box"
      class="select-location-box"
      style="display: block; z-index: 1000"
      v-if="!showMap"
    >
      <div class="select-location">
        <h1 class="modal-close">
          <span> Choose Your Location </span>
          <i
            class="fa fa-times p-4"
            area-hidden="true"
            @click="$emit('shut')"
          ></i>
        </h1>
        <div class="select-location-inner">
          <p>
            Select a delivery location to see product availability and delivery
            options
          </p>
          <button
            class="sign-in-address"
            type="button"
            v-if="!isLogin"
            @click="$router.push('/login')"
          >
            Sign in to see your address
          </button>
          <div v-if="isLogin">
            <!-- <div
              class="address mt-15 block-with-text"
              :class="selectedAddress == 1 ? 'active' : ''"
              @click="selectedAddress = 1"
            >
              {{ getShippingAddress }}
            </div>
            <div
              class="address block-with-text"
              :class="selectedAddress == 2 ? 'active' : ''"
              @click="selectedAddress = 2"
            >
              {{ getBillingAddress }}
            </div> -->
            <template v-if="addresses.length > 0">
              <div
                v-for="(address, index) in addresses"
                :key="index"
                class="address block-with-text"
                :class="addressIndex == index ? 'active' : ''"
                @click="addressIndex = index"
              >
                {{ printAddress(address) }}
              </div>
            </template>
            <router-link to="/address" class="add-more-address"
              >Add an address or pick-up point</router-link
            >
          </div>
          <div class="divide"><span>OR</span></div>

          <select name="" class="form-control">
            <option value="" disabled>Select Country</option>
            <option value="IN" selected>India</option>
          </select>

          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Enter Your Pincode"
            maxlength="6"
            oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          />

          <select name="" class="form-control">
            <option value="">Select State</option>
            <option value="Haryana" selected>Haryana</option>
          </select>

          <select name="" class="form-control">
            <option value="">Select City</option>
            <option value="Gurugram" selected>Gurugram</option>
          </select>

          <button type="button" @click="showMap = true">
            Select Location on Map
          </button>
        </div>
      </div>
    </div>

    <div id="map-popup" class="map-box" v-if="showMap">
      <div class="map">
        <button id="closemap" class="closemap" @click="$emit('shut')">
          <i class="fa fa-times"></i>
        </button>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1781789.360459031!2d74.90523121727364!3d29.280645103527174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390db00b8670400b%3A0x732eaab4aaa6c143!2sHaryana!5e0!3m2!1sen!2sin!4v1634110570445!5m2!1sen!2sin"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("auth");
const addressMapGetters = createNamespacedHelpers("address").mapGetters;

export default {
  data() {
    return {
      showMap: false,
      selectedAddress: 1,
      addressIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
    ...addressMapGetters([
      "getBillingAddress",
      "getShippingAddress",
      "addresses",
    ]),
  },
  methods: {
    printAddress(address) {
      return `${address.name || ""}
               ${address.address || ""}
                ${address.contact || ""}
                ${address.email || ""}
                 ${address.city || ""}
                  ${address.state || ""}
                ${address.country || ""}`;
    },
  },
};
</script>
<style scoped>
.block-with-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.map-box {
  display: block;
}
.modal-close {
  display: flex;
  justify-content: space-between;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: rgb(226, 224, 224);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #000;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
