import axios from 'axios';

const API_URL = `${process.env.VUE_APP_CART_SERVICE_API}api/`;
const CART_API_URL = `${process.env.VUE_APP_CART_SERVICE_API}api/`;

class OrderService {
  getOrders(customerId) {
    return axios.get(API_URL + 'orders', {
      params: {
        customerid: customerId,
      },
    });
  }
  getOrderById(orderId) {
    // return axios.get(API_URL + 'order', {
    //   params: {
    //     customerid: customerId,
    //     orderid: orderId,
    //   },
    // });
    return axios.get(API_URL + 'order/' + orderId);
  }
  getOrderDetail(orderId) {
    return axios.get(API_URL + 'order/' + orderId);
  }
  addOrder(data) {
    return axios.post(API_URL + 'order/addorder', data, {
      'Content-Type': 'application/json',
    });
  }
  updateOrder(orderId, data) {
    return axios.put(API_URL + 'order/' + orderId, data);
  }
  checkoutOrder(orderId) {
    return axios.post(CART_API_URL + 'order/checkout/' + orderId);
  }
  getOrdersByVendorId(id) {
    return axios.get(API_URL + 'vendor/order/' + id);
  }
}

export default new OrderService();
