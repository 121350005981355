<template>
  <footer class="footer ">
    <div class="container">
      <div class="footer-top">
        <div class="row">
          <div class="col-lg-2 col-sm-6">
            <div class="widget">
              <h3 class="widget-title">Customer Services</h3>
              <ul class="widget-body">
                <li>
                  <router-link tag="a" to="/help-center"
                    >Help Center</router-link
                  >
                </li>
                <li><a href="#">Contact us</a></li>
                <li><a href="#">Report Abuse</a></li>
                <li><a href="#">Submit a Dispute</a></li>
                <li>
                  <router-link tag="a" to="/policy-rules"
                    >Policies & Rules</router-link
                  >
                </li>
                <li><a href="#">Bet Paid for your Feedback</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6">
            <div class="widget">
              <h3 class="widget-title">About us</h3>
              <ul class="widget-body">
                <li>
                  <router-link tag="a" to="/about-wmt">About WMT</router-link>
                </li>
                <li><a href="#">About WMT Team</a></li>
                <li><a href="#">Sitemap</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6">
            <div class="widget">
              <h3 class="widget-title">Source on WMT</h3>
              <ul class="widget-body">
                <li><a href="#">Resource</a></li>
                <li><a href="#">All Categories</a></li>
                <li><a href="#">Request for Quotation</a></li>
                <li><a href="#">Ready to Ship</a></li>
                <li><a href="#">Buyer Partners</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6">
            <div class="widget">
              <h4 class="widget-title">Sell on WMT</h4>
              <ul class="widget-body">
                <li><a href="#">Supplier Memberships</a></li>
                <li><a href="#">Learning Center</a></li>
                <li><a href="#">Partner Program</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6">
            <div class="widget">
              <h4 class="widget-title">Trade Services</h4>
              <ul class="widget-body">
                <li><a href="#">Trade Assurance</a></li>
                <li><a href="#">Business Identity</a></li>
                <li>
                  <a href="#">Logistics Monitoring & Inspection Services</a>
                </li>
                <li><a href="#">Letter of Credit</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-bottom />
  </footer>
  <!-- End of Footer -->
</template>
<script>
import FooterBottom from './FooterBottom.vue';
export default {
  components: { FooterBottom },
};
</script>
