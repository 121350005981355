<template>
  <main class="main">
    <div class="container">
      <home-banner />
      <shop-promotion />
      <feature-brands />

      <div v-for="ct in categories" :key="ct">
        <div v-if="ct.is_direct_home_menu" class="category-heading">
          <h3>{{ ct.name }}
            <span>
              <router-link :to="'/category/' + ct.slug" target="_blank">View More</router-link>
            </span>    
          </h3>
          <div class="row">
            <template v-for="pt in productss" :key="pt._id" >
            <div class="col-6 col-md-2 category-product mb-4" v-if="ct._id == pt.category">
              <router-link :to="`/product/${pt.slug}`" target="_blank">
                <div class="product bg-white">
                  <figure class="product-media">
                    <div class="product-label-group">
                      <label
                        class="product-label label-new"
                        :style="
                          pt.quantity == 0 ? 'background:rgb(168, 50, 70);' : ''
                        "
                        >{{ pt.quantity == 0 ? "Out of Stock" : "New" }}</label
                      >
                    </div>
                    <div>
                      <img
                        :src="imgSrc(pt)"
                        alt="Product"
                        width="300"
                        height="338"
                      />
                    </div>
                  </figure>
                  <div class="product-details">
                    <h4 class="product-name">
                      <span>{{ pt.name }}</span>
                    </h4>
                    <!-- <span>{{ seller.short_description }}</span> -->
                    <div class="ratings-container">
                      <div class="ratings-per">4.3%</div>
                      <div class="rating-reviews">(19 ratings)</div>
                    </div>
                    <div class="product-pa-wrapper">
                      <!-- <div class="product-price-old">
                              MRP <del>1899</del> <span>16% off</span>
                            </div> -->
                      <div class="product-price">
                        <span>&#8377; {{ getPrice(pt) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div> 
            </template>                        
          </div>
        </div>
      </div>

      <!--<product-deals v-for="title in titles" :key="title" :title="title" style="display:none;"/>-->
    </div>
  </main>
</template>

<script>
//import Spinner from '@/components/Spinner.vue';
import ShopPromotion from '../promotions/ShopPromotion.vue';
import HomeBanner from './HomeBanner.vue';
import FeatureBrands from '../brands/FeatureBrands.vue';
import UserService from '@/services/user.service.js';
import { createNamespacedHelpers } from 'vuex';
//import ProductDeals from '../products/ProductDeals.vue';

const { mapGetters } = createNamespacedHelpers('product');
const authMapGetters = createNamespacedHelpers('auth').mapGetters;
const categoryMapGetters = createNamespacedHelpers('category').mapGetters;
export default {
  components: {
    ShopPromotion,
    FeatureBrands,
    HomeBanner,
    //ProductList,
    //ProductDeals,
    //Spinner,
  },
  data() {
    return {
      titles: ['Popular Combo Deals', 'Doctors Corner'],
      products: '',
      productss:[],
      loading: true,
      flashdeal: {
        title: 'flash Deal',
        viewmore: '#',
        productrs: [
          {
            id: '1',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '2',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '3',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '4',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '5',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
        ],
      },
      papulardeal: {
        title: 'Papular combo deal',
        viewmore: '#',
        productrs: [
          {
            id: '1',
            imageurl: 'images/product/1.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '2',
            imageurl: 'images/product/2.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '3',
            imageurl: 'images/product/3.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '4',
            imageurl: 'images/product/4.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '5',
            imageurl: 'images/product/6.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
        ],
      },
      newdeal: {
        title: 'Explore Some thing new',
        viewmore: '#',
        productrs: [
          {
            id: '1',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '2',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '3',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '4',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '5',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
        ],
      },
      doctordeal: {
        title: "Doctor's corner",
        viewmore: '#',
        productrs: [
          {
            id: '1',
            imageurl: 'images/product/1.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '2',
            imageurl: 'images/product/2.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '3',
            imageurl: 'images/product/3.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '4',
            imageurl: 'images/product/4.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '5',
            imageurl: 'images/product/5.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
        ],
      },
      healthcaredeal: {
        title: 'healthcare devices',
        viewmore: '#',
        productrs: [
          {
            id: '1',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '2',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '3',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '4',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '5',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
        ],
      },
      summerdeal: {
        title: 'summer essentials',
        viewmore: '#',
        productrs: [
          {
            id: '1',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '2',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '3',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '4',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
          {
            id: '5',
            imageurl: '../../assets/images/ONGold.jpg',
            sale: 'sale',
            title: 'Optanium Nutrations (on) Gold Standereds ',
            subtitle: 'packet of lb powder lb',
            ratingpercentage: '4.3',
            ratingcount: '19',
            crossprice: '1800',
            priceoff: '15',
            finalprice: '1500',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['productSearchKey']),
    ...authMapGetters(['userInfo', 'isLogin']),
    ...categoryMapGetters(['categories']),
  },
  mounted() {
    this.getProducts();
    this.getPP();
  },

  methods: {
    imgSrc(product) {
      let img = product.Seller_Specific_Detail[0]?.imgurl?.filter(x=>x);
      if(img && img?.length > 0) return img[0];
      return '/no-image.jpg';
    },
    openProductDetails() {
      this.$router.push("/product/" + this.product._id);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getPrice(data) {
      if (data.Seller_Specific_Detail) {
        if (data.Seller_Specific_Detail.length > 0) {
          return data.Seller_Specific_Detail[0].default_price;
        }
      }
      return "0";
    },
    getProducts() {
      UserService.getProducts().then(
        (response) => {
          this.products = response.data;
          this.loading = false;
          this.fetchCartItems();
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getPP() {
      UserService.getProducts().then((res) => {
          this.productss = res.data.DATA; 
          this.loading = false;         
        })
        .catch((err) => console.log(err));
    },
    openCategories(ct) {
      if (ct === 'All Categories') this.$router.push('/allcategory');
      else this.$router.push('/category/' + ct.slug);
    },
    fetchCartItems() {
      // if (this.isLogin) {
      //   this.$store.dispatch('cart/loadCartItems', {
      //     customerId: this.userInfo.id,
      //     shopping_cart_type_id: 1,
      //   });
      // }
    },
  },
};
</script>
