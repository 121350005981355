<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <!-- Category Request -->
                    <div class="col-lg-5">
                      <div class="document-field">
                        <p>New Category Request:</p>
                        <div class="row mt-4">
                          <div class="col-md-12">
                            <div class="form-group">
                              <input
                                name=""
                                class="form-control form-control-md"
                                placeholder="Category Name"
                                required
                                v-model="categoryName"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Description</label>
                              <textarea
                                name=""
                                id="editor-1"
                                v-model="categoryDesc"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group mt-3">
                              <button
                                type="button"
                                class="form-control form-control-md"
                                @click="addCategoryRequest"
                              >
                                SUBMIT
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Category Request end -->

                    <div class="col-lg-7">
                      <div class="document-field">
                        <p>New Category Request List:</p>
                        <table
                          cellpadding="0"
                          cellspacing=""
                          class="document-list"
                        >
                          <tr>
                            <th width="5%">#</th>
                            <th width="15%">Category Name</th>
                            <th>Description</th>
                            <th>Suggestion/Rejected Reason</th>
                            <th width="10%">Status</th>
                            <th width="10%">Action</th>
                          </tr>

                          <tr
                            v-for="(ct, index) in categoryRequests"
                            :key="ct.name"
                          >
                            <td>{{ index + 1 }}.</td>
                            <td>{{ ct.category_name }}</td>
                            <td>{{ ct.category_description }}</td>
                            <td>{{ ct.suggestion }}</td>
                            <td style="text-transform: capitalize;">
                              {{ ct.is_approved ? 'Approved' : ct.status }}
                            </td>
                            <td>
                              <button
                                type=""
                                class="action-button blue-btn"
                                @click="
                                  editRequest(
                                    ct._id,
                                    ct.category_name,
                                    ct.category_description
                                  )
                                "
                              >
                                <i
                                  class="fa fa-edit"
                                  title="
                                    Edit
                                  "
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>

<script>
import CategoryService from '../../services/category-service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '../Spinner.vue';
import { success, warning } from '../../util/ToastUtil';

const { mapGetters } = createNamespacedHelpers('auth');

export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      categoryName: '',
      categoryDesc: '',
      categoryRequests: [],
      requestId: '',
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getAllCategoryRequest();
  },
  methods: {
    async getAllCategoryRequest() {
      if (!this.userInfo.id) return;
      try {
        this.loading = true;
        const res = await CategoryService.getCategoryRequestsByVendorId(
          this.userInfo.id
        );
        this.categoryRequests = res.data.DATA;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    validation() {
      if (this.categoryName === '' || this.categoryDesc === '') {
        this.$wkToast('Please fill all the fields', warning);
        return false;
      }
      return true;
    },
    async addCategoryRequest() {
      if (!this.validation()) {
        return;
      }
      this.loading = true;

      try {
        const payload = {
          vendor_id: this.userInfo.id,
          category_name: this.categoryName,
          category_description: this.categoryDesc,
          is_approved: false,
          active: true,
          deleted: false,
        };
        const res = this.requestId
          ? await CategoryService.updateCategoryRequest(this.requestId, payload)
          : await CategoryService.createCategoryRequest(payload);
        const created = this.requestId ? 'Updated' : 'Created';
        if (res.status == 200) {
          this.$wkToast(`Request ${created} Successfully`, success);
          this.categoryName = '';
          this.categoryDesc = '';
          this.requestId = '';
          await this.getAllCategoryRequest();
        } else {
          this.$wkToast('Request Failed', warning);
        }
      } catch (err) {
        console.log(err);
        this.$wkToast('Request Failed', warning);
      }
      this.loading = false;
    },
    editRequest(id, name, description) {
      this.requestId = id;
      this.categoryName = name;
      this.categoryDesc = description;
    },
  },
};
</script>

<style></style>
