<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="document-field">
                        <p>Manage Category:</p>

                        <div class="row mt-4">
                          <div class="col-md-3"></div>
                          <div class="col-md-5">
                            <div class="form-group">
                              <input
                                name=""
                                class="form-control form-control-md"
                                placeholder="Category Name"
                                v-model="categoryName"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div class="form-group">
                              <button
                                type="button"
                                class="form-control form-control-md"
                                @click="searchCategory(categoryName)"
                              >
                                SEARCH
                              </button>
                            </div>
                          </div>
                        </div>

                        <table cellpadding="0" cellspacing="" class="manage-category-list admin-cat">
                          <tr v-for="ct in filteredCategories" :key="ct._id" >
                            <td>{{ ct.ParentsMap }}</td>
                            <td width="2%">
                              <button type="" class="action-button blue-btn" @click="updateCategory(ct._id, ct.name)">
                                <i class="fa fa-edit" title="Edit"></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <!-- <div class="document-field" v-if="false">
                        <p>All Categories:</p>
                        <table
                          cellpadding="0"
                          cellspacing=""
                          class="seller-list"
                        >
                          <tr>
                            <th>#</th>
                            <th width="10%">Image</th>
                            <th>Name</th>
                            <th>Required Fields</th>
                            <th>Required Documents</th>
                          </tr>

                          <tr
                            v-for="(category, index) in categories"
                            :key="category._id"
                          >
                            <td>{{ index + 1 }}.</td>
                            <td>
                              <img
                                :src="
                                  category.imgurl
                                    ? category.imgurl[0]
                                    : '/images/covishield.jpg'
                                "
                                class="w-100"
                              />
                            </td>
                            <td>
                              <a @click="updateCategory(category._id)">
                                {{ category.name }}
                              </a>
                            </td>
                            <td>
                              <h6
                                v-for="(field,
                                i) in category.mandatory_for_product"
                                :key="i"
                              >
                                [{{ field.required_field_name }},{{
                                  field.required_field_type
                                }}]
                              </h6>
                            </td>
                            <td>
                              <h6
                                v-for="(field,
                                i) in category.mandatory_for_seller"
                                :key="i"
                              >
                                [{{ field.required_field_name }},{{
                                  field.required_field_type
                                }}]
                              </h6>
                            </td>
                          </tr>
                        </table>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
<script>
import CategoryService from '../../services/category-service';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('category');

export default {
  data() {
    return {
      loading: false,
      categoryName: '',
      mappingCategory: '',
      categoryId: '',
      filteredCategories: [],
    };
  },
  computed: {
    ...mapGetters(['categories', 'categoryAllChildren']),
  },
  mounted() {
    this.getAllCategory();
  },
  methods: {
    async getAllCategory() {
      this.loading = true;
      try {
        let res = await CategoryService.getAllCategory();
        let data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch('category/addCategories', data);
        }
        res = await CategoryService.getCategoryAllChildren();
        data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch('category/addCategoryAllChildren', data);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    searchCategory(searchKey) {
      this.categoryModel = '';
      if (!searchKey || searchKey === '') {
        return;
      }
      this.filteredCategories = this.categoryAllChildren.filter((ct) =>
        ct.name.toLowerCase().includes(searchKey.toLowerCase())
      );
      console.log(this.filteredCategories);
    },

    updateCategory(id, name) {
      this.$router.push({
        name: 'Add Category',
        params: {
          catId: id,
          parentCategoryId: this.getParentIdOfCategory(id),
        },
      });
    },
    getParentIdOfCategory(id) {
      const cat = this.categories.find((ct) => ct._id === id);
      return cat?.parent_category_id ? cat?.parent_category_id : '';
    },
  },
};
</script>
