<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="tab tab-vertical tab-nav-solid">
                  <ul class="" role="tablist" style="list-style-type:none;">
                    <li
                      class="nav-item"
                      v-for="(link, index) in nav_links"
                      :key="index"
                    >
                      <a
                        class="nav-link"
                        @click="activate(index + 1)"
                        :class="active_el == index + 1 ? 'active' : ''"
                        ><i :class="link.icon"></i> {{ link.name }}</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content">
                    <seller-account v-if="active_el === 1" />
                    <company-overview v-if="active_el === 2" />
                    <trade-capabilities v-else-if="active_el === 3" />
                    <production-capacity v-else-if="active_el === 4" />
                    <quality-control v-else-if="active_el === 5" />
                    <rnd-capacity v-else-if="active_el === 6" />
                    <factory-inspection-reports v-else-if="active_el === 7" />
                    <seller-documents v-else-if="active_el === 8" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>

  <!-- End of Main -->
</template>

<script>
import CompanyOverview from '@/components/seller/profile/CompanyOverview.vue';
import FactoryInspectionReports from '@/components/seller/profile/FactoryInspectionReports.vue';
import ProductionCapacity from '@/components/seller/profile/ProductionCapacity.vue';
import QualityControl from '@/components/seller/profile/QualityControl.vue';
import RndCapacity from '@/components/seller/profile/RndCapacity.vue';
import SellerAccount from '@/components/seller/profile/SellerAccount.vue';
import SellerDocuments from '@/components/seller/profile/SellerDocuments.vue';
import TradeCapabilities from '@/components/seller/profile/TradeCapabilities.vue';

export default {
  components: {
    CompanyOverview,
    TradeCapabilities,
    ProductionCapacity,
    QualityControl,
    RndCapacity,
    FactoryInspectionReports,
    SellerAccount,
    SellerDocuments,
  },
  data() {
    return {
      active_el: 1,
      nav_links: [
        { name: 'Account', icon: 'w-icon-account' },
        { name: 'Company Overview', icon: 'w-icon-company' },
        { name: 'Trade Capabilities', icon: 'w-icon-ruby' },
        { name: 'Production Capaity', icon: 'w-icon-building' },
        { name: 'Quality Control', icon: 'w-icon-check' },
        { name: 'R&D Capacity', icon: 'w-icon-light' },
        { name: 'Factory Inspection Reports', icon: 'w-icon-reports' },
        { name: 'Seller Documents', icon: 'w-icon-reports' },
      ],
    };
  },
  methods: {
    activate(value) {
      this.active_el = value;
    },
  },
};
</script>

<style scoped>
.w-icon-building::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 300;
  content: '\f1ad';
}
.w-icon-check::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 300;
  content: '\f058';
}
.w-icon-light::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 300;
  content: '\f0eb';
}

.w-icon-company::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 300;
  content: '\f1ad';
}
</style>
