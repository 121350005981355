<template>
  <div class="tab-content">
    <div class="tab-pane active" id="product-tab-description">
      <div class="row">
        <div class="col-md-12">
          <table cellpadding="0" cellspacing="0" class="product-detail-tab">
            <col><col>
            <tr v-for="info in Object.keys(detail)" :key="info">
              <td
                >{{
                  info
                    .split('_')
                    .reduce(
                      (s, str) => s.toUpperCase() + str.toUpperCase() + ' ',
                      ''
                    )
                }}</td
              >
              <td
                v-if="
                  info === 'product_specification_detail' ||
                    info === 'short_description' ||
                    info === 'full_description'
                "
                v-html="seller[info]"
              >
              </td>
              <td v-else-if="info === 'seller_geo_location'"
                >{{ seller[info].latitude }},{{ seller[info].longitude }}
              </td>
              <td v-else>{{ seller[info] }}</td>
            </tr>
            <template v-if="product?.mandatoryFields?.length > 0">
              <tr v-for="(p, index) in product.mandatoryFields" :key="index">
                <td>{{ p.field.split('_').reduce((s, str) => s.toUpperCase() + str.toUpperCase() + ' ','')}}:</td>
                <td>{{ p.value }} </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product', 'index'],
  data() {
    return {
      detail: {
        short_description: '',

        full_description: '',

        brand_name: '',

        manufacturer: '',

        manufacturer_part_name: '',

        ppu_count: '',

        ppu_count_unit_measure: '',

        product_expirable: '',

        sku: '',

        origin_country: '',

        item_condition: '',

        hsn_code: '',

        max_retail_price: '',

        fullfillment_channel: '',

        seller_geo_location: '',

        product_specification_detail: '',
      },
    };
  },
  computed: {
    seller() {
      return this.product['Seller_Specific_Detail'][this.index];
    },
  },
};
</script>

<style></style>
