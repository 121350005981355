<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('auth');
export default {
  computed: {
    ...mapGetters(['isLogin']),
  },
  created() {
    if (this.isLogin) {
      this.$router.replace('/productcatalog');
    } else {
      this.$router.replace('/seller/login');
    }
  },
};
</script>

<style></style>
