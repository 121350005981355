<template>
  <main class="main">
    <div class="container-small">
      <div class="cart">
        <div class="row">
          <div class="col-md-5 mb-4">
            <div class="attached-prescription mt-2" style="padding-bottom:0;">
              <h5 class="mb-1">Upload Prescription</h5>
              <p class="font-13 mb-1">
                Please attach a Prescription to proceed
              </p>
              <div class="row">
                <div class="col-md-12 mb-4">
                  <div class="fileUpload-1">
                    <span><img src="/images/up.png" /> UPLOAD NEW</span>
                    <input
                      type="file"
                      class="upload"
                      ref="file"
                      @change="onFileSelect"
                    />
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <a href="" class="saved-prescription-link">
                    <div class="saved-prescription">
                      <img src="/images/sp.png" />
                      <span>SAVED PRESCRIPTION</span>
                    </div>
                  </a>
                </div>

                <div class="col-md-12 mb-1">
                  <h5>Attached Prescriptions</h5>
                </div>
                <div
                  class="col-6 col-md-4 mb-4"
                  v-for="(url, index) in urls"
                  :key="url"
                >
                  <button
                    type="submit"
                    class="prescription-upload"
                    @click="deleteFiles(index)"
                  >
                    <i class="fa fa-window-close"></i>
                  </button>
                  <img :src="url" class="prescription-img" />
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn-2-continue mt-4 mb-10"
              @click="continuePrescription"
            >
              CONTINUE
            </button>
          </div>
          <div class="col-md-7 mb-4">
            <div class="attached-prescription mt-2">
              <h5>Guide for a valid Prescription</h5>
              <img src="/images/prescription.png" />
            </div>
            <p class="font-13">
              Government regulations require a valid prescription
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      urls: [],
    };
  },
  methods: {
    onFileSelect(e) {
      debugger;
      const file = e.target.files[0];
      this.urls.push(URL.createObjectURL(file));
    },
    deleteFiles(index) {
      this.urls.splice(index, 1);
    },
    continuePrescription() {
      if (!this.urls.length) alert('please select prescription');
      else this.$router.push('/cart/address');
    },
  },
};
</script>

<style></style>
