<template>
  <div class="tab-pane active in" id="tab4-3">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="rndCapacity">
      <div class="row" v-for="key in Object.keys(rndCapacity)" :key="key">
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>

        <div class="row" v-for="value in rndCapacity[key]" :key="value.name">
          <div class="pt-4 pb-4">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-15">Not Available</div>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  props: ['vendorId'],
  data() {
    return {
      showEdit: false,
      loading: false,
      rndCapacityId: '',
      rnd: {
        ProductionCertification: [{ name: 'Picture', des: 'url' }],
        Certification: [
          { name: 'Certification Name', des: 'ISO9001' },
          {
            name: 'Issued By',
            des: 'Quality Assurance Centre of China Association',
          },
        ],
        AwardsCertification: [
          { name: 'Name', des: 'Verified Supplier Certificate' },
          { name: 'Issued By', des: 'TUV' },
        ],
      },
    };
  },
  computed: {
    rndCapacity() {
      if (this.rndCapacityBySellerId) {
        const { _id, customerid, ...co } = this.rndCapacityBySellerId;
        this.setRndCapacityId(_id);
        return co;
      }

      return null;
    },
    rndCapacityBySellerId() {
      if (this.rndCapacities) {
        return this.rndCapacities[this.vendorId];
      }
      return null;
    },
    ...profileMapGetters(['rndCapacities']),
  },
  mounted() {
    if (this.rndCapacityId == '') {
      this.getRndCapacity();
    }
  },
  methods: {
    setRndCapacityId(id) {
      if (id) this.rndCapacityId = id;
    },
    getRndCapacity() {
      this.loading = true;
      ProfileService.getRndCapacity(this.vendorId)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addRndCapacity', cover);
          } else {
            co = null; //this.rnd;
            co.customerid = this.vendorId;
            this.$store.dispatch('companyProfile/addRndCapacity', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
