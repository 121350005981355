<template>
  <div class="input-box">
    <button type="button" class="image-delete" @click="$emit('close', index)">
      <i class="fa fa-times"></i>
    </button>
    <div class="form-control">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'index'],
};
</script>

<style></style>
