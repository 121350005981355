<template>
  <div class="tab-pane active in" id="tab4-3">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="!self || (!showEdit && qualityControlId)">
      <div
        class="row"
        v-for="(key, index) in Object.keys(qualityControl)"
        :key="key"
      >
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>
        <div
          class="col-md-6 d-flex justify-content-end align-items-center"
          @click="showEdit = true"
          v-if="self && index == 0"
        >
          <i class="fas fa-edit "></i>
        </div>
        <div class="row" v-for="value in qualityControl[key]" :key="value.name">
          <div class="pt-4 pb-4">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>

    <form v-else class="form account-details-form" @submit.prevent="save">
      <div v-for="key in Object.keys(qualityControl)" :key="key">
        <h4 class="title title-password ls-25 font-weight-bold">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>
        <div class="row">
          <div
            class="col-md-6"
            v-for="value in qualityControl[key]"
            :key="value.name"
          >
            <div class="form-group">
              <label for=""
                ><h5>
                  {{
                    value.name
                      .split(/(?=[A-Z])/)
                      .reduce((s, str) => s + str + ' ', '')
                  }}
                  *
                </h5></label
              >
              <div v-if="value.name === 'Verified'">
                <input
                  list="verified"
                  name="verified"
                  class="form-control form-control-md"
                  v-model="value.des"
                  required
                />
                <datalist id="verified">
                  <option v-for="v in verifyList" :key="v" :value="v" />
                </datalist>
              </div>
              <input
                v-else
                :type="value.name === 'Quantity' ? 'number' : 'text'"
                required
                class="form-control form-control-md"
                maxlength="150"
                pattern="[a-zA-Z0-9-._\s]+"
                title="text and number only"
                v-model="value.des"
              />
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-dark btn-rounded btn-sm mb-4">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const { mapGetters } = createNamespacedHelpers('auth');
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  data() {
    return {
      showEdit: false,
      loading: false,
      qualityControlId: '',
      sellerId: this.$route.params.id,
      quality: {
        TestEquipment: [
          {
            name: 'Machine Name',
            des: ' Gas Chromatograph',
          },
          {
            name: 'Brand & Model NO',
            des: 'Confidential',
          },
          {
            name: 'Quantity',
            des: '2',
          },
          {
            name: 'Verified',
            des: 'yes',
          },
        ],
      },
      verifyList: ['Yes', 'No', 'Confidential'],
    };
  },
  computed: {
    self() {
      return this.userInfo.id === this.sellerId;
    },
    qualityControl() {
      if (this.qualityControlBySellerId) {
        const { _id, customerid, ...co } = this.qualityControlBySellerId;
        this.setQualityControlId(_id);
        return co;
      }

      return {};
    },
    qualityControlBySellerId() {
      if (this.qualityControls) {
        return this.qualityControls[this.sellerId || this.userInfo.id];
      }
      return null;
    },
    ...mapGetters(['userInfo']),
    ...profileMapGetters(['qualityControls']),
  },
  mounted() {
    if (this.qualityControlId == '') {
      this.getQualityControl();
    }
  },
  methods: {
    setQualityControlId(id) {
      if (id) this.qualityControlId = id;
    },
    getQualityControl() {
      this.loading = true;
      ProfileService.getQualityControl(this.sellerId || this.userInfo.id)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addQualityControl', cover);
          } else {
            co = this.quality;
            co.customerid = this.sellerId || this.userInfo.id;
            this.$store.dispatch('companyProfile/addQualityControl', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async save() {
      const payload = {
        ...this.qualityControl,
        customerid: this.userInfo.id,
      };
      try {
        this.loading = true;
        if (!this.qualityControlId || this.qualityControlId == '') {
          console.log('create quality');
          await ProfileService.createQualityControl(payload);
        } else {
          await ProfileService.updateQualityControl(
            payload,
            this.qualityControlId
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.showEdit = false;
      this.getQualityControl();
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
