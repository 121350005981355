export default {
  namespaced: true,
  state() {
    return {
      documents: [],
    };
  },
  getters: {
    documents(state) {
      return state.documents;
    },
  },
  mutations: {
    addDocuments(state, data) {
      state.documents = data;
    },
  },
  actions: {
    addDocuments({ commit }, data) {
      commit('addDocuments', data);
    },
  },
};
