<template>
  <nav class="breadcrumb-nav container">
    <ul class="breadcrumb bb-no">
      <li>
        <router-link to="/">
          Home
        </router-link>
      </li>

      <li>Products</li>
    </ul>
  </nav>
</template>

<script>
export default {};
</script>

<style></style>
