export const alphaNumericWithSpace = /^[a-zA-Z\d-._\s]+$/;
export const alphaNumeric = /^[a-zA-Z\d]+$/;
export const oninput =
  "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(..*)./g, '$1');";

export const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
export const regMobile = /^\d{10}$/;

export const emailPattern =
  '[A-Za-z]{1,}[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})';

export const passwordField = {
  maxlength: '32',
  pattern: '.{8,}',
  title: 'Eight or more characters',
};
export const mobileField = {
  maxlength: '10',
  pattern: '[7-9]{1}[0-9]{9}',
  oninput: "this.value = this.value.replace(/[^0-9]/g, '');",
  title: 'Phone number with 7-9 and remaing 9 digit with 0-9',
};

export const quantityField = {
  min: '1',
  max: '1000000',
  maxlength: '7',
  oninput: 'maxLengthInput',
};
