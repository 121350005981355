<template>
  <div class="tab-pane active in" id="tab4-3">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="qualityControl">
      <div class="row" v-for="key in Object.keys(qualityControl)" :key="key">
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>

        <div class="row" v-for="value in qualityControl[key]" :key="value.name">
          <div class="pt-4 pb-4">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-15">Not Available</div>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  props: ['vendorId'],
  data() {
    return {
      loading: false,
      qualityControlId: '',
      quality: {
        TestEquipment: [
          {
            name: 'Machine Name',
            des: ' Gas Chromatograph',
          },
          {
            name: 'Brand & Model NO',
            des: 'Confidential',
          },
          {
            name: 'Quantity',
            des: '2',
          },
          {
            name: 'Verified',
            des: 'yes',
          },
        ],
      },
    };
  },
  computed: {
    qualityControl() {
      if (this.qualityControlBySellerId) {
        const { _id, customerid, ...co } = this.qualityControlBySellerId;
        this.setQualityControlId(_id);
        return co;
      }

      return null;
    },
    qualityControlBySellerId() {
      if (this.qualityControls) {
        return this.qualityControls[this.vendorId];
      }
      return null;
    },
    ...profileMapGetters(['qualityControls']),
  },
  mounted() {
    if (this.qualityControlId == '') {
      this.getQualityControl();
    }
  },
  methods: {
    setQualityControlId(id) {
      if (id) this.qualityControlId = id;
    },
    getQualityControl() {
      this.loading = true;
      ProfileService.getQualityControl(this.vendorId)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addQualityControl', cover);
          } else {
            co = null; //this.quality;
            co.customerid = this.vendorId;
            this.$store.dispatch('companyProfile/addQualityControl', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
