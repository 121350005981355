import { cartType } from '@/typesEnum/cartType';
import CartService from '../../services/cart-service';
export default {
  namespaced: true,
  state: {
    isLoadingCartItems: false,
    isCartEmpty: false,
    isWishListEmpty: false,
    paymentOptions: { COD: 'Cash On Delivery' },
    cartItems: [],
    sessionCartItems: [],
    wishlistItems: [],
    // itemCount: 0,
  },
  getters: {
    cartItems(state) {
      return state.cartItems;
    },
    wishlistItems(state) {
      return state.wishlistItems;
    },
    sessionCartItems(state) {
      return state.sessionCartItems;
    },

    totalPrice(state) {
      if (state.cartItems.length) {
        return parseInt(
          state.cartItems?.reduce(
            (total, item) => total + item.product_price * item.quantity,
            0
          )
        ).toFixed(2);
      }
      return 0;
    },

    isLoadingCartItems(state) {
      return state.isLoadingCartItems;
    },
    isCartEmpty(state) {
      return state.isCartEmpty || state.cartItems.length == 0;
    },
    isWishListEmpty(state) {
      return state.isWishListEmpty || state.wishlistItems.length == 0;
    },
    itemCount(state) {
      let itemCount = 0;
      if (state.cartItems.length != 0) {
        state.cartItems.map((item) => (itemCount += parseInt(item.quantity)));
      }

      if (state.cartItems.length == 0 && state.sessionCartItems.length != 0) {
        state.sessionCartItems.map((el) => {
          if (el.shopping_cart_type_id == 1) {
            itemCount += parseInt(el.quantity);
          }
        });
      }
      return itemCount;
    },
    paymentOptions(state) {
      return state.paymentOptions;
    },
  },
  mutations: {
    resetAll(state) {
      state.isLoadingCartItems = false;
      state.isCartEmpty = false;
      state.paymentOptions = { COD: 'cash On Deliver' };
      state.cartItems = [];
      // state.itemCount = 0;
      state.sessionCartItems = [];
      state.wishlistItems = [];
    },
    resetCart(state) {
      state.cartItems = [];
      // state.itemCount = 0;
    },
    resetSessionCart(state) {
      state.sessionCartItems = [];
      // state.itemCount = 0;
    },
    isLoadingCartItems(state, value) {
      state.isLoadingCartItems = value;
    },
    isCartEmpty(state, value) {
      state.isCartEmpty = value;
    },
    addItemToCart(state, product) {
      state.cartItems.push(product);
    },
    SET_CART_ITEMS(state, products) {
      state.cartItems = products;
      // state.itemCount = state.cartItems.reduce(
      //   (total, item) => parseInt(total) + parseInt(item.quantity),
      //   0
      // );
    },
    UPDATE_QUANTITY(state, { id, quantity }) {
      state.cartItems.map((item) => {
        if (item.id == id) {
          item.quantity = quantity;
        }
      });
    },
    DELETE_ITEMS(state, id) {
      state.cartItems = state.cartItems.filter((item) => item.id != id);
    },
    addPaymentOptions(state, paymentOptions) {
      state.paymentOptions = paymentOptions;
    },
    addWishListItems(state, items) {
      state.wishlistItems = items;
    },
    addItemToWishList(state, item) {
      state.wishlistItems.push(item);
    },
    // updateItemCount(state, count) {
    //   count == 0 ? (state.itemCount = count) : (state.itemCount += count);
    //   console.log(state.itemCount);
    // },

    addSessionCartItem(state, product) {
      if (state.sessionCartItems.length == 0) {
        state.sessionCartItems.push(product);
      } else {
        let isInclude = false;
        state.sessionCartItems.map((item) => {
          if (
            item.product_id == product.product_id &&
            item.shopping_cart_type_id == product.shopping_cart_type_id
          ) {
            item.quantity += product.quantity;
            isInclude = true;
            return;
          }
        });
        if (!isInclude) {
          state.sessionCartItems.push(product);
        }
      }
    },
  },
  actions: {
    addItemsToCart({ commit }, products) {
      commit('SET_CART_ITEMS', products);
    },
    async loadCartItems(
      { commit, rootState, getters, dispatch },
      { customerId, shopping_cart_type_id }
    ) {
      if (rootState.auth.isLogin) {
        try {
          commit('isLoadingCartItems', true);
          commit('isCartEmpty', true);
          let response = await CartService.getCartItems(
            customerId,
            shopping_cart_type_id
          );
          let cartItems = response.data.DATA;
          response = await CartService.getWishListItems(customerId, 2);
          let wishlistItems = response.data.DATA;
          commit('SET_CART_ITEMS', cartItems);
          commit('addWishListItems', wishlistItems);
          if (cartItems.length == 0 && getters.sessionCartItems.length != 0) {
            getters.sessionCartItems.map((item) => {
              CartService.addCart({
                product_id: item.product_id,
                customer_id: rootState.auth.userInfo.id,
                quantity: item.quantity,
                shopping_cart_type_id: item.shopping_cart_type_id,
              });
            });
          }
          dispatch('resetSessionCart');
        } catch (err) {
          console.log(err);
        }
        commit('isLoadingCartItems', false);
        commit('isCartEmpty', false);
      } else if (getters.sessionCartItems.length != 0) {
        const cItem = getters.sessionCartItems.filter(
          (item) => item.shopping_cart_type_id == 1
        );
        const wItem = getters.sessionCartItems.filter(
          (item) => item.shopping_cart_type_id == 2
        );
        commit('SET_CART_ITEMS', cItem);
        commit('addWishListItems', wItem);
      }
    },

    async deleteItem({ commit, dispatch, rootState, state }, id) {
      if (rootState.auth.isLogin) {
        try {
          await CartService.deleteItem(id);

          // alert(res.data.MESSAGE);
        } catch (err) {
          console.log(err);
        }
        commit('DELETE_ITEMS', id);
        dispatch('loadCartItems', {
          customerId: rootState.auth.userInfo.id,
          shopping_cart_type_id: 1,
        });
      } else {
        state.sessionCartItems = state.sessionCartItems.filter(
          (item) => item.id != id
        );
        if (state.sessionCartItems == 0) {
          commit('SET_CART_ITEMS', []);
          commit('addWishListItems', []);
        }
        dispatch('loadCartItems', {});
      }
    },
    async saveItem({ commit, rootState, state, dispatch }, item) {
      if (rootState.auth.isLogin) {
        try {
          await CartService.saveItem(
            item,
            rootState.auth.userInfo.id,
            cartType.WISH_LIST
          );
          // alert(res.data.MESSAGE);
        } catch (err) {
          console.log(err);
        }
      } else {
        state.sessionCartItems.map((el) => {
          if (el.id == item.id) {
            el.shopping_cart_type_id = 2;
          }
        });
        dispatch('loadCartItems', {});
      }
    },
    async updateItem(
      { commit, dispatch, rootState, state },
      { id, quantity, shopping_cart_type_id }
    ) {
      if (rootState.auth.isLogin) {
        try {
          const res = await CartService.updateItem(
            id,
            quantity,
            shopping_cart_type_id
          );
          if (res.data.DATA == null) {
            try {
              this.$wkToast(res.data.MESSAGE);
            } catch (err) {
              console.log(err);
            }
          }
        } catch (err) {
          console.log(err);
        }
        commit('UPDATE_QUANTITY', { id, quantity });
        dispatch('loadCartItems', {
          customerId: rootState.auth.userInfo.id,
          shopping_cart_type_id: 1,
        });
      } else {
        state.sessionCartItems.map((el) => {
          if (el.id === id) {
            el.quantity = quantity;
            el.shopping_cart_type_id = shopping_cart_type_id;
          }
        });
        debugger;
        dispatch('loadCartItems', {});
      }
    },
    addPaymentOptions({ commit }, paymentOptions) {
      commit('addPaymentOptions', paymentOptions);
    },
    addWishListItems({ commit }, items) {
      commit('addWishListItems', items);
    },
    updateItemCount({ commit }, count) {
      commit('updateItemCount', count);
    },
    resetCart({ commit }) {
      commit('resetCart');
    },
    resetSessionCart({ commit }) {
      commit('resetSessionCart');
    },
    addSessionCartItem({ commit, dispatch }, product) {
      commit('addSessionCartItem', product);
      dispatch('loadCartItems', {});
    },
    addItemToCart({ commit }, item) {
      commit('addItemToCart', item);
    },
    addItemToWishList({ commit }, item) {
      commit('addItemToWishList', item);
    },
  },
};
