<template>
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart" style="min-height:100vh">
        <div class="row" v-if="product">
          <div class="col-md-3">
            <img :src="product.imgurl[0]" alt="" width="200" height="200" />
          </div>
          <div class="col-md-6 mt-4">
            <h6>{{ product.name }}</h6>
            <p>Priec:{{ sellers[0].default_price }}</p>
            <p>Status: {{ product.is_approved ? 'Approved' : 'Pending' }}</p>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="document-field">
                <p>All Sellers:</p>
                <table cellpadding="0" cellspacing="" class="seller-list">
                  <tr>
                    <th>#</th>
                    <th>Seller Name</th>
                    <!-- <th>Contact No</th>
                    <th>E-mail</th>
                    <th>Address</th>-->
                    <th>About Seller</th>
                    <th>Status</th>
                    <th width="7%">Action</th>
                  </tr>

                  <tr v-for="(seller, index) in sellers" :key="seller._id">
                    <td>{{ index + 1 }}.</td>
                    <td>{{ seller.brand_name }}</td>
                    <!-- <td>{{ seller.phonenumber }}</td>
                    <td>{{ seller.email }}</td>
                    <td>{{ seller.address }}</td>-->
                    <td>
                      <router-link :to="'/profile/' + seller.customer_id"
                        >view</router-link
                      >
                    </td>
                    <td>
                      {{ seller.is_approved ? 'Approved' : 'Pending' }}
                    </td>
                    <td>
                      <button
                        type=""
                        class="action-button "
                        :class="seller.is_approved ? 'red-btn' : 'green-btn'"
                        @click="
                          updateSeller(
                            seller,
                            !seller.is_approved,
                            seller.deleted
                          )
                        "
                      >
                        <i
                          class="fa"
                          :class="seller.is_approved ? 'fa-times' : 'fa-check'"
                          :title="
                            !seller.is_approved ? 'Approve' : 'DisApprove'
                          "
                        ></i>
                      </button>
                      <button
                        class="action-button red-btn"
                        @click="
                          updateSeller(
                            seller,
                            seller.is_approved,
                            !seller.deleted
                          )
                        "
                      >
                        <i class="fa fa-trash" title="Reject"></i>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Spinner from '../../components/Spinner.vue';
import UserService from '../../services/user.service';

export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      product: null,
      sellers: [],
      productId: this.$route.params.id,
    };
  },
  created() {
    if (!this.productId) {
      this.$router.push('/productcatalog');
    }
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      this.loading = true;
      try {
        const res = await UserService.getProductById(this.productId);
        const data = res.data.DATA;
        console.log(data);
        if (data && data.length > 0) {
          this.product = data[0];
          if (this.product['Seller_Specific_Detail']) {
            this.sellers = this.product['Seller_Specific_Detail'];
            if (this.product.imgurl.length == 0) {
              this.product.imgurl = this.sellers[0].imgurl;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.seller-list {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.seller-list td,
.seller-list th {
  border: 1px solid #ddd;
  padding: 8px;
}
.seller-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #77abf0;
  color: white;
}
</style>
