<template>
  <home-page />
</template>

<script>
import HomePage from '@/components/seller/HomePage.vue';
export default {
  components: { HomePage },
};
</script>

<style></style>
