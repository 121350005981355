<template>
  <div @click="openProductDetails">
    <img v-if="product.imgurl" :src="productrs.imgurl[0]" />
    <img v-if="!product.imgurl" src="@/assets/images/dummyimage.jpg" />
    <span class="sale">Sale {{ product.quantity }}</span>
    <h3 class="title">{{ product.name }}</h3>
    <p class="finalprice" v-if="product.default_price">
      {{ product.default_price }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['productrs'],

  computed: {
    product() {
      const pro = { ...this.productrs };
      if (!pro.imgurl.length) {
        pro.imgurl = pro['Seller_Specific_Detail'][0].imgurl;
        pro.default_price = pro['Seller_Specific_Detail'][0].seller_min_price;
      }
      return pro;
    },
  },
  methods: {
    openProductDetails() {
      console.log(this.productrs);
      console.log({ id: this.productrs._id });

      // this.$router.push('/product/' + this.productrs._id);
      this.$router.push({
        path: '/update-product/' + this.product._id,
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
