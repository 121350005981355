<template>
  <div class="product-details" data-sticky-options="{'minWidth': 767}">
    <h2
      class="product-title"
      @click="updateDefaultVariant"
      style="cursor:pointer"
    >
      {{ product.name }}
    </h2>
    <a href="">Visit the {{ productName }} Store</a>

    <div class="mt-1 ratings-container">
      <div class="ratings-full">
        <div class="ratings" style="width: 80%;"></div>
        <div class="tooltiptext tooltip-top"></div>
      </div>
      <a href="#product-tab-reviews" class="rating-reviews scroll-to">{{
        seller.ratings || '(3 Reviews)'
      }}</a>
    </div>

    <p class="product-detail-p">
      <b
        >M.R.P.: &#8377;
        {{ product.Seller_Specific_Detail[index].default_price }}</b
      >
      <button
        id="price-option"
        class="price-option"
        @click="$emit('showPrice')"
        v-if="product['Seller_Specific_Detail'].length > 1"
      >
        Other Price Options</button
      ><button id="bulk-option" class="bulk-option" @click="$emit('showBulk')">
        Bulk Price Options
      </button>
      <br />
      Deal of the Day:
      <b>best deals</b><br />
      Inclusive of all taxes
    </p>
    <!-- moment()
          .add(10, 'days')
          .calendar() -->
    <p class="product-detail-p">
      FREE delivery:
      {{ weekFromToday }}<br />
      Fastest delivery: Tomorrow<br />
      Order within 2 hrs and 1 min
    </p>

    <p class="product-detail-p">
      <b>Bank Offer:</b> Not Available
      <!-- <a href="">Details</a> -->
    </p>

    <p class="product-detail-p">
      <b>No Cost EMI:</b> Avail No Cost EMI on select cards
      <!-- <a href="">Details</a> -->
    </p>
    <p class="product-detail">
      Best Before: 21 Jan 2023s
      <span
        class="in-stock text-left"
        :style="product.quantity == 0 ? 'color:red;' : 'color:green;'"
        >In stock Quantity: {{ seller.quantity || '100' }}</span
      >
    </p>
    <!-- <p class="product-detail">Flavour Name : <b>Flavour name</b></p> -->
    <table class="specification">
      <!-- <tr>
        <td width="20%"><b>Design</b></td>
        <td>Whey</td>
      </tr>
      <tr>
        <td><b>Ingredients</b></td>
        <td>
          Protein Blend (Whey Protein Isolate, Whey Protein Concentrate, Whey
          Protein Peptides), Cocoa Powder....
        </td>
      </tr>
      <tr>
        <td><b>Flavour</b></td>
        <td>Double Rich Chocolate</td>
      </tr> -->
      <tr>
        <td><b>Brand</b>: {{ seller.brand_name || 'Apple' }}</td>
        <!-- </tr>
      <tr>
        <td><b>Item Weight</b></td>
        <td>453.59 Grams</td>
      </tr>
      <tr>
        <td><b>Allergen</b></td>
        <td>Milk</td>
      </tr>
      <tr>
        <td colspan="2"><a href="">See More</a></td>
      </tr> -->
      </tr>
      <!-- <tr>
        <td>
          <button class="price-option" @click="showVariants = !showVariants">
            Other Variants of this Product
          </button>
        </td>
      </tr> -->
    </table>

    <hr class="product-divider" />

    <product-other-variant
      :product="product"
      :index="index"
      @changeVariant="onVariantChange"
    />
  </div>
</template>

<script>
import moment from 'moment';
import ProductOtherVariant from './ProductOtherVariant.vue';
export default {
  components: { ProductOtherVariant },
  props: ['product', 'index'],
  data(props) {
    return {
      productName: props.product.name || '',
      defaultPrice:
        props.product['Seller_Specific_Detail'][props.index].default_price ||
        100,
      showVariants: false,
    };
  },

  computed: {
    seller() {
      return this.product['Seller_Specific_Detail'][this.index];
    },
    weekFromToday() {
      return moment()
        .add(7, 'days')
        .format('MMMM Do YYYY');
    },
  },
  mounted() {
    console.log('rerendered');
  },
  methods: {
    methodThatForcesUpdate() {
      // ...
      this.$forceUpdate();
    },
    onVariantChange(indices) {
      console.log({ indices });
      if (indices > -1) {
        const attr = this.product['Seller_Specific_Detail'][this.index]
          .product_attribute[indices];
        // this.productName = `${this.product.name}-${attr.description}`;
        this.defaultPrice = attr.values[0].default_price;
        this.$emit('changeVariant', indices);
      }
    },
    updateDefaultVariant() {
      this.productName = this.product.name;
      this.$emit('updateDefaultVariant');
    },
  },
};
</script>

<style></style>
