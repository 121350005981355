<template>
  <div class="checkout-relative">
    <div class="loader-container" v-if="loading">
      <spinner />
    </div>
    <main class="main">
      <div class="container">
        <div class="cart">
          <div class="row">
            <!-- cart left -->
            <div class="col-md-9 mb-4">
              <div class="bg-white cart-panel">
                <div class="checkout-heading">
                  Checkout (<span>{{ itemCount }} Items</span>)
                  <p>
                    By placing your order, you agree to worldmediacltrade.com’s
                    <router-link to="/policy-rules">privacy policy</router-link>
                    and
                    <router-link to="/policy-rules"
                      >conditions of use</router-link
                    >
                  </p>
                </div>
                <div class="checkout-address">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="address">
                        <h2>
                          Shipping Address
                          <span
                            ><router-link to="/cart/address">
                              Change
                            </router-link></span
                          >
                        </h2>
                        <p>{{ address.shipping.name }}</p>
                        <p>
                          {{ address.shipping.address }}
                        </p>
                        <p>
                          <b>Contact Number</b><br />
                          {{ address.shipping.contact }}
                        </p>
                        <p>
                          <b>Email Address</b><br />
                          {{ address.shipping.email }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="address">
                        <h2>
                          Payment Method
                          <span
                            ><router-link to="/cart/address">
                              Change
                            </router-link></span
                          >
                        </h2>
                        <p>{{ paymentOptions.COD }}</p>

                        <h2 class="mt-4">
                          Billing Address
                          <span
                            ><router-link to="/cart/address">
                              Change
                            </router-link></span
                          >
                        </h2>
                        <p>{{ address.billing.name }}</p>
                        <p>
                          {{ address.billing.address }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="gift-card">
                        <h2>Gift card & promotional codes :</h2>
                        <form @submit.prevent>
                          <input
                            type="text"
                            name=""
                            maxlength="10"
                            placeholder="Enter Code"
                          />
                          <button type="submit" name="">Apply</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-item">
                  <div class="row">
                    <div class="col-md-8">
                      <!-- item start -->
                      <div class="row" v-for="item in cartItems" :key="item.id">
                        <div class="col-md-2">
                          <img :src="item.imgurl" class="product-img" />
                        </div>
                        <div class="col-md-8">
                          <p class="product-name">
                            {{ item.product_name }}
                          </p>
                          <p class="qty">Qty: {{ item.quantity }}</p>
                        </div>
                        <div class="col-md-2">
                          <p class="price">&#8377; {{ item.item_price }}</p>
                        </div>
                      </div>
                      <!-- item end -->
                    </div>
                    <div class="col-md-4">
                      <div class="delivery-option">
                        <h2>Choose Delivery Option:</h2>
                        <div class="shipping">
                          <input type="radio" name="delivery" checked /> Free
                          Shipping - <span>{{ weekFromToday }}</span>
                        </div>
                        <div class="shipping">
                          <input type="radio" name="delivery" /> Standard
                          Shipping - <span>{{ day5FromToday }}</span>
                        </div>
                        <div class="shipping">
                          <input type="radio" name="delivery" /> Tow Day
                          Shipping - <span>With in 2 hours</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <order-summary
              :showBtn="true"
              :totalPrice="totalPrice"
              @proceedToPay="proceedToPay"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
  <!-- End of Main -->
</template>

<script>
import moment from 'moment';
import OrderSummary from '@/components/customer/cart/OrderSummary.vue';
import Spinner from '@/components/Spinner.vue';
import OrderService from '@/services/order-service';
import { createNamespacedHelpers } from 'vuex';
import { success, warning } from '@/util/ToastUtil';
const { mapGetters } = createNamespacedHelpers('cart');
const addressMapGetters = createNamespacedHelpers('address').mapGetters;
const authMapGetters = createNamespacedHelpers('auth').mapGetters;
export default {
  components: { OrderSummary, Spinner },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['paymentOptions', 'cartItems', 'totalPrice', 'itemCount']),
    ...addressMapGetters(['address']),
    ...authMapGetters(['userInfo']),
    weekFromToday() {
      return moment()
        .add(7, 'days')
        .format('MMMM Do YYYY');
    },
    day5FromToday() {
      return moment()
        .add(5, 'days')
        .format('MMMM Do YYYY');
    },
  },
  methods: {
    async proceedToPay() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          'order/addOrder',
          this.userInfo.id
        );
        let order = res.data.DATA;
        const orderId = order._id;
        res = await OrderService.checkoutOrder(orderId);
        order = res.data.DATA;
        this.$store.dispatch('order/addOrders', order);
        this.$store.dispatch('cart/resetCart');
        this.loading = false;
        this.$wkToast('Order placed succesfully', success);
        this.$router.replace('/thanksfororder');
      } catch (err) {
        this.loading = false;
        this.$wkToast('Order failed', warning);
        console.log(err);
      }
      // } else {
      //   alert(res);
      // }
    },
  },
};
</script>

<style scoped>
.loader-container {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 999;
}
.checkout-relative {
  position: relative;
}
</style>
