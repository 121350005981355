<template>
  <div class="row">
    <div class="col-md-2">
      <img :src="item.imgurl" class="product-img" />
    </div>
    <div class="col-md-8">
      <p class="product-name">
        {{ item.product_name }}
      </p>
      <p class="in-stock">{{ item.stock_status }}</p>
      <p class="sold-by">
        Sold By <a href="">{{ item.sold_by }}</a>
      </p>
      <p class="gift">Gift options not available. <a href="">Learn more</a></p>
      <span>
        Qty:
        <select
          v-if="quantity < 10"
          class="quantity"
          @change="updateItem(item.id, cartType.CART)"
          v-model="quantity"
        >
          <option value="0">
            0 (Delete)
          </option>
          <option
            v-for="qt in 9"
            :key="qt"
            :value="qt"
            :selected="qt == item.quantity"
          >
            {{ qt }}
          </option>
          <option value="10">
            10+
          </option>
        </select>
        <span v-else class="">
          <input type="text" v-model="quantityUpdated" class="qty-input" />
          <button
            class="btn-update"
            @click="updateQuantity(item.id, cartType.CART)"
          >
            update
          </button>
        </span>
      </span>

      <button class="cart-btn" @click="deleteItem(item.id)">
        Delete</button
      ><button
        class="cart-btn"
        @click="updateItem(item.id, cartType.WISH_LIST)"
      >
        Save For Later
      </button>
      <router-link to="/"
        ><button class="cart-btn">See More Like This</button></router-link
      >
    </div>
    <div class="col-md-2">
      <p class="price">&#8377; {{ item.product_price }}</p>
    </div>
    <div class="col-md-12"><hr /></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { cartType } from '@/typesEnum/cartType.js';
const { mapGetters } = createNamespacedHelpers('auth');
export default {
  props: ['item'],
  data(props) {
    return {
      quantity: props.item.quantity,
      quantityUpdated: props.item.quantity,
      cartType,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    price() {
      return this.item.product_price * this.item.quantity;
    },
  },
  methods: {
    plusHandler() {
      this.quantity += 1;
    },
    minusHandler() {
      if (this.quantity <= 1) {
        this.quantity = 1;
      } else {
        this.quantity -= 1;
      }
    },
    updateQuantity(id, cartType) {
      this.quantity = this.quantityUpdated;
      this.updateItem(id, cartType);
    },

    deleteItem(id) {
      this.$store.dispatch('cart/deleteItem', id);
    },
    saveItem(item) {
      let payload = { ...item };
      payload['customer_id'] = this.userInfo.id;
      this.$store.dispatch('cart/saveItem', payload);
    },
    updateItem(id, cartType) {
      if (this.quantity == 0) {
        this.deleteItem(id);
        return;
      }
      if (this.quantity == 10 && this.quantity != this.quantityUpdated) {
        return;
      }
      this.quantityUpdated = this.quantity;
      debugger;
      this.$store.dispatch('cart/updateItem', {
        id,
        quantity: this.quantity,
        shopping_cart_type_id: cartType,
      });
    },
  },
};
</script>

<style scoped>
.qty-input {
  margin-top: 20px;
  width: 60px;
  border: 1px solid #2a9cf5;
  border-radius: 5px;
  margin-right: 5px;
}
.btn-update {
  font-size: 11px;
  height: 20px;
  margin-top: 20px;
  padding: 0 6px 0 7px;
  border: 2px solid yellow;
  background-color: yellow;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  margin-right: 5px;
}
</style>
