<template>
  <div class="col-6 col-md-2 category-product mb-4">
    <router-link :to="`/product/${product.slug}`" target="_blank">
      <div class="product bg-white">
        <figure class="product-media">
          <div class="product-label-group">
            <label
              class="product-label label-new"
              :style="
                product.quantity == 0 ? 'background:rgb(168, 50, 70);' : ''
              "
              >{{ product.quantity == 0 ? "Out of Stock" : "Sale" }}</label
            >
          </div>
          <div>
            <img
              :src="imgSrc(product)"
              alt="Product"
              width="300"
              height="338"
            />
          </div>
        </figure>
        <div class="product-details">
          <h4 class="product-name">
            <span>{{ product.name }}</span>
          </h4>
          <!-- <span>{{ seller.short_description }}</span> -->
          <div class="ratings-container">
            <div class="ratings-per">4.3%</div>
            <div class="rating-reviews">(19 ratings)</div>
          </div>
          <div class="product-pa-wrapper">
            <!-- <div class="product-price-old">
                    MRP <del>1899</del> <span>16% off</span>
                  </div> -->
            <div class="product-price">
              <span>&#8377; {{ getPrice(product) }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["product"],

  data(props) {
    return {
      productInfo: {
        name: props?.product?.name,
        // quantity:
        //   props?.product?.quantity ||
        //   props?.product?.Seller_Specific_Detail?.quantity ||
        //   props?.product?.Seller_Specific_Detail[0].quantity,
        // price:
        //   props?.product?.quantity ||
        //   props?.product?.Seller_Specific_Detail[0].price,
        // imgurl:
        //   props?.product?.imgurl ||
        //   props?.product?.Seller_Specific_Detail[0].imgurl[0],
      },
    };
  },

  computed: {
    // seller() {
    //   return this.product.Seller_Specific_Detail[0];
    // },
  },
  methods: {
    imgSrc(product) {
      let img = product.Seller_Specific_Detail[0]?.imgurl?.filter(x=>x);
      if(img && img?.length > 0) return img[0];
      return '/no-image.jpg';
    },
    openProductDetails() {
      this.$router.push("/product/" + this.product._id);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getPrice(data) {
      if (data.Seller_Specific_Detail) {
        if (data.Seller_Specific_Detail.length > 0) {
          return data.Seller_Specific_Detail[0].default_price;
        }
      }
      return "0";
    },
  },
};
</script>
<style scoped></style>
