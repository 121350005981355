<template>
  <div>
    <div class="share">
      Share
      <a href=""><i class="fa fa-envelope-o mail"></i></a>
      <a href=""><i class="fa fa-facebook facebook"></i></a>
      <a href=""><i class="fa fa-twitter twitter"></i></a>
      <a href=""><i class="fa fa-pinterest pinterest"></i></a>
    </div>
    <div class="add-to-cart-section">
      <form>
        <div class="product-qty-form">
          <div class="input-group">
            <button
              type="button"
              class="quantity-minus w-icon-minus"
              @click.prevent="minusHandler"
            ></button>
            <input
              class="quantity form-control quantity-input"
              type="text"
              min="1"
              max="100000"
              v-model="quantity"
            />
            <button
              type="button"
              class="quantity-plus w-icon-plus"
              @click.prevent="plusHandler"
            ></button>
          </div>
        </div>
      </form>

      <button class="addtocart" @click="addToCart(1)">
        {{ cartAdded ? "Added" : "Add" }} to Cart
      </button>
      <button class="buynow bg-green" @click="buyNow(1)">
        {{ cartAdded ? "Added" : "Buy" }} Now
      </button>
      <div class="secure">
        <i class="fa fa-lock"></i> <a href="">Secure transaction</a>
      </div>
      <div class="location-1">
        <i class="fa fa-map-marker"></i> <a href="">Select delivery location</a>
      </div>
      <button class="addtowishlist" @click="addToCart(2)">
        {{ wishListAdded ? "Added" : "Add" }} to Wish List
      </button>
      <spinner v-if="loading" />
    </div>
  </div>
</template>
<script>
import { warning, success } from "@/util/ToastUtil";
import { createNamespacedHelpers } from "vuex";
import CartService from "@/services/cart-service";
import Spinner from "@/components/Spinner.vue";
import { v4 as uuidv4 } from "uuid";
const { mapGetters } = createNamespacedHelpers("auth");
export default {
  components: { Spinner },
  props: ["product", "index"],
  data() {
    return {
      quantity: 1,
      cartAdded: false,
      wishListAdded: false,
      loading: false,
    };
  },
  computed: { ...mapGetters(["userInfo", "isLogin"]) },
  methods: {
    addToCart(cartType) {
      if (cartType == '2' && !this.isLogin) {
        this.$wkToast("Please Login to create wishlist", warning);
        return;
      }
      if (this.product.Seller_Specific_Detail[this.index].quantity == 0) {
        this.$wkToast("Product out of stock", warning);
        return;
      }
      if (!this.validation()) {
        return;
      }
      if (this.wishListAdded && cartType == 2) {
        this.$wkToast("Product Already Added To the WishList");
        return;
      }
      if (!this.isLogin) {
        const id = uuidv4();
        let item = {
          id,
          product_id: this.product._id,
          product_price: this.product.price,
          product_name: this.product.name,
          quantity: this.quantity,
          shopping_cart_type_id: cartType,
          item_price: this.product.price * this.quantity,
          stock_status: "In Stock",
          imgurl: this.product.imgurl,
        };
        if (cartType == 2) {
          this.wishListAdded = true;
        }
        this.$store.dispatch("cart/addSessionCartItem", item);
        this.$wkToast(
          cartType == 1
            ? "Product Added To The Cart"
            : "Product Added To The WishList"
        );

        return;
      }

      const data = {
        product_id: this.product._id,
        customer_id: this.userInfo.id,
        quantity: this.quantity,
        shopping_cart_type_id: cartType,
        vendor_id: this.product?.Seller_Specific_Detail[this.index].customer_id,
      };
      this.loading = true;
      // console.log("cart data", data);
      CartService.addCart(data)
        .then((response) => {
          const cartItem = response.data.DATA;
          if (cartItem) {
            let item = {
              id: cartItem._id,
              product_id: cartItem.productid,
              product_price: 0,
              product_name: "product_name",
              quantity: cartItem.quantity,
              shopping_cart_type_id: cartItem.shoppingcarttypeid,
              item_price: 0,
              stock_status: "In Stock",
            };
            cartType == 1
              ? this.$store.dispatch("cart/addItemToCart", item)
              : this.$store.dispatch("cart/addItemToWishList", item);
          }
          const status = response.data.STATUS;
          if (status == "SUCCESS") {
            if (cartType == 2) {
              this.wishListAdded = true;
            }
          }
          this.$wkToast(response.data.MESSAGE, success);

          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.loading = false;
          this.$wkToast("Product Not added to cart", warning);
        });
    },

    buyNow(cartType) {
      if (this.product.Seller_Specific_Detail[this.index].quantity == 0) {
        this.$wkToast("Product out of stock", warning);
        return;
      }
      if (!this.validation()) {
        return;
      }
      if (!this.isLogin) {
        const id = uuidv4();
        let item = {
          id,
          product_id: this.product._id,
          product_price: this.product.price,
          product_name: this.product.name,
          quantity: this.quantity,
          shopping_cart_type_id: cartType,
          item_price: this.product.price * this.quantity,
          stock_status: "In Stock",
          imgurl: this.product.imgurl,
        };
        this.$store.dispatch("cart/addSessionCartItem", item);
        window.location.href = '/cart/address';
        return;
      }

      const data = {
        product_id: this.product._id,
        customer_id: '0',
        quantity: this.quantity,
        shopping_cart_type_id: cartType,
        vendor_id: this.product?.Seller_Specific_Detail[this.index].customer_id,
      };
      this.loading = true;
      // console.log("cart data", data);
      CartService.addCart(data)
        .then((response) => {
          const cartItem = response.data.DATA;
          if (cartItem) {
            let item = {
              id: cartItem._id,
              product_id: cartItem.productid,
              product_price: 0,
              product_name: "product_name",
              quantity: cartItem.quantity,
              shopping_cart_type_id: cartItem.shoppingcarttypeid,
              item_price: 0,
              stock_status: "In Stock",
            };
          }
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.loading = false;
          this.$wkToast("Product Not added to cart", warning);
        });
    },
    plusHandler() {
      if (
        this.quantity >=
        this.product?.Seller_Specific_Detail[this.index].quantity
      ) {
        this.quantity =
          this.product.this.product?.Seller_Specific_Detail[
            this.index
          ].quantity;
      } else {
        this.quantity += 1;
      }
    },
    minusHandler() {
      if (this.quantity <= 1) {
        this.quantity = 1;
      } else {
        this.quantity -= 1;
      }
    },

    validation() {
      try {
        if (
          this.quantity >
            this.product.Seller_Specific_Detail[this.index].quantity ||
          this.quantity < 1
        ) {
          this.$wkToast("Quantity Not Allowed", warning);
          return false;
        }
        return true;
      } catch (err) {
        this.$wkToast("Quantity Not Allowed", warning);
        return false;
      }
    },
  },
};
</script>
