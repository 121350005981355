import axios from 'axios';
import authService from '../../services/auth.service';

export default {
  namespaced: true,
  state() {
    return {
      drafts: [],
    };
  },
  mutations: {
    reset(state) {
      state.drafts = [];
    },
    addToDrafts(state, data) {
      state.drafts.push(data);
    },
    deleteDraft(state, draft) {
      const index = state.drafts.findIndex(
        (d) => d.product.name === draft.product.name
      );
      if (index > -1) {
        state.drafts.splice(index, 1);
      }
    },
  },
  actions: {
    addToDrafts({ commit }, data) {
      commit('addToDrafts', data);
    },
    deleteDraft({ commit }, draft) {
      commit('deleteDraft', draft);
    },
  },

  getters: {
    drafts(state) {
      return state.drafts;
    },
  },
};
