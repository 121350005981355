<template>
  <div class="order">
    <div class="order-header">
      <div class="row">
        <div class="col-md-2">
          ORDER PLACED<br />{{
            formatDate(order.payment_method.created_on_utc_date)
          }}
        </div>
        <div class="col-md-2">
          TOTAL<br /><i class="fa fa-inr"> {{ totalPrice }}</i>
        </div>
        <div
          class="col-md-2"
          @mouseover="showShipAddress = true"
          @mouseleave="showShipAddress = false"
        >
          SHIP TO<br /><a href=""
            >{{ order.shipping_address.first_name }}
            <i class="fa fa-angle-down"></i
          ></a>
          <div class="ship-address-detail" v-if="showShipAddress">
            RZ-53, Street No.3, Bajaj Enclave, Kakrola, Dwarka, New Delhi -
            110078
          </div>
        </div>
        <div class="col-md-6 text-right">
          ORDER # {{ order._id }}<br /><router-link
            :to="'/order/' + order._id"
            >View order details</router-link
          >
          |
          <!-- <a href="">Invoice</a> -->
        </div>
      </div>
    </div>
    <div class="order-detail">
      <div class="row">
        <div class="col-md-9">
          <h3 class="order-delevery-day">{{ weekFromToday() }}</h3>

          <div
            class="row"
            v-for="item in order.order_item"
            :key="item.productid"
          >
            <div class="col-md-3">
              <img :src="item?.imgurl[0]" class="product-img" />
            </div>
            <div class="col-md-9">
              <a href="">{{ item.product_name }}</a>
              <div>Quantity:{{ item.quantity }}</div>
              <div>Price:&#8377; {{ item.price }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <!-- <button class="track-package">Track Package</button> -->

          <button class="buying">Request cancellation</button>
          <!-- <button class="buying">Leave with a neighbour</button> -->
          <!-- <button class="buying">Archive order</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['order'],
  data() {
    return {
      showShipAddress: false,
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      if (this.order && this.order.order_item) {
        this.order.order_item?.map((el) => (total += el.price * el.quantity));
      }
      return total;
    },
  },
  methods: {
    formatDate(str) {
      const d = new Date(str);
      debugger;
      return d.toDateString();
    },
    weekFromToday() {
      return moment()
        .add(7, 'days')
        .format('MMMM Do YYYY');
    },
  },
};
</script>

<style scoped>
.ship-address-detail {
  display: block;
}
</style>
