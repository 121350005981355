<template>
  <div class="footer-bottom">
    <div class="container text-center">
      <p>
        worldmediacaltrade.com Site: International - Español - Português -
        Deutsch - Français - Italiano - हिंदी - Pусский - 한국어 - 日本語 -
        اللغة العربية - ภาษาไทย - Türk - Nederlands - tiếng Việt - Indonesian -
        עברית
      </p>
      <p>
        Browse Alphabetically: Onetouch | Showroom | Country Search | Suppliers
        | Affiliate
      </p>
      <p>
        Product Listing Policy - Intellectual Property Protection - Privacy
        Policy - Terms of Use - User Information Legal Enquiry Guide
      </p>
      <p>
        Trade Alert - Delivering the latest product trends and industry news
        straight to your inbox.
      </p>
      <div class="subscribe">
        <form
          action="#"
          method="get"
          class="input-wrapper input-wrapper-inline input-wrapper-rounded"
        >
          <input
            type="email"
            class="form-control mr-2 blue-bg text-default"
            name="email"
            id="email"
            placeholder="Your E-mail Address"
          />
          <button
            class="btn btn-primary btn-rounded transparent-bg"
            type="submit"
          >
            Subscribe
          </button>
        </form>
      </div>

      <img src="/images/payment.png" alt="payment" width="300" />
      <p class="copyright">
        © 2021 Worldmedicaltrade.com. All rights reserved | Designed And
        Developed By Diigiad Multimedia
      </p>
    </div>
  </div>
</template>
