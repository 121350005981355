<template>
  <footer class="footer">
    <div class="footer-bottom">
      <div class="container">
        <p class="copyright text-right">
          <a href="">Terms of Service</a> | <a href="">Privacy Policy</a> |
          &copy; 2021 Worldmedicaltrade.com. All rights reserved
        </p>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.footer {
  background: transparent;
}
nav li {
  display: inline-block;
  padding: 10px;
  color: rgb(106, 106, 106);
}
nav li a {
  color: rgb(106, 106, 106);
}
</style>
