<template>
  <div class="tab-pane active in" id="tab4-1">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>

    <div>
      <div class="row ml-4 mr-4">
        <div class="col-md-6">
          <h5>Personal Info</h5>
        </div>
        <div
          class="col-md-6 d-flex justify-content-end align-items-center"
          v-if="!showEdit && self"
          @click="showEdit = true"
        >
          <i class="fas fa-edit"></i>
        </div>
      </div>
      <div v-if="!showEdit">
        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Email</h5>
            <span>{{ email }}</span>
          </div>
        </div>
        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Name</h5>
            <span>{{ name }}</span>
          </div>
        </div>

        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Status</h5>
            <span>{{ status }}</span>
          </div>
        </div>

        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Mobile</h5>
            <span>{{ mobile }}</span>
          </div>
        </div>

        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Company</h5>
            <span>{{ company }}</span>
          </div>
        </div>

        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Avatar</h5>
            <img :src="avatar" alt="" width="50" height="50" />
          </div>
        </div>

        <div class="row">
          <div class="pt-4 pb-4 ml-4">
            <h5>Location</h5>
            <span>{{ location }}</span>
          </div>
        </div>
      </div>

      <form
        v-else
        class="form account-details-form ml-4 mr-4"
        @submit.prevent="updateSellerInfo"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for=""> First Name </label>
              <input
                type="text"
                class="form-control form-control-md"
                maxlength="50"
                pattern="[a-zA-Z0-9-._\s]+"
                required
                v-model="firstname"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for=""> Last Name </label>
              <input
                type="text"
                class="form-control form-control-md"
                maxlength="50"
                pattern="[a-zA-Z0-9-._\s]+"
                required
                v-model="lastname"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for=""> Mobile </label>
              <div
                class="
                  form-control form-control-md
                  mask-code
                  align-items-center
                  d-flex
                "
              >
                <span>+91</span>
                <input
                  type="tel"
                  placeholder="Enter Mobile Number"
                  required
                  maxlength="10"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  pattern="[7-9]{1}[0-9]{9}"
                  title="Phone number with 7-9 and remaing 9 digit with 0-9"
                  v-model="mobile"
                />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for=""> Company </label>
              <input
                type="text"
                placeholder="Location"
                class="form-control form-control-md"
                v-model="company"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for=""> Location </label>
              <input
                type="text"
                placeholder="Location"
                class="form-control form-control-md"
                :value="location"
                readonly
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for=""> Avatar </label>
              <input
                type="file"
                class="form-control form-control-md"
                accept="image/*"
                @change="onFileSelect"
              />
            </div>
          </div>

          <div class="row">
            <image-uploader
              :url="url"
              index="0"
              @delete-file="deleteFile"
              showDelete="true"
              class="ml-4"
            />
          </div>
        </div>

        <button type="submit" class="btn btn-dark btn-rounded btn-sm mb-4">
          Save
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
import { createNamespacedHelpers } from "vuex";
import ImageUploader from "@/components/util/ImageUploader.vue";
import SellerService from "@/services/seller-service";
import { useGeolocation } from "@/util/useGeolocation";
import { s3NewFileUrl, S3Client, s3NewFileName, s3Upload } from "@/util/s3upload";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  components: { Spinner, ImageUploader },
  setup() {
    const { coords } = useGeolocation();

    return { coords };
  },
  data() {
    return {
      showEdit: false,
      loading: false,
      sellerId: this.$route.params.id,
      sellerInfo: null,

      //seller info
      email: null,
      name: null,
      status: null,
      mobile: null,
      avatar: null,
      location: null,

      //form
      firstname: null,
      lastname: null,
      mobileno: null,
      url: null,
      company: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    self() {
      return this.userInfo.id === this.sellerId;
    },
  },
  mounted() {
    if (!this.self) {
      this.getSellerInfo(this.sellerId);
    } else {
      this.setSellerInfo(this.userInfo);
    }
  },
  methods: {
    setSellerInfo(info) {
      console.log({ coords_print: this.coords.latitude });
      this.email = info.email;
      this.name = `${info.firstname} ${info.lastname} `;
      this.status = info.is_approved ? "Approved" : "Pending";
      this.mobile = info.phonenumber;
      this.avatar = info.avatar && info.avatar.length ? info.avatar[0] : null;
      debugger;
      this.location = info.geo_location
        ? `${info.geo_location.latitude}, ${info.geo_location.longitude}`
        : `${this.coords.latitude}, ${this.coords.longitude}`;

      this.firstname = info.firstname;
      this.lastname = info.lastname;
      this.mobileno = info.phonenumber;
      this.url = this.avatar;
      this.company = info.company;
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = "";
        this.$wkToast("Please Select file less than 1mb");
        return;
      }

      this.file = file;
      this.url = URL.createObjectURL(file);
    },
    deleteFile() {
      this.url = null;
      this.file = null;
    },
    async getSellerInfo(id) {
      try {
        this.loading = true;
        const res = await SellerService.getSellerById(id);
        const data = res.data.DATA;
        if (data) {
          this.sellerInfo = data;
          this.setSellerInfo(this.sellerInfo);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateSellerInfo() {
      if (!this.isValidated()) {
        return;
      }

      try {
        this.loading = true;

        if (this.url.includes("localhost")) await this.uploadToS3(this.file);

        const payload = {
          firstname: this.firstname,
          lastname: this.lastname,
          avatar: [this.url],
          geo_location: {
            latitude: this.coords.latitude,
            longitude: this.coords.longitude,
          },
          phonenumber: this.mobileno,
          company: this.company,
          is_approved: this.userInfo.is_approved || false,
          active: this.userInfo.active || false,
          deleted: this.userInfo.deleted || false,
        };

        const res = await SellerService.updateSeller(this.sellerId, payload);
        debugger;
        const data = res.data.DATA;
        if (data) {
          data.id = data._id;
          // data.firstname
          //   ? (data.username = data.firstname)
          //   : (data.username = data.email.split('@')[0]);
          data.username = data.firstname || data.email.split("@")[0];
          this.$store.dispatch("auth/setUserInfo", data);
        }
      } catch (err) {
        // console.log(err);
        if (err.response) this.$wkToast(err.response.data.message);
      }
      this.loading = false;
      this.showEdit = false;
      this.setSellerInfo(this.userInfo);
    },
    async uploadToS3(file) {
      let fileExtension = file.type.split("/")[1];
      if (fileExtension === "svg+xml") {
        fileExtension = "svg";
      }
      const s3NewFile = s3NewFileName();
      try {
        await s3Upload(file, s3NewFile);
      } finally {
        this.url = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
      }
    },
    isValidated() {
      return true;
    },
  },
};
</script>

<style scoped>
.mask-code {
  padding: 5px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  width: 94%;
}
.mask-code input {
  background-color: transparent;
  border: 0px solid;
  display: table-cell;
  width: 90%;
}

.mask-code input:focus {
  box-shadow: 0px 0px 0px 0px transparent;
  outline: 0;
}

.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
