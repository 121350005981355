<template>
  <main class="main bg-white">
    <div class="full-wrapper">
      <h4>Order Issues List</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="right-panel">
            <div class="order-div">
              <p>Order Issues List 1 to 15 out of 1025</p>
            </div>
            <div class="order-data">
              <table cellpadding="0" cellspacing="0" class="issues-table">
                <tr>
                  <th>Issue Raise Refrence No.<br />and Date</th>
                  <th>Order Detail</th>
                  <th>Customer Detail</th>
                  <th>Shipping</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>
                    <a href="order-detail.html">888-85412522-6265626522</a
                    ><br />
                    25/10/2021 <span>06:32 PM</span>
                  </td>
                  <td>
                    <a href="order-detail.html">888-85412522-6265626522</a
                    ><br />
                    Product Name
                    <p><span>QTY: 1</span> <span>SKU: 1254</span></p>
                  </td>
                  <td>
                    Mohit Sharma
                    <p>
                      RZ-53, Street No. 3, Bajaj Enclave, Kakrola, Dwarka, New
                      Delhi - 110078
                    </p>
                  </td>
                  <td>
                    Standard<br />
                    <p>Expected Ship Date<br /><span>Oct 26, 2021</span></p>
                    <p>
                      Deliver By<br /><span>Oct 26, 2021 to Oct 28, 2021</span>
                    </p>
                  </td>
                  <td>
                    Unshipped
                  </td>
                  <td>
                    <select name="">
                      <option value="">Update Status</option>
                      <option value="">Pending</option>
                      <option value="">Resolve</option>
                      <option value="">Close</option>
                    </select>
                    <button type="button" class="order-btn">Update</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
