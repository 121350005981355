<template>
  <div class="controls tier-price">
    <div class="variant-inner">
      <label for="">Quantity Upto</label>
      <input
        class="form-control"
        type="number"
        min="1"
        name="quantity"
        :value="quant"
        readonly
      />
    </div>
    <div class="tier-price-inner">
      <label for="">Price</label>
      <input
        class="form-control"
        type="number"
        min="1"
        name="price"
        :value="pric"
        readonly
      />
    </div>
    <a
      @click.prevent="$emit('remove', index)"
      class="remove_this btn btn-dark btn-rounded red-bg btn-sm mt-5"
      >remove</a
    >
  </div>
</template>

<script>
export default {
  props: ['quantity', 'price', 'index'],
  data(props) {
    return {
      quant: props.quantity,
      pric: props.price,
    };
  },
};
</script>

<style></style>
