<template>
  <div class="tab-pane active in" id="tab4-1">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>

    <div v-else-if="companyOverviews">
      <div class="tab-pane ">
        <table cellpadding="0" cellspacing="0" class="product-detail-tab">
            <col><col>
              <tr
                v-for="[key, value] in Object.entries(companyOverviews)"
                :key="key"
              >
                <td>
                  {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
                </td>
                <td>{{ key === 'BusinessType' ? business[value] : value }}</td>
            </tr>
        </table>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h5 class="p-15 mb-0">Company Album</h5>
        </div>
      </div>
      <div class="row">
        <image-uploader
          v-for="(url, index) in imageUrls"
          :key="index"
          :url="url"
          :index="index"
          class="ml-4"
        />
      </div>
    </div>
    <div v-else class="p-15">Not Available</div>
  </div>
</template>

<script>
import ImageUploader from '@/components/util/ImageUploader.vue';
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;

export default {
  components: { ImageUploader, Spinner },
  props: ['vendorId'],
  data() {
    return {
      loading: false,
      imageUrls: [],
      companyOverviewId: '',

      coverview: {
        BusinessType: 1,
        MainProducts: 'product short desc...',
        CountryRegion: 'product full desc.....',
        Ownership: 'Ownership',
        TotalEmployees: 3,
        TotalAnnualRevenue: '234',
        YearEstablished: '3445',
        ProductCertification1: 'test m',
        ProductCertification2: 'manufacturer_part_name',
        Patent: 'asfas',
        Trademark: '90 cm',
        imgurl: [],
      },
      count: 1,
      business: ['Trading', 'Manufacturer', 'Distributor', 'Retailer'],
    };
  },
  computed: {
    companyOverviews() {
      if (this.companyOverviewBySellerId) {
        const {
          _id,
          imgurl,
          customerid,
          ...co
        } = this.companyOverviewBySellerId;
        this.setCompanyOverviewId(_id);
        this.setImageUrls(imgurl);
        return co;
      }

      return null;
    },
    companyOverviewBySellerId() {
      if (this.companyOverview) {
        return this.companyOverview[this.vendorId];
      }
      return null;
    },
    ...profileMapGetters(['companyOverview']),
  },
  mounted() {
    if (this.companyOverviewId == '') {
      this.getCompanyOverview();
    }
  },
  methods: {
    incrementCount() {
      return this.count++;
    },
    setCompanyOverviewId(id) {
      if (id) this.companyOverviewId = id;
    },
    setImageUrls(imgurl) {
      if (imgurl) this.imageUrls = imgurl;
    },
    getCompanyOverview() {
      this.loading = true;
      ProfileService.getCompanyOverview(this.vendorId)
        .then((res) => {
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addCompanyOverview', cover);
          } else {
            co = null; //this.coverview;
            co.customerid = this.vendorId;
            this.$store.dispatch('companyProfile/addCompanyOverview', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.loading-container {
  display: block;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
