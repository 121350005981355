<template>
  <div class="tab tab-nav-boxed tab-nav-solid">
    <ul class="product nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" href="#tab1-1">Product Details</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#tab1-2">Company Profile</a>
      </li>
    </ul>
    <div class="tab-content border-1">
      <div class="tab-pane active p-0" id="tab1-1">
        <div class="tab tab-nav-boxed tab-nav-solid">
          <ul class="sub-nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" href="#tab2-1">Product Description</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#tab2-2">Packing & Delivery</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab2-3">FAQs</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane p-15 active" id="tab2-1">
              <product-details :product="product" :index="index" />
            </div>
            <div class="tab-pane p-15" id="tab2-2">
              <p>Packing & Delivery</p>
            </div>
            <div class="tab-pane p-15" id="tab2-3">
              <p>FAQs</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane p-0" id="tab1-2">
        <div class="tab tab-nav-boxed tab-nav-solid">
          <ul class="sub-nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" @click="active_el = 1"
                >Company Overview</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="active_el = 2">Trade Capabilities</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="active_el = 3"
                >Producation Capacity</a
              >
            </li>

            <li class="nav-item">
              <a class="nav-link" @click="active_el = 4">Quality Control</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="active_el = 5">RnD Capacity</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="active_el = 6"
                >Factory Inspection Reports</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane p-15 active" id="tab3-1">
              <company-overview v-if="active_el === 1" :vendorId="vendorId" />
              <trade-capabilities
                v-else-if="active_el === 2"
                :vendorId="vendorId"
              />
              <production-capacity
                v-else-if="active_el === 3"
                :vendorId="vendorId"
              />
              <quality-control
                v-else-if="active_el === 4"
                :vendorId="vendorId"
              />
              <rnd-capacity v-else-if="active_el === 5" :vendorId="vendorId" />
              <factory-inspection-reports
                v-else-if="active_el === 6"
                :vendorId="vendorId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyOverview from './CompanyOverview.vue';
import TradeCapabilities from './TradeCapabilities.vue';
import ProductionCapacity from './ProductionCapacity.vue';
import QualityControl from './QualityControl.vue';
import RndCapacity from './RndCapacity.vue';
import FactoryInspectionReports from './FactoryInspectionReports.vue';
import ProductDetails from '../products/ProductDetails.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('auth');

export default {
  components: {
    // SellerProfileNav,
    CompanyOverview,
    TradeCapabilities,
    ProductionCapacity,
    QualityControl,
    RndCapacity,
    FactoryInspectionReports,
    ProductDetails,
  },
  props: ['product', 'index'],
  data() {
    return {
      active_el: 1,
    };
  },
  computed: {
    vendorId() {
      return this.seller.customer_id || this.userInfo.id;
    },
    ...mapGetters(['userInfo']),
    seller() {
      return this.product['Seller_Specific_Detail'][this.index];
    },
  },
  methods: {
    selectedNav(value) {
      this.active_el = value;
    },
  },
};
</script>

<style></style>
