<template>
  <div id="wrapper">
    <div v-if="!isNoHeaderPage">
      <seller-header v-if="isVendor" />
      <!-- <customer-header v-if="isCustomer" :showCategory="showCategory" /> -->
      <customer-header v-else :showCategory="showCategory" />
    </div>

    <div class="wrapper"><router-view :key="$route.fullPath" /></div>

    <footer-bottom v-if="isVendor" />
    <section-footer v-else />

    <!-- Start of Scroll Top -->
    <a id="scroll-top" href="#top" title="Top" role="button" class="scroll-top"
      ><i class="fas fa-chevron-up"></i
    ></a>
    <!-- End of Scroll Top -->
  </div>
</template>

<script>
import SellerHeader from './components/seller/header/Header.vue';
import CustomerHeader from './components/customer/header/Header.vue';
import FooterBottom from './components/seller/footer/FooterBottom.vue';
import SectionFooter from './components/customer/footer/SectionFooter.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('auth');

export default {
  components: {
    SellerHeader,
    CustomerHeader,
    FooterBottom,
    SectionFooter,
  },
  computed: {
    ...mapGetters(['isVendor']),
    isNoHeaderPage() {
      if (
        this.$route.name == 'Login' ||
        this.$route.name == 'Signup' ||
        this.$route.name == 'CustomerLogin' ||
        this.$route.name == 'CustomerSignup' ||
        this.$route.name == 'SellerLogin' ||
        this.$route.name == 'SellerSignup' ||
        this.$route.name == 'AdminLogin' ||
        this.$route.name == 'AdminSignup'
        // this.$route.name == 'NotFound'
      ) {
        return true;
      }
      return false;
    },
    isNoFooterPage() {
      if (
        this.$route.name == 'Login' ||
        this.$route.name == 'Signup' ||
        this.$route.name == 'CustomerLogin' ||
        this.$route.name == 'CustomerSignup' ||
        this.$route.name == 'SellerLogin' ||
        this.$route.name == 'SellerSignup' ||
        this.$route.name == 'AdminLogin' ||
        this.$route.name == 'AdminSignup' ||
        this.$route.name == 'NotFound'
      ) {
        return true;
      }
      return false;
    },
    showCategory() {
      if (this.$route.name == 'CustomerHome') {
        return true;
      }
      return false;
    },
  },
};
</script>
<style></style>
