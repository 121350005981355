<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <!-- orders -->
          <div class="col-md-9 mb-4">
            <div class="bg-white order-panel">
              <h2>Your Raise Order Issue List</h2>

              <div class="order">
                <div class="order-header">
                  <div class="row">
                    <div class="col-md-3">ORDER ID<br />25 August 2021</div>
                    <div class="col-md-2">
                      ORDER AMOUNT<br /><i class="fa fa-inr">197.00</i>
                    </div>
                    <div class="col-md-3">
                      SHIP TO<br /><a href="" class="ship-address"
                        >Mohit Sharma <i class="fa fa-angle-down"></i
                      ></a>
                      <div class="ship-address-detail">
                        RZ-53, Street No.3, Bajaj Enclave, Kakrola, Dwarka, New
                        Delhi - 110078
                      </div>
                    </div>
                    <div class="col-md-4 text-right">
                      REFRENCE NO.<br />402-3976885-7596346
                    </div>
                  </div>
                </div>
                <div class="order-detail">
                  <div class="row">
                    <div class="col-md-12">
                      <p><b>Order/Product Issue</b>: Worng Product Recived</p>
                      <p><b>Description</b>:</p>
                      <p><b>Status</b>:</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- right panel -->
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12 col-xs-6 mb-4">
                <div class="bg-white cart-panel-right-sponsored">
                  <h2>Still looking for these essentials?</h2>
                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="/images/product/5.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Fast&Up 100%…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 1999.00</p>
                      <button class="addtocart-btn">Add to Cart</button>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="/images/product/6.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Horlicks Health &…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 412.00</p>
                      <button class="addtocart-btn">Add to Cart</button>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="/images/product/6.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Horlicks Health &…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 412.00</p>
                      <a href="" class="buying">See all buying options</a>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row">
                    <div class="col-md-4">
                      <img src="/images/product/7.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">MuscleBlaze Biozyme…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 4499.00</p>
                      <a href="" class="buying">See all buying options</a>
                    </div>
                  </div>
                  <!-- item end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
