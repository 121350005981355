<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-9 mb-4">
            <form @submit.prevent="addAddress">
              <div class="bg-white cart-panel">
                <div class="checkout-heading">Shipping Address</div>
                <div class="checkout-address">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="shipping-billing">
                        <input
                          type="text"
                          name="Name"
                          v-model="shipping.name"
                          placeholder="Name"
                          maxlength="50"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="shipping-billing d-flex justify-content-center align-items-center"
                      >
                          <span style="border: 1px solid #a6a6a6;padding: 6.5px;border-top-left-radius: 0.3rem;border-bottom-left-radius: 0.3rem;">+91</span>
                          <input style="border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-left:0 !important;"
                          type="tel"
                          name="phone"
                          v-model="shipping.contact"
                          placeholder="Contact No"
                          maxlength="10"
                          pattern="[7-9]{1}[0-9]{9}"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                          title="Phone number with 7-9 and remaing 9 digit with 0-9"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="shipping-billing">
                        <input
                          type="email"
                          name="email"
                          v-model="shipping.email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="shipping-billing">
                        <input
                          type="text"
                          name="address"
                          v-model="shipping.address"
                          placeholder="Address"
                          maxlength="200"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="shipping-billing">
                        <input
                          type="text"
                          name="city"
                          v-model="shipping.city"
                          placeholder="City"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="shipping-billing">
                        <input
                          type="text"
                          name="state"
                          v-model="shipping.state"
                          placeholder="State"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="shipping-billing">
                        <input
                          type="text"
                          name="pincode"
                          v-model="shipping.pincode"
                          placeholder="Pincode"
                          maxlength="6"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="shipping-billing">
                        <input
                          type="text"
                          name="country"
                          v-model="shipping.country"
                          placeholder="Country"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row shipping-billing-same">
                  <div class="col-md-12">
                    <input
                      type="checkbox"
                      name=""
                      class="sameshipping"
                      v-model="isChecked"
                      @change="sameAddress"
                    />
                    Shipping and Billing Address Same
                  </div>
                </div>

                <div v-if="!isChecked" class="billing">
                  <div class="checkout-heading">Billing Address</div>
                  <div class="checkout-address">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="shipping-billing">
                          <input
                            type="text"
                            name=""
                            v-model="billing.name"
                            placeholder="Name"
                            maxlength="50"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div
                          class="shipping-billing d-flex justify-content-center align-items-center"
                        >
                          <span style="border: 1px solid #a6a6a6;padding: 7px;border-top-left-radius: 0.3rem;border-bottom-left-radius: 0.3rem;">+91</span>
                          <input style="border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-left:0 !important;"
                            type="tel"
                            v-model="billing.contact"
                            placeholder="Contact No"
                            maxlength="10"
                            pattern="[7-9]{1}[0-9]{9}"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                            title="Phone number with 7-9 and remaing 9 digit with 0-9"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="shipping-billing">
                          <input
                            type="email"
                            name=""
                            v-model="billing.email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="shipping-billing">
                          <input
                            type="text"
                            name=""
                            v-model="billing.address"
                            placeholder="Address"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="shipping-billing">
                          <input
                            type="text"
                            name=""
                            v-model="billing.city"
                            placeholder="City"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="shipping-billing">
                          <input
                            type="text"
                            name=""
                            v-model="billing.state"
                            placeholder="State"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="shipping-billing">
                          <input
                            type="text"
                            v-model="billing.pincode"
                            placeholder="Pincode"
                            maxlength="6"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="shipping-billing">
                          <input
                            type="text"
                            name=""
                            v-model="billing.country"
                            placeholder="Country"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-heading">Payment Method</div>
                <div class="checkout-address">
                  <div class="row">
                    <div class="col-md-9">
                      <div class="shipping-billint-radio">
                        <input type="radio" name="" checked /> Cash on Delivery
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="shipping-billing">
                        <!-- <router-link to="/cart/checkout"> -->
                        <button type="submit" name="">Continue</button>
                        <!-- </router-link> -->
                      </div>
                      <div
                        v-if="isLoadingOrder"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <div
                          class="spinner-border text-primary loader"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <spinner />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <order-summary :totalPrice="totalPrice" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import OrderSummary from '@/components/customer/cart/OrderSummary.vue';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import { warning } from '@/util/ToastUtil';
import { regEmail, regMobile } from '@/util/regexUtil';

const userMapGetters = createNamespacedHelpers('auth').mapGetters;
const addressMapGetters = createNamespacedHelpers('address').mapGetters;
const { mapGetters } = createNamespacedHelpers('cart');
export default {
  data() {
    return {
      isChecked: true,
      isLoadingOrder: false,
      shipping: {
        name: '',
        contact: '',
        email: '',
        address: '',
        pincode: '',
        city: 'Gurugram',
        state: 'Haryana',
        country: 'India',
      },
      billing: {
        name: '',
        contact: '',
        email: '',
        address: '',
        pincode: '',
        city: 'Gurugram',
        state: 'Haryana',
        country: 'India',
      },
    };
  },
  components: { OrderSummary, Spinner },
  mounted() {
    if (this.address) {
      this.shipping = { ...this.address.shipping };
      this.billing = { ...this.address.billing };
    }
  },
  computed: {
    ...mapGetters(['totalPrice']),
    ...userMapGetters(['userInfo']),
    ...addressMapGetters(['address']),
  },
  methods: {
    sameAddress() {
      if (this.isChecked) {
        this.billing = { ...this.shipping };
      }
    },
    validation() {
      if (
        !this.shipping.contact ||
        !this.shipping.email ||
        !this.shipping.name ||
        !this.shipping.address ||
        !this.shipping.pincode
      ) {
        this.$wkToast('Please Fill All Input Fields', warning);
        return false;
      }
      if (!this.isChecked) {
        if (
          !this.billing.contact ||
          !this.billing.email ||
          !this.billing.address ||
          !this.billing.name ||
          !this.billing.pincode
        ) {
          this.$wkToast('Please Fill All Input Fields', warning);
          return false;
        }
      }

      if (!this.isEmailValid(this.shipping.email)) {
        this.$wkToast('Please Fill Correct Email', warning);
        return false;
      }

      if (!this.isChecked && !this.isEmailValid(this.billing.email)) {
        this.$wkToast('Please Fill Correct Email', warning);
        return false;
      }

      return true;
    },
    addAddress() {
      this.sameAddress();
      if (!this.validation()) {
        return;
      }

      this.isLoadingOrder = true;
      this.$store.dispatch('address/addShippingAddress', this.shipping);
      this.$store.dispatch('address/addBillingAddress', this.billing);
      this.proceedToPay();
    },
    async proceedToPay() {
      this.$router.push('/cart/checkout');
      // let res = await this.$store.dispatch('order/addOrder', this.userInfo.id);
      // if (res == 'SUCCESS') {
      //   this.$router.push('/cart/checkout');
      // } else {
      //   alert(res);
      // }
      this.isLoadingOrder = false;
    },

    isEmailValid(email) {
      return email == '' ? false : regEmail.test(email) ? true : false;
    },
    isMobileValid(mobile) {
      return mobile.match(regMobile);
    },
  },
};
</script>
<style scoped></style>
