<template>
  <new-category-request />
</template>

<script>
import NewCategoryRequest from '../../components/seller/NewCategoryRequest.vue';
export default {
  components: { NewCategoryRequest },
};
</script>

<style></style>
