<template>
  <!-- Start of Header -->
  <div>
    <header class="header">
      <div class="header-middle">
        <div class="container">
          <div class="header-left mr-md-4">
            <router-link to="/" class="logo ml-lg-0">
              <!-- <a href="" > -->
              <img src="/images/logo.png" alt="logo" width="145" height="45" />
              <!-- </a> -->
            </router-link>
            <a href="javascript:void(0);"
              id="location"
              class="location ml-lg-0"
              @click="showLocationModal"
            >
              Deliver to
              <!-- <span v-if="location !== ''">{{ location }}</span> -->
              <span>Gurugram</span>
            </a>
            <form
              @submit.prevent="searchProducts"
              class="
              header-search
              hs-expanded hs-round
              d-none d-md-flex
              input-wrapper
            "
            >
              <div class="select-box">
                <select
                  id="category"
                  name="category"
                  v-model="selectedCategory"
                >
                  <option value="All Products">All Products</option>
                  <option
                    v-for="category in categories"
                    :key="category._id"
                    :value="category._id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <input
                type="text"
                class="form-control"
                name="search"
                id="search"
                v-model="searchKey"
              />
              <button class="btn btn-search" type="submit">
                <i class="w-icon-search"></i> Search
              </button>
            </form>
          </div>
          <div class="header-right ml-4 menu">
            <ul class="pt-2">
              <li
                class="
                header-call
                d-xs-show d-lg-flex
                align-items-center
                has-submenu
              "
              >
                <a
                  @click.prevent
                  class="
                  user-account
                  ml-lg-0
                  d-flex
                  align-items-center
                  jusify-content-center
                "
                >
                  <div class="ml-8">
                    Hello, {{ userName }}
                    <span>Account & Lists</span>
                  </div>
                  <i class="fa fa-angle-down ml-2"></i>
                </a>
                <ul class="submenu pt-4 pb-4">
                  <li>
                    <a href="" @click.prevent="logoutHandler" class="">{{
                      loginButton
                    }}</a>
                  </li>
                  <li v-if="isLogin">
                    <router-link to="/my-account"
                      >My Account</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
            <router-link to="/trackorder">
              <a class="wishlist label-down link d-xs-show text-center">
                <img src="@/assets/images/track.png" width="20" />
                <span class="wishlist-label d-lg-show">Track Orders</span>
              </a>
            </router-link>
            <a class="wishlist label-down link d-xs-show" href="">
              <img src="@/assets/images/help.png" width="20" />
              <span class="compare-label d-lg-show">Help</span>
            </a>
            <router-link to="/cart">
              <a
                class="wishlist label-down link d-xs-show wishlist-item"
                href=""
              >
                <img src="@/assets/images/cart.png" width="20" />
                <span class="compare-label d-lg-show">Cart</span>
                <span class="cartNo" v-if="itemCount != 0">{{
                  itemCount
                }}</span>
              </a>
            </router-link>
          </div>
                      <a href="javascript:void(0);" class="mobile-menu-toggle w-icon-hamburger"></a>

        </div>
      </div>
      <!-- End of Header Middle -->
      <!-- class="header-bottom sticky-content fix-top sticky-header has-dropdown" -->
      <div
        class="header-bottom sticky-content fix-top sticky-header has-dropdown"
      >
        <div class="container">
          <div class="inner-wrap d-flex">
            <div class="header-left flex-1">
              <div
                class="dropdown category-dropdown"
                :class="showCategory ? 'show-dropdown' : ''"
              >
                <a href="#" role="button">
                  <i class="w-icon-category"></i>
                  <span>Categories</span>
                </a>

                <div class="dropdown-box">
                  <ul class="sidemenu vertical-menu category-menu">
                    <li v-for="ct in categories" :key="ct" class="has-submenu">
                      <a @click="openCategories(ct)" v-if="ct.is_direct_home_menu">
                        {{ ct.name }}
                      </a>
                      <div class="megadrop">
                          <div class="col" v-for="sub in getSubCategory(ct._id)" :key="sub._id">
                          <h3>{{sub.name}}</h3>
                              <ul>
                                  <li v-for="subSub in getSubSubCategory(sub._id)" :key="subSub._id">
                                    <router-link :to="'/category/' + subSub.slug" target="_blank">{{subSub.name}}</router-link>
                                  </li>
                              </ul>
                          </div>
                      </div>
                    </li>
                    <li>
                      <router-link to="/allcategory" >All Category</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <span class="divider d-lg-show"></span>
              <nav class="main-nav">
                <ul class="menu active-underline">
                  <li class="has-submenu">
                    <a href="">Best Offers</a>
                  </li>
                  <li class="">
                    <a href="">Trade Show</a>
                  </li>
                  <!-- <li class="">
                  <a href="">Personal Protective Equipment</a>
                </li> -->
                  <li class="">
                    <a href="">Medicine</a>
                  </li>
                  <li class="">
                    <a href="">Ask Doctor</a>
                  </li>
                  <li class="">
                    <a href="">Covid 19</a>
                  </li>
                  <li class="">
                    <a href="">Offer Price</a>
                  </li>
                  <!-- <li class="">
                    <a href="">Buybulk</a>
                  </li> -->
                  <li class="">
                    <router-link to="/prescription-upload">
                      Prescription Upload
                    </router-link>
                  </li>
                  <li>
                    <a href="">Sell on WMT <i class="fa fa-angle-down"></i></a>
                    <ul>
                      <li>
                        <router-link to="/seller/login">Sell</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>



            <div class="header-right">
              <span class="divider d-lg-show"></span>
              <div class="dropdown">
                <a href="#" class="currency">English - INR</a>
                <div class="dropdown-box">
                  <a href="#USD">English - INR</a>
                  <!-- <a href="#EUR">English - EUR</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <mobile-menu @search="searchFromMobileMenu" />

    <!-- End of Header -->

    <location-modal v-if="showModal" @shut="showLocationModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import LocationModal from '../modal/LocationModal.vue';
import MobileMenu from './MobileMenu.vue';
import CategoryService from '@/services/category-service';
import { useGeolocation } from '@/util/useGeolocation';

const { mapGetters } = createNamespacedHelpers('auth');
const cartMapGetters = createNamespacedHelpers('cart').mapGetters;
const categoryMapGetters = createNamespacedHelpers('category').mapGetters;

export default {
  setup() {
    const { coords } = useGeolocation();
    return { coords };
  },
  props: {
    showCategory: {
      default: false,
    },
  },
  components: {
    LocationModal,
    MobileMenu,
  },
  data(props) {
    return {
      showModal: false,
      productCategories: [
        'All Products',
        'cipla',
        'Dabur',
        'Hansaplast',
        'Mankind',
        'TrueBasics',
        'Accu-check',
        'Abott',
        'Zydus',
      ],
      searchKey: '',
      selectedCategory: 'All Products',
      locatoin: '',
      isCategoryVisible: props.showCategory,
      isFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isLogin', 'userInfo']),
    ...cartMapGetters(['itemCount']),
    ...categoryMapGetters(['categories']),
    loginButton() {
      let btn = this.isLogin ? 'Sign Out' : 'Sign In';
      return btn;
    },
    userName() {
      return this.isLogin ? this.userInfo.username : '';
    },
  },
  mounted() {
    if (!this.isFetched) {
      this.fetchCartItems();
    }
    this.getAllCategory();
  },

  methods: {
    logoutHandler() {
      if (this.isLogin) {
        // console.log(this.userInfo);
        this.$store.dispatch('auth/isLogin', false);
        this.$store.dispatch('resetAll');
        this.$router.push('/');
      } else {
        this.$router.push('/login');
      }
    },
    searchFromMobileMenu(value) {
      this.searchKey = value;
      this.searchProducts();
    },
    searchProducts() {
      if (this.searchKey) {
        this.$store.dispatch('product/productSearchKey', this.searchKey);
        this.$router.push('/product/search/' + this.searchKey);
        this.searchKey = '';
      } else {
        this.$wkToast('please enter product name to search');
      }
    },

    showLocationModal() {
      this.showModal = !this.showModal;
    },
    openCategories(ct) {
      if (ct === 'All Categories') this.$router.push('/allcategory');
      else this.$router.push('/category/' + ct.slug);
    },
    mouseOver() {
      // this.isCategoryVisible = !this.isCategoryVisible;
    },
    fetchCartItems() {
      if (this.isLogin) {
        this.$store.dispatch('cart/loadCartItems', {
          customerId: this.userInfo.id,
          shopping_cart_type_id: 1,
        });
      }
    },
    getSubSubCategory(subCategoryId) {
      return this.categories.filter(
        (ct) => ct.parent_category_id === subCategoryId
      );
    },
    getSubCategory(categoryId) {
      return this.categories.filter(
        (ct) => ct.parent_category_id === categoryId
      );
    },
    getAllCategory() {
      CategoryService.getAllCategory()
        .then((res) => {
          // console.log(res);
          const data = res.data.DATA;
          if (data.length) {
            this.$store.dispatch('category/addCategories', data);
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style scoped>
.dropdown > a {
  line-height: 1.1;
  padding: 10px 0;
}

.box-height {
  max-height: 350px;
  overflow: auto;
}

.box-height li a {
  cursor: pointer;
}
</style>
