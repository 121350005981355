<template>
  <!-- Start of Main-->
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-9 mb-4">
            <div class="bg-white thanks">
              <h3>CONGRATULATION!</h3>
              <h4>YOUR ORDER HAS BEEN PLACED</h4>
              <hr />
              <h5>Order ID: #{{ orderId }}</h5>
              <hr />
              <p><router-link tag="a" to="/my-account">My Dashboard</router-link></p>
              <p><router-link tag="a" to="/">Continue Shopping</router-link></p>
              <hr />
              <p>
                You will receive an email with tracking information once your
                goods have shipped.
              </p>
            </div>
          </div>

          <!-- right panel -->
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12 col-xs-6 mb-4">
                <div class="bg-white cart-panel-right-sponsored">
                  <h2>Still looking for these essentials?</h2>
                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="images/product/5.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Fast&Up 100%…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 1999.00</p>
                      <button class="addtocart-btn">Add to Cart</button>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="images/product/6.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Horlicks Health &…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 412.00</p>
                      <button class="addtocart-btn">Add to Cart</button>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="images/product/6.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Horlicks Health &…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 412.00</p>
                      <a href="" class="buying">See all buying options</a>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row">
                    <div class="col-md-4">
                      <img src="images/product/7.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">MuscleBlaze Biozyme…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 4499.00</p>
                      <a href="" class="buying">See all buying options</a>
                    </div>
                  </div>
                  <!-- item end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('order');
export default {
  computed: {
    ...mapGetters(['orderId']),
  },
};
</script>

<style scoped></style>
