export default {
  namespaced: true,
  state: {
    addresses: [],
    address: {
      billing: {},
      shipping: {},
    },
  },
  getters: {
    address(state) {
      return state.address;
    },
    addresses(state) {
      return state.addresses;
    },
    isAddressSelected(state) {
      return state.address.shipping.name ? true : false;
    },
    getBillingAddress(state) {
      const shipping = state.address.billing;
      return `${shipping.name || ''}
               ${shipping.address || ''}
                ${shipping.contact || ''}
                ${shipping.email || ''}
                 ${shipping.city || ''}
                  ${shipping.state || ''}
                ${shipping.country || ''}`;
    },
    getShippingAddress(state) {
      const shipping = state.address.shipping;
      return `${shipping.name || ''}
               ${shipping.address || ''}
                ${shipping.contact || ''}
                ${shipping.email || ''}
                 ${shipping.city || ''}
                  ${shipping.state || ''}
                ${shipping.country || ''}`;
    },
  },
  mutations: {
    addShippingAddress(state, payload) {
      state.address.shipping = payload;
    },
    addBillingAddress(state, payload) {
      state.address.billing = payload;
    },
    addAddress(state, data) {
      state.addresses.push(data);
    },
    deleteAddress(state, id) {
      debugger;
      const index = state.addresses.findIndex((addr) => addr.id === id);
      if (index > -1) {
        state.addresses.splice(index, 1);
      }
    },
    updateAddress(state, payload) {
      const index = state.addresses.findIndex((a) => a.id === payload.id);
      if (index > -1) {
        state.addresses[index] = payload;
      }
    },
    setDefaultAddress(state, id) {
      state.addresses.forEach((element) => {
        // debugger;
        element.default = element.id === id;
      });
    },
  },
  actions: {
    addShippingAddress({ commit }, payload) {
      commit('addShippingAddress', payload);
    },
    addBillingAddress({ commit }, payload) {
      commit('addBillingAddress', payload);
    },
    addAddress({ commit }, payload) {
      commit('addAddress', payload);
    },
    deleteAddress({ commit }, id) {
      commit('deleteAddress', id);
    },
    updateAddress({ commit }, payload) {
      commit('updateAddress', payload);
    },
    setDefaultAddress({ commit }, id) {
      commit('setDefaultAddress', id);
    },
  },
};
