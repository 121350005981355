<template>
  <main class="main">
    <div class="container">
      <div v-if="loading" class="loader-container ">
        <spinner />
      </div>
      <div v-else-if="isError" class="loader-container">
        <h5>There is no orders to show</h5>
      </div>

      <div v-else class="cart">
        <div class="row">
          <!-- orders -->
          <div class="col-md-9 mb-4">
            <div class="bg-white order-panel">
              <h2>Your Orders</h2>
              <order-card
                v-for="order in ordersData"
                :key="order._id"
                :order="order"
              />
            </div>
          </div>

          <!-- right panel -->
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12 col-xs-6 mb-4">
                <div class="bg-white cart-panel-right-sponsored">
                  <h2>Still looking for these essentials?</h2>
                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="/images/product/5.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Fast&Up 100%…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 1999.00</p>
                      <button class="addtocart-btn">Add to Cart</button>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="/images/product/6.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Horlicks Health &…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 412.00</p>
                      <button class="addtocart-btn">Add to Cart</button>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <img src="/images/product/6.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">Horlicks Health &…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 412.00</p>
                      <a href="" class="buying">See all buying options</a>
                    </div>
                  </div>
                  <!-- item end -->

                  <!-- item -->
                  <div class="row">
                    <div class="col-md-4">
                      <img src="/images/product/7.jpg" class="product-img" />
                    </div>
                    <div class="col-md-8">
                      <p class="product-name">MuscleBlaze Biozyme…</p>
                      <p class="rating">
                        <i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star yellow"></i
                        ><i class="fa fa-star gray"></i>
                      </p>
                      <p class="price">&#8377; 4499.00</p>
                      <a href="" class="buying">See all buying options</a>
                    </div>
                  </div>
                  <!-- item end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import OrderCard from '@/components/customer/order/OrderCard.vue';
import Spinner from '@/components/Spinner.vue';
import OrderService from '@/services/order-service';
const { mapGetters } = createNamespacedHelpers('order');
const addressMapGetters = createNamespacedHelpers('address').mapGetters;
const authMapGetters = createNamespacedHelpers('auth').mapGetters;
export default {
  components: { OrderCard, Spinner },
  data() {
    return {
      loading: false,
      isError: false,
      ordersData: [],
    };
  },
  computed: {
    ...mapGetters(['orders']),
    ...addressMapGetters(['getShippingAddress', 'getBillingAddress']),
    ...authMapGetters(['userInfo']),
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.loading = true;
      OrderService.getOrders(this.userInfo.id)
        .then((res) => {
          this.loading = false;
          this.ordersData = res.data.DATA;
          if (this.ordersData.length == 0) {
            this.isError = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.loader-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style></style>
