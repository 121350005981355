<template>
  <div class="col-md-2 showbutton" v-if="url">
    <img :src="url" class="album-img" style="position:relative;" />
    <img
      v-if="showDelete"
      src="@/assets/images/cancelrx.png"
      @click="removeUrlFromUrls(url)"
      width="20"
      height="20"
      class="delete-button"
    />
  </div>
</template>

<script>
export default {
  props: ['url', 'index', 'showDelete'],
  methods: {
    removeUrlFromUrls(url) {
      this.$emit('delete-file', url);
    },
  },
};
</script>

<style scoped>
.showbutton {
  position: relative;
}
.delete-button {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -6px;
  cursor: pointer;
}
</style>
