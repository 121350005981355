import axios from 'axios';

const API_URL = `${process.env.VUE_APP_CART_SERVICE_API}api/`;//'https://quiet-reaches-15229.herokuapp.com/api/';

class CartService {
  addCart(payload) {
    var data = JSON.stringify(payload);

    var config = {
      method: 'post',
      url:
        `${API_URL}shoppingcart/addtocart`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    return axios(config);
  }
  getWishListItems(customerId, shopping_cart_type_id) {
    return axios.get(API_URL + 'shoppingcart', {
      params: {
        customerid: customerId,
        shopping_cart_type_id,
      },
    });
  }
  getCartItems(customerId, shopping_cart_type_id) {
    return axios.get(API_URL + 'shoppingcart', {
      params: {
        customerid: customerId,
        shopping_cart_type_id,
      },
    });
  }
  deleteItem(id, shopping_cart_type_id) {
    return axios.delete(API_URL + 'shoppingcart/' + id, {
      shopping_cart_type_id,
    });
  }
  saveItem(item, customerId, cartType) {
    return axios.post(API_URL + 'shoppingcart/addtocart', {
      product_id: item.product_id,
      customer_id: customerId,
      quantity: item.quantity,
      shopping_cart_type_id: cartType,
    });
  }
  updateItem(id, quantity, shopping_cart_type_id) {
    return axios.put(API_URL + 'shoppingcart/' + id, {
      quantity,
      shopping_cart_type_id,
    });
  }
}

export default new CartService();
