<template>
  <div
    v-if="loading"
    class="d-flex justify-content-center align-items-center"
    style="height: 200px"
  >
    <spinner />
  </div>
  <div
    v-else-if="!products.length"
    class="d-flex justify-content-center align-items-center"
    style="height: 200px"
  >
    <h3>Products Not Found</h3>
  </div>
  <main v-else class="main main-content">
    <nav class="toolbox bg-white">
      <div class="toolbox-right">
        <div class="toolbox-item toolbox-sort select-box text-dark">
          <label>Sort By :</label>
          <select name="orderby" class="form-control">
            <option value="default" selected="selected">Default sorting</option>
            <option value="popularity">Sort by popularity</option>
            <option value="rating">Sort by average rating</option>
            <option value="date">Sort by latest</option>
            <option value="price-low">Sort by pric: low to high</option>
            <option value="price-high">Sort by price: high to low</option>
          </select>
        </div>
      </div>
    </nav>
    <div class="container">
      <div class="cart">
        <div class="row">
          <!-- filters -->
          <div class="col-md-2">
            <div class="sidebar-content scrollable pt-0">
              <div class="filter-actions">
                <label>Filter :</label>
                <a href="javascript:window.location.href=window.location.href" class="btn btn-dark btn-link filter-clean"
                  >Clean All</a
                >
              </div>

              <!-- Start of Collapsible Widget -->
              <div class="widget widget-collapsible">
                <h3 class="widget-title">
                  <span>Price</span><span class="toggle-btn"></span>
                </h3>
                <div class="widget-body">
                  <ul class="filter-items search-ul">
                    <li><a href="#">Under 100</a></li>
                    <li><a href="#">100 - 200</a></li>
                    <li><a href="#">200 - 500</a></li>
                    <li><a href="#">500 - 1000</a></li>
                    <li><a href="#">1000+</a></li>
                  </ul>
                  <form class="price-range">
                    <input
                      type="number"
                      name="min_price"
                      class="min_price text-center"
                      placeholder="min"
                    />
                    <span class="delimiter">-</span>
                    <input
                      type="number"
                      name="max_price"
                      class="max_price text-center"
                      placeholder="max"
                    />
                    <a href="#" class="btn btn-primary btn-rounded">Go</a>
                  </form>
                </div>
              </div>

              <div class="widget widget-collapsible">
                <h3 class="widget-title">
                  <span>Brand</span><span class="toggle-btn"></span>
                </h3>
                <ul class="widget-body filter-items item-check mt-1">
                  <li v-for="(brand, index) in brands" :key="index" :class="{ active: filters.brand.includes(brand) }">
                    <a href="javascript:void(0)" @click="toggleFilter('brand', brand)" style="text-transform: capitalize">
                      {{ brand }}
                    </a>
                  </li>
                </ul>
              </div>

              <!--<template v-if="Object.keys(mandatory).length > 0">
                <div
                  v-for="(md_data, md_id) in mandatory"
                  :key="md_id"
                  class="widget widget-collapsible"
                >
                  <h3 class="widget-title">
                    <span>{{ md_data.name }}</span
                    ><span class="toggle-btn"></span>
                  </h3>
                  <ul class="widget-body filter-items item-check mt-1">
                    <li
                      v-for="(md, index) in md_data.data.filter(v => v)"
                      :key="index"
                      :class="{ active: mandatory_filters[md_id].includes(md) }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="toggleMdFilter(md_id, md)"
                        style="text-transform: capitalize"
                        >{{ md }}</a
                      >
                    </li>
                  </ul>
                </div>
              </template>-->
            </div>
          </div>

          <div class="col-md-10">
            <div class="row">
              <product-card
                v-for="product in filteredProducts"
                :key="product.id"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import _ from "lodash";
import Spinner from "@/components/Spinner.vue";
import ProductService from "@/services/product-service";
import { createNamespacedHelpers } from "vuex";
import ProductCard from "../../components/customer/products/ProductCard.vue";
const { mapGetters } = createNamespacedHelpers("category");
export default {
  components: {
    Spinner,
    ProductCard,
  },
  props: ["categoryName"],
  data() {
    return {
      products: [],
      loading: false,
      brands: [],
      prices: [],
      mandatory: {},
      mandatory_filters: {},
      filters: { brand: [], price: "" },
    };
  },
  computed: {
    categoryId() {
      const cat = this.categories.find(
        (ct) => ct.slug === this.$route.params.category
      );
      return cat ? cat._id : null;
    },

    ...mapGetters(["categories"]),
    filteredProducts() {
      return this.products.filter((item) => {
        return (
          (this.filters.brand.length === 0 ||
            _.intersection(
              _.map(item.Seller_Specific_Detail, "brand_name"),
              this.filters.brand
            ).length > 0) 
          // ||
          // this.checkMdHas(
          //   _.has(item, "mandatoryFields") ? item.mandatoryFields : []
          // )
        );
        //  &&
        // (this.colors.length === 0 || this.colors.includes(item.color)) &&
        // (this.sizes.length === 0 || this.sizes.includes(item.size))
      });
      // .sort((a, b) => {
      //   return a[this.sortBy].toString().localeCompare(b[this.sortBy].toString())
      // })
    },
  },
  mounted() {
    if (this.categoryId) this.getProducts();
  },

  methods: {
    async getProducts() {
      try {
        this.loading = true;
        const res = await ProductService.getAllProductByCategory(
          this.categoryId
        );
        if (res.data.DATA && res.data.DATA.length) {
          this.products = res.data.DATA;
          this.getFilters(res.data.DATA);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    /**
     * generate filter attributes
     */
    getFilters(products) {
      var brands = [];
      var prices = [];
      for (const product of products) {
        brands = _.concat(
          brands,
          _.map(product.Seller_Specific_Detail, "brand_name")
        );
        prices = _.concat(
          prices,
          _.map(product.Seller_Specific_Detail, "default_price")
        );
        if (
          _.has(product, "mandatoryFields") &&
          product.mandatoryFields.length > 0
        ) {
          for (const md of product.mandatoryFields) {
            if (_.has(this.mandatory, md.field_id)) {
              this.mandatory[md.field_id].data.push(md.value);
            } else {
              this.mandatory[md.field_id] = {
                name: md.field,
                data: [md.value],
              };
              this.mandatory_filters[md.field_id] = [];
            }
          }
        }
      }
      this.brands = _.uniq(brands.filter(v=>v));
      this.prices = _.uniq(prices.filter(v=>v));
    },

    /**
     * toggle filter for checkbox
     */
    toggleFilter(key, value) {
      if (this.filters[key].includes(value)) {
        this.filters[key].splice(
          this.filters[key].findIndex((x) => x === value),
          1
        );
      } else {
        this.filters[key].push(value);
      }
    },
    /**
     * mandatory toggle filter for checkbox
     */
    toggleMdFilter(key, value) {
      if (this.mandatory_filters[key].includes(value)) {
        this.mandatory_filters[key].splice(
          this.mandatory_filters[key].findIndex((x) => x === value),
          1
        );
      } else {
        this.mandatory_filters[key].push(value);
      }
    },

    checkMdHas(item) {
      if (item.length > 0) {
        var conditions = [];

        for (const mdf of Object.keys(this.mandatory_filters)) {
          if (this.mandatory_filters[mdf].length > 0) {
            var obj = _.find(item, ["field_id", mdf]);
            conditions.push(
              `this.mandatory_filters['${mdf}'].includes('${obj.value}')`
            );
          }
        }
        return conditions.length === 0 || eval(conditions.join(" || "));
      }
      return false;
    },
  },
};
</script>
