<template>
  <home-page />
</template>

<script>
import HomePage from '@/components/customer/home/HomePage.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('auth');

export default {
  components: { HomePage },
  computed: {
    ...mapGetters(['isAdmin', 'isVendor', 'isLogin']),
  },
  created() {
    if (this.isLogin && (this.isVendor || this.isAdmin)) {
      //this.$router.push('/productcatalog');
    }
  },
};
</script>

<style></style>
