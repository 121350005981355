<template>
  <div id="other-price" class="other-price-box">
    <div class="other-price">
      <button id="closeprice" class="closeprice" @click="$emit('close')">
        <i class="fa fa-times"></i>
      </button>
      <table cellpadding="0" cellspacing="0">
        <tr>
          <th>#</th>
          <th width="40%">Seller Name</th>
          <th width="20%">Price</th>
          <th width="40%">
            Expected Delivery
          </th>
        </tr>
        <tr v-for="(seller, index) in sellersInfo" :key="index">
          <td>
            <input
              type="radio"
              name="seller"
              :checked="index == selectedIndex"
              @click="$emit('selectedSeller', index)"
            />
          </td>
          <td>
            <p>
              {{ seller.brand_name }}
            </p>
          </td>
          <td>
            <p>
              {{ seller.default_price }}
            </p>
          </td>
          <td>
            <p>
              {{ seller.delivery || '2 Days' }}
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product', 'selectedIndex'],
  data() {
    return {
      selectedRadio: 0,
    };
  },
  computed: {
    sellersInfo() {
      return this.product.Seller_Specific_Detail;
    },
  },
};
</script>

<style scpoed>
.other-price-box {
  display: block;
}
.other-price-box p {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  margin: 0;
}
</style>
