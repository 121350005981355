<template>
  <div class="intro-wrapper">
    <div class="row">
      <div class="col-md-8 mb-4">
        <carousel :items-to-show="1" :wrapAround="true" :autoplay="3000" :transition="400">
          <slide v-for="slide in 4" :key="slide">
            <div class="">
              <img :src="'/images/slide/slide-' + slide + '.jpg'" alt="" />
            </div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>

      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12 col-xs-6 mb-4">
            <div class="category-banner banner banner-fixed br-sm">
              <figure>
                <img
                  src="/images/1-1.jpg"
                  alt=""
                  width=""
                  height=""
                  style="background-color: #605959;"
                />
              </figure>
              <div class="banner-content">
                <h3 class="banner-title text-white text-capitalize ls-25">
                  GET UPTO 30% OFF<br />ON MEDICAL EQUIPMENTS
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-xs-6 mb-4">
            <div class="category-banner banner banner-fixed br-sm">
              <figure>
                <img
                  src="/images/1-2.jpg"
                  alt=""
                  width=""
                  height=""
                  style="background-color: #eff5f5;"
                />
              </figure>
              <div class="banner-content">
                <h3 class="banner-title text-white text-capitalize ls-25 mb-3">
                  GET 10% OFF ON<br />EVERY MEDICINE
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>
