<template>
  <div class="cart">
    <div class="row">
      <product-card
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import ProductCard from './ProductCard.vue';
export default {
  props: ['products'],
  components: { ProductCard },
};
</script>

<style></style>
