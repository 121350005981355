export default {
  namespaced: true,
  state: {
    companyOverviews: {},
    tradeCapabilities: {},
    productionCapacities: {},
    qualityControls: {},
    rndCapacities: {},
    factoryInspectionReports: {},
  },
  getters: {
    companyOverview(state) {
      return state.companyOverviews;
    },
    tradeCapabilities(state) {
      return state.tradeCapabilities;
    },
    productionCapacities(state) {
      return state.productionCapacities;
    },
    qualityControls(state) {
      return state.qualityControls;
    },
    rndCapacities(state) {
      return state.rndCapacities;
    },
    factoryInspectionReports(state) {
      return state.factoryInspectionReports;
    },
  },
  mutations: {
    resetAll(state) {
      state.companyOverviews = {};
      state.tradeCapabilities = {};
      state.productionCapacities = {};
      state.qualityControls = {};
      state.rndCapacities = {};
      state.factoryInspectionReports = {};
    },
    addCompanyOverview(state, co) {
      state.companyOverviews[co.customerid] = co;
    },
    addTradeCapability(state, co) {
      state.tradeCapabilities[co.customerid] = co;
    },
    addProductionCapacity(state, co) {
      state.productionCapacities[co.customerid] = co;
    },
    addQualityControl(state, co) {
      state.qualityControls[co.customerid] = co;
    },
    addRndCapacity(state, co) {
      state.rndCapacities[co.customerid] = co;
    },
    addFactoryInspectionReport(state, co) {
      state.factoryInspectionReports[co.customerid] = co;
    },
  },
  actions: {
    addCompanyOverview({ commit }, co) {
      commit('addCompanyOverview', co);
    },
    addTradeCapability({ commit }, co) {
      commit('addTradeCapability', co);
    },
    addProductionCapacity({ commit }, co) {
      commit('addProductionCapacity', co);
    },
    addQualityControl({ commit }, co) {
      commit('addQualityControl', co);
    },
    addRndCapacity({ commit }, co) {
      commit('addRndCapacity', co);
    },
    addFactoryInspectionReport({ commit }, co) {
      commit('addFactoryInspectionReport', co);
    },
  },
};
