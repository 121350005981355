<template>
  <div style="height:100vh" v-if="loading" class="main">
    <div class="loading-container-full">
      <spinner />
    </div>
  </div>
  <product-info-form :category="category" :name="name" :productId="productId" />
</template>

<script>
import ProductInfoForm from '@/components/seller/products/ProductInfoForm.vue';
import CategoryService from '@/services/category-service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const { mapGetters } = createNamespacedHelpers('category');

export default {
  components: { ProductInfoForm, Spinner },
  props: ['name', 'category_ids'],
  data() {
    return {
      category: null,
      loading: false,
      productId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters(['categoryAllChildren']),
    isFound() {
      return this.category ? true : false;
    },
  },

  mounted() {
    // this.getAllCategory();
  },
  methods: {
    async getAllCategory() {
      try {
        this.loading = true;
        let res = await CategoryService.getCategoryAllChildren();
        const data = res.data.DATA;
        if (data.length) {
          this.$store.dispatch('category/addCategoryAllChildren', data);
        }
      } catch (err) {
        console.log(err);
      }
      this.findUpdateCategory();
      this.loading = false;
    },
    findUpdateCategory() {
      debugger;
      const t = this.category_ids[0];
      this.category = this.categoryAllChildren.find(
        (cat) => cat._id === this.category_ids[0]
      );
      console.log('find', this.category);
    },
  },
};
</script>

<style scoped>
loading-container-scoped {
  width: 100%;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
