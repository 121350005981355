<template>
  <main class="main bg-white">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-5 mb-4 center-div">
            <form v-if="!isEditting">
              <div class="bg-white cart-panel">
                <div class="breadcrumb-login">
                  <router-link to="/my-account"
                    >Your Account</router-link
                  >
                  > Login & Security
                </div>
                <div class="address-heading">Login & Security</div>
                <div class="login-box">
                  <div class="security">
                    <div class="row">
                      <div class="col-md-9">
                        <p><b>Name:</b></p>
                        <p>Mohit Sharma</p>
                      </div>
                      <div class="col-md-3">
                        <a @click.prevent="showEditting(1)" class="edit"
                          >Edit</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="security">
                    <div class="row">
                      <div class="col-md-9">
                        <p><b>E-mail:</b></p>
                        <p>mohit.digiad@gmail.com</p>
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                  </div>
                  <div class="security">
                    <div class="row">
                      <div class="col-md-9">
                        <p><b>Mobile/Phone Number:</b></p>
                        <p>+917651925690</p>
                      </div>
                      <div class="col-md-3">
                        <a @click.prevent="showEditting(2)" class="edit"
                          >Edit</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="security last">
                    <div class="row">
                      <div class="col-md-9">
                        <p><b>Password:</b></p>
                        <p>********</p>
                      </div>
                      <div class="col-md-3">
                        <a @click.prevent="showEditting(3)" class="edit"
                          >Edit</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div v-else>
              <change-name v-if="edittingIndex == 1" />
              <change-mobile v-else-if="edittingIndex == 2" />
              <change-password v-else-if="edittingIndex == 3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ChangeMobile from '../../components/customer/profile/ChangeMobile.vue';
import ChangeName from '../../components/customer/profile/ChangeName.vue';
import ChangePassword from '../../components/customer/profile/ChangePassword.vue';
export default {
  components: { ChangeName, ChangeMobile, ChangePassword },

  data() {
    return {
      isEditting: false,
      edittingIndex: 0,
    };
  },

  methods: {
    showEditting(index) {
      this.edittingIndex = index;
      this.isEditting = true;
    },
  },
};
</script>

<style></style>
