import axios from 'axios';

// const API_URL = 'https://limitless-chamber-45831.herokuapp.com/api/';
const API_URL = `${process.env.VUE_APP_SELLER_SERVICE_API}api/`;

class ProfileService {
  // Company Overview Services
  createCompanyOverview(payload) {
    return axios.post(API_URL + 'companyoverview/create', payload);
  }
  updateCompanyOverview(payload, companyOverviewId) {
    return axios.put(API_URL + 'companyoverview/' + companyOverviewId, payload);
  }
  getCompanyOverview(sellerId) {
    return axios.get(API_URL + 'companyoverview/search/' + sellerId);
  }

  // Trade Capabilities Services
  createTradeCapability(payload) {
    return axios.post(API_URL + 'tradecapability/create', payload);
  }
  updateTradeCapability(payload, tradeId) {
    return axios.put(API_URL + 'tradecapability/' + tradeId, payload);
  }
  getTradeCapability(sellerId) {
    return axios.get(API_URL + 'tradecapability/search/' + sellerId);
  }

  // Production Capacities Services
  createProductionCapacity(payload) {
    return axios.post(API_URL + 'productioncapacity/create', payload);
  }
  updateProductionCapacity(payload, productionId) {
    return axios.put(API_URL + 'productioncapacity/' + productionId, payload);
  }
  getProductionCapacity(sellerId) {
    return axios.get(API_URL + 'productioncapacity/search/' + sellerId);
  }

  // Quality Control Services
  createQualityControl(payload) {
    return axios.post(API_URL + 'qualitycontrol/create', payload);
  }
  updateQualityControl(payload, qualityId) {
    return axios.put(API_URL + 'qualitycontrol/' + qualityId, payload);
  }
  getQualityControl(sellerId) {
    return axios.get(API_URL + 'qualitycontrol/search/' + sellerId);
  }

  // Rnd Capacities Services
  createRndCapacity(payload) {
    return axios.post(API_URL + 'rndcapacity/create', payload);
  }
  updateRndCapacity(payload, rndId) {
    return axios.put(API_URL + 'rndcapacity/' + rndId, payload);
  }
  getRndCapacity(sellerId) {
    return axios.get(API_URL + 'rndcapacity/search/' + sellerId);
  }

  // Factory Inspection Services

  createFactoryInspection(data) {
    return axios.post(API_URL + 'factoryinspection/create', data);
  }
  getFactoryInspecctionBySellerId(sellerId) {
    return axios.get(API_URL + 'factoryinspection/search/' + sellerId);
  }
  updateFactoryInspection(data, factoryInspectionId) {
    return axios.put(
      API_URL + 'factoryinspection/' + factoryInspectionId,
      data
    );
  }
}

export default new ProfileService();
