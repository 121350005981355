<template>
  <main class="main">

    <div v-if="loading" class="loader-container">
      <spinner />
    </div>

    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-12 mb-4">
            <carousel :items-to-show="15" :wrapAround="true" :autoplay="3000" :transition="400">
              <slide v-for="(link, index) in getCategory" :key="link._id" :class="{ active: active_el == index + 1 }">
                <div class="categoryLinks">
                  <a href="javascript:void(0)" @click="activate(index + 1)">
                    <img :src="link.imgurl && link.imgurl.filter(v => v).length ? link.imgurl[0] : '/images/no-image.jpg'"/>
                    <span>{{link.name}}</span>
                  </a>
                </div>
              </slide>
              <template #addons>
                <navigation/>
              </template>
            </carousel>
          </div>

          <div class="col-md-12 mb-4 ">
            <div v-for="(ct, index) in getCategory" :key="ct._id" :ref="`div${index + 1}`" class="section" style="padding:20px 0;">
              <router-link :to="'/category/' + ct.slug">
                <h3>{{ ct.name }}</h3>
              </router-link>
              <div class="row">
                <div class="col-md-12 all-cat">
                  <p v-if="!getSubCategory(ct._id).length">
                    There are no subcategories in this category
                  </p>
                  <ul v-else>
                    <li v-for="sub in getSubCategory(ct._id)" :key="sub._id">
                      <!--<router-link :to="'/category/' + sub.slug">-->{{
                        sub.name
                      }}<!--</router-link>-->
                        <ul>
                            <li v-for="subSub in getSubSubCategory(sub._id)" :key="subSub._id">
                              <router-link :to="'/category/' + subSub.slug" target="_blank">{{subSub.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { createNamespacedHelpers } from "vuex";
import Spinner from "@/components/Spinner.vue";
import CategoryService from "@/services/category-service";
const { mapGetters } = createNamespacedHelpers("category");
export default {
  components: { 
    Spinner,    
    Carousel,
    Slide,
    Navigation, 
  },
  data() {
    return {
      loadin: false,
      active_el: 1,
    };
  },
  computed: {
    ...mapGetters(["categories"]),
    getCategory() {
      return this.categories.filter((ct) => ct.is_main_category);
    },
  },
  mounted() {
    this.getAllCategory();
  },
  methods: {
    activate(value) {
      this.active_el = value;
      var element = this.$refs[`div${value}`];
      var top = element.offsetTop;

      scroll({
        top: top,
        behavior: "smooth"
      });

      // window.scrollTo(0, top);
    },
    getSubCategory(categoryId) {
      return this.categories.filter(
        (ct) => ct.parent_category_id === categoryId
      );
    },
    getSubSubCategory(subCategoryId) {
      return this.categories.filter(
        (ct) => ct.parent_category_id === subCategoryId
      );
    },
    getAllCategory() {
      // if (!this.categories || !this.categories.length) {
      this.loading = true;
      CategoryService.getAllCategory()
        .then((res) => {
          this.loading = false;
          const data = res.data.DATA;
          if (data.length) {
            this.$store.dispatch("category/addCategories", data);
          }
          // this.loading = false;
        })
        .catch((err) => (this.loading = false));
      // }
    },
  },
};
</script>

<style scoped>
.nav-all {
  width: 100%;
  background-color: #fff;
  text-align: left;
  position: sticky;
  top: 50px;
  overflow: hidden;
  z-index: 99;
}

.nav li {
  display: block;
  width: 16.66%;
  border: 1px solid #eee;
  float: left;
  height: 50px;
  line-height: 50px;
}

.nav a {
  color: #333 !important;
  font-weight: 600;
  position: relative;
  font-size: 12px;
  z-index: 2;
  display: inline-block;
  cursor: pointer;
  width: 60%;
  margin-left: 15%;
  vertical-align: middle;
  line-height: normal;
}
.nav a i {
  font-size: 20px;
  color: #00aeef;
  margin-right: 5px;
}
.nav a:hover {
  color: black;
}

.nav li.active {
  border: 2px solid #00aeef;
  color: black;
}

.section {
  width: 100%;
  background: #fff;
  padding: 20px 25px;
}
.section p {
  margin-bottom: 10px;
}
.section ul {
  padding: 0;
  margin: 0;
}
.section ul li {
  list-style: none;
  width: 48%;
  display: inline-block;
}
.section ul li a {
  font-size: 12px;
  color: #333;
}
.section ul li a:hover {
  color: #00aeef;
}
</style>
