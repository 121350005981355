<template>
  <div id="askseller" class="seller-box" style="display:block">
    <div class="seller">
      <h1>
        Ask to Seller<button
          id="closeseller"
          class="closeseller"
          @click="$emit('shut')"
        >
          <i class="fa fa-times"></i>
        </button>
      </h1>
      <p>Contact to seller for bulk order</p>
      <form class="row" @submit.prevent="contactSeller">
        <div class="col-md-6">
          <input
            type="text"
            name="name"
            maxlength="100"
            class="form-control"
            placeholder="Full Name"
            title="Alpha Numeric With Space"
            v-model="name"
            pattern="[a-zA-Z\d-._\s]+"
            required
          />
        </div>
        <div class="col-md-6">
          <div class=" d-flex align-items-center form-control mask-code">
            <span>+91</span>
            <input
              type="tel"
              name="contact"
              class=""
              placeholder="Contact No."
              v-model="mobile"
              :pattern="regMobile.pattern"
              :oninput="regMobile.oninput"
              :title="regMobile.title"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <input
            type="email"
            name="email"
            class="form-control"
            placeholder="Email"
            :pattern="regEmail"
            required
          />
        </div>
        <div class="col-md-6">
          <input
            type="number"
            name="quantity"
            class="form-control"
            placeholder="Quantity"
            v-model="quantity"
            min="1"
            max="10000000"
            required
          />
        </div>
        <div class="col-md-12">
          <textarea
            name="description"
            class="form-control"
            placeholder="Your Requirement"
            v-model="description"
            required
          ></textarea>
        </div>
        <div class="col-md-12">
          <button type="submit" class="submit">SUBMIT</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  alphaNumericWithSpace,
  mobileField,
  emailPattern,
  quantityField,
} from '@/util/regexUtil';
export default {
  data() {
    return {
      name: null,
      mobile: null,
      email: null,
      quantity: null,
      description: null,
      regName: alphaNumericWithSpace,
      regMobile: mobileField,
      regEmails: emailPattern,
      regQuantity: quantityField,
    };
  },
  methods: {
    contactSeller() {
      this.$emit('shut');
    },
  },
};
</script>

<style scoped>
.mask-code {
  padding: 5px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
}
.mask-code input {
  background-color: transparent;
  border: 0px solid;
  display: table-cell;
  width: 90%;
}

.mask-code input:focus {
  box-shadow: 0px 0px 0px 0px transparent;
  outline: 0;
}

input:focus {
  -webkit-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  -moz-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  outline: 0;
}
</style>
