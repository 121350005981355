<template>
  <div id="raiseorderissue" class="seller-box">
    <div class="seller">
      <h1>
        Raise Order Issue<button
          id="closeraiseorderissue"
          class="closeseller"
          @click="$emit('shut')"
        >
          <i class="fa fa-times"></i>
        </button>
      </h1>
      <p>Raise a request for order issue</p>
      <div class="row">
        <div class="col-md-12">
          <select name="" class="form-control" placeholder="Full Name">
            <option value="">Select Issue</option>
            <option value="">Worng Product</option>
          </select>
        </div>
        <div class="col-md-12">
          <textarea
            name=""
            class="form-control"
            placeholder="Description"
          ></textarea>
        </div>
        <div class="col-md-12">
          <button type="submit" class="submit" @click="$emit('shut')">
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style .scoped>
.seller-box {
  display: block;
}
</style>
