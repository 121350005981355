<template>
  <main class="main bg-white">
    <div class="full-wrapper">
      <h4>Selling Applications</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="right-panel">
            <div class="search">
              <form>
                <input
                  type="text"
                  name=""
                  placeholder="Search by Product Name"
                  class="search-control"
                />
                <button name=""><i class="fa fa-search"></i></button>
              </form>
            </div>
            <div class="appication-data">
              <table cellpadding="0" cellspacing="0" class="data-table">
                <tr>
                  <th>
                    Application Name
                    <span>Case ID</span>
                  </th>
                  <th>Application Type</th>
                  <th>Status</th>
                  <th>Update Status</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <!-- <select name="" class="update-control">
                      <option value="">Pending</option>
                      <option value="">Approved</option>
                      <option value="">Reject</option>
                    </select> -->
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
