<template>
  <div>
    <main class="main mb-10 pb-1">
      <breadcrumb-nav />

      <div class="page-content">
        <div class="container">
          <div v-if="loading" class="loader-container">
            <spinner />
          </div>

          <div v-else class="">
            <div class="bg-white p-10">
              <div class="product product-single row">
                <div class="col-md-4 mb-6">
                  <product-image-zoom :images="images" />
                </div>

                <div class="col-md-5 mb-4 mb-md-6">
                  <product-description
                    :product="product"
                    :index="selectedIndex"
                    @showPrice="onShowPrice"
                    @showBulk="onShowBulk"
                    @changeVariant="onChangeVariant"
                    @updateDefaultVariant="onDefaultVariant"
                  />
                </div>

                <div class="col-md-3 mb-4 mb-md-6">
                  <product-add-cart
                    :product="mProduct"
                    :index="selectedIndex"
                  />
                </div>
              </div>
              <div class="tab tab-nav-boxed tab-nav-underline product-tabs">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item border-0">
                    <div class="productdetail">Product Details</div>
                  </li>
                </ul>
                <product-details :product="product" :index="selectedIndex" />
                <h4 class="productdetail mt-4">
                  FROM THE MANUFACTURER
                </h4>
                <div class="text-center">
                  <img class="mb-2 w-40" :src="product.imgurl[0]" />
                </div>
              </div>
              <seller-profile :product="product" :index="selectedIndex" />
              <related-products />
            </div>
          </div>
        </div>
      </div>
    </main>
    <product-other-price
      v-if="showPrice && renderedComponent"
      :product="product"
      :selectedIndex="selectedIndex"
      @close="showPrice = !showPrice"
      @selectedSeller="onSellerSelected"
    />

    <product-bulk-option
      :product="product"
      :tierPrice="tierPrice"
      :index="selectedIndex"
      v-if="showBulk"
      @close="showBulk = !showBulk"
      @askSeller="showAskSeller = true"
    />

    <ask-seller v-if="showAskSeller" @shut="showAskSeller = false" />
  </div>
</template>
<script>
import BreadcrumbNav from "@/components/BreadcrumbNav.vue";
import ProductDescription from "@/components/customer/products/ProductDescription.vue";
import ProductDetails from "@/components/customer/products/ProductDetails.vue";
import ProductAddCart from "@/components/customer/products/ProductAddCart.vue";
import ProductImageZoom from "@/components/customer/products/ProductImageZoom.vue";
//import RelatedProducts from "@/components/customer/products/RelatedProducts.vue";
import UserService from "@/services/user.service";
import Spinner from "@/components/Spinner.vue";
import SellerProfile from "@/components/customer/seller/SellerProfile.vue";
import ProductOtherPrice from "@/components/customer/products/ProductOtherPrice.vue";
import ProductBulkOption from "@/components/customer/products/ProductBulkOption.vue";
import AskSeller from "../../../components/customer/modal/AskSeller.vue";

export default {
  components: {
    BreadcrumbNav,
    ProductDetails,
    //RelatedProducts,
    ProductDescription,
    ProductImageZoom,
    ProductAddCart,
    Spinner,
    ProductOtherPrice,
    ProductBulkOption,
    SellerProfile,
    AskSeller,
  },
  data() {
    return {
      loading: true,
      renderedComponent: true,
      product: {},
      images: [],
      showPrice: false,
      showBulk: false,
      showVariant: false,
      showAskSeller: false,
      mProduct: {},
      selectedSellerInfo: {},
      selectedIndex: 0,
      tierPrice: [],
    };
  },

  computed: {},
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderedComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderedComponent = true;
      });
    },
    updateSellerImages() {
      try {
        if (
          this.product.Seller_Specific_Detail[this.selectedIndex].imgurl.length
        )
          this.images =
            this.product.Seller_Specific_Detail[this.selectedIndex].imgurl;
      } catch (err) {
        console.log(err);
      }
    },

    updateTierPrice() {
      this.tierPrice =
        this.product.Seller_Specific_Detail[this.selectedIndex].tier_price;
    },

    onChangeVariant(indices) {
      const attr =
        this.product.Seller_Specific_Detail[this.selectedIndex]
          .product_attribute[indices];
      if (attr.values[0].image_url.length > 0) {
        this.images = attr.values[0].image_url;
      }
      if (attr.values[0].tier_price.length > 0) {
        this.tierPrice = attr.values[0].tier_price;
      }
    },
    onDefaultVariant() {
      this.updateSellerImages();
      this.updateTierPrice();
    },
    onShowPrice() {
      this.showPrice = true;
      this.showBulk = false;
    },
    onShowBulk() {
      this.showBulk = true;
      this.showPrice = false;
    },
    onSellerSelected(index) {
      this.selectedIndex = index;

      this.updateSellerImages();
      this.updateTierPrice();
      this.$forceUpdate();
    },

    loadProductDetails() {
      // UserService.getProductById(this.$route.params.id).then(
      UserService.getProductBySlug(this.$route.params.id).then(
        (response) => {
          this.loading = false;
          if (response.data.DATA) {
            this.product = response.data.DATA[0];
            if (this.product) {
              if (this.product.Seller_Specific_Detail) {
                if (
                  this.product.Seller_Specific_Detail.length &&
                  this.product.Seller_Specific_Detail[0].imgurl.length
                ) {
                  this.images = this.product.Seller_Specific_Detail[0].imgurl;
                } else if (this.product.imgurl.length) {
                  this.images = this.product.imgurl;
                }

                this.tierPrice =
                  this.product.Seller_Specific_Detail[0].tier_price;
              }
            }
            this.mProduct = { ...this.product };
            let sellerInfo = this.product.Seller_Specific_Detail;
            this.selectedSellerInfo = sellerInfo[0];
            if (sellerInfo && sellerInfo.length > 0) {
              sellerInfo = sellerInfo[0];
              this.mProduct.quantity = sellerInfo.quantity;
              this.mProduct.price =
                sellerInfo.default_price || sellerInfo.max_retail_price || 10;
              this.mProduct.vendor_id = sellerInfo.customer_id;
            }
          }
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.loadProductDetails();
  },
};
</script>
<style scoped>
.loader-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pic-box {
  width: 500px;
  height: 500px;
  border: 1px solid #eee;
}
</style>
