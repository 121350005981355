<template>
  <!-- Start of Mobile Menu -->
  <div class="mobile-menu-wrapper">
    <div class="mobile-menu-overlay"></div>
    <!-- End of .mobile-menu-overlay -->

    <a href="#" class="mobile-menu-close"><i class="close-icon"></i></a>
    <!-- End of .mobile-menu-close -->

    <div class="mobile-menu-container scrollable mobile-nav">
      <form class="input-wrapper" @submit.prevent="search">
        <input
          type="text"
          class="form-control"
          name="search"
          autocomplete="off"
          placeholder="Search"
          v-model="searchKey"
          required
        />
        <button class="btn btn-search" type="submit">
          <i class="w-icon-search"></i>
        </button>
      </form>
      <!-- End of Search Form -->
      <div class="tab">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a href="#main-menu" class="nav-link active">Main Menu</a>
          </li>
          <li class="nav-item">
            <a href="#categories" class="nav-link">Categories</a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div class="tab-pane active" id="main-menu">
          <ul class="mobile-menu mobile-nav">
            <li><router-link to="/">Home</router-link></li>
            <li>
              <router-link to="/login">{{
                isLogin ? 'Signout' : 'Signin'
              }}</router-link>
            </li>
            <li><router-link to="/cart">Cart</router-link></li>
            <li>
              <router-link to="/trackorder">TrackOrder</router-link>
            </li>
            <li><router-link to="/help-center">Help</router-link></li>
            <li><router-link to="/">Best Offers</router-link></li>
            <li><router-link to="/">Trade Show</router-link></li>
            <li>
              <router-link to="/">Personal Protective Equipment</router-link>
            </li>
            <li><router-link to="/">Medicine</router-link></li>
            <li><router-link to="/">Ask Doctor</router-link></li>
            <li><router-link to="/">Covid 19</router-link></li>
            <li><router-link to="/">Offer Price</router-link></li>
            <li>
              <a href="">Sell on WMT</a>
              <ul>
                <li>
                  <router-link to="/seller/login">Sell</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="tab-pane" id="categories">
          <ul class="mobile-menu mobile-nav">
            <li v-for="ct in mainCategories" :key="ct._id">
              <router-link :to="'/category/' + ct.slug">{{
                ct.name
              }}</router-link>
            </li>
            <li>
              <router-link to="/allcategory"
                >All Categories</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Mobile Menu -->
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('category');
const authMapGetters = createNamespacedHelpers('auth').mapGetters;
export default {
  data() {
    return {
      searchKey: '',
    };
  },
  computed: {
    ...mapGetters(['categories']),
    ...authMapGetters(['isLogin']),
    mainCategories() {
      return this.categories.filter((ct) => ct.is_main_category);
    },
  },
  methods: {
    search() {
      this.$emit('search', this.searchKey);
      this.searchKey = '';
    },
  },
};
</script>

<style></style>
