export const warning = {
  className: ['my-toast', 'wk-alert'],
  horizontalPosition: 'right',
  verticalPosition: 'top',
  duration: 3000,
  transition: 'transition',
};

export const success = {
  className: ['my-toast', 'wk-success'],
  horizontalPosition: 'right',
  verticalPosition: 'top',
  duration: 3000,
  transition: 'transition',
};
