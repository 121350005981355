<template>
  <Signup />
</template>

<script>
import Signup from '../../components/signup/Signup.vue';
export default {
  components: { Signup },
};
</script>

<style></style>
