import axios from 'axios';

const API_URL = `${process.env.VUE_APP_PRODUCT_SERVICE_API}api/`;//'https://wmt-seller-product.herokuapp.com/api/';
// const API_URL = 'http://localhost:3010/api/';

class ProductService {
  createProduct(payload) {
    return axios.post(API_URL + 'product/create', payload);
  }
  updateProduct(id, payload) {
    return axios.put(API_URL + 'product/' + id, payload);
  }
  createProductSeller(payload) {
    return axios.post(API_URL + 'seller/product/create', payload);
  }

  updateProductSeller(id, payload) {
    return axios.put(API_URL + 'seller/product/' + id, payload);
  }

  searchProducts(key) {
    return axios.get(API_URL + 'product/search/' + key);
  }
  getProductById(productId) {
    return axios.get(API_URL + 'product/' + productId);
  }
  searchSellerProduct(product_id, vendor_id) {
    return axios.get(API_URL + 'seller/product/search/', {
      params: {
        product_id,
        vendor_id,
      },
    });
  }
  getProductsByVendor(id) {
    return axios.get(API_URL + 'seller/products/' + id);
  }

  getAllProductByCategory(id) {
    return axios.get(API_URL + 'category/products/' + id);
  }

  updateActiveProductSeller(id, payload) {
    return axios.put(`${API_URL}seller/active-product/${id}`, payload);
  }
}

export default new ProductService();
