<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="page-404">
              <h3>404</h3>
              <h4>Page Not Found</h4>
              <p>
                The page you are looking for doesn't exist or an other<br />error
                occured.
                <!-- <router-link to="/"><a>Home Page</a> </router-link> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
