<template>
  <div id="bulk-price" class="other-price-box" v-if="seller">
    <div class="bulk-price">
      <button id="close" class="close" @click="$emit('close')">
        <i class="fa fa-times"></i>
      </button>
      <table cellpadding="0" cellspacing="0">
        <tr>
          <th width="27%">
            Seller Name
          </th>
          <th>Price</th>
        </tr>
        <tr>
          <td>
            <p>
              {{ seller.brand_name }}
            </p>
          </td>
          <td>
            <table cellpadding="0" cellspacing="0" class="inner-table">
              <tr>
                <th
                  v-for="(tier, i) in tierPrice"
                  :key="tier.quantity"
                  width="25%"
                >
                  <p>
                    {{
                      i == 0
                        ? `0 - ${tier.quantity}`
                        : `${tierPrice[i - 1].quantity}-${tier.quantity}`
                    }}
                  </p>
                </th>

                <th>More Then 10000</th>
              </tr>
              <tr>
                <td v-for="tier in tierPrice" :key="tier.quantity">
                  <p>
                    {{ tier.price }}
                  </p>
                </td>

                <td>
                  <a @click="askSeller" style="cursor:pointer">
                    Ask Seller
                  </a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product', 'tierPrice', 'index'],
  computed: {
    seller() {
      try {
        return this.product['Seller_Specific_Detail'][this.index];
      } catch (err) {
        return null;
      }
    },
  },
  mounted() {
    console.log({ tierprice: this.tierPrice });
  },
  methods: {
    askSeller() {
      this.$emit('close');
      this.$emit('askSeller');
    },
  },
};
</script>

<style scoped>
.other-price-box {
  display: block;
}

p {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  margin: 0;
}
</style>
