<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="document-field">
                        <p>All Products:</p>
                        <table
                          cellpadding="0"
                          cellspacing=""
                          class="seller-list"
                        >
                          <tr>
                            <th>#</th>
                            <th width="10%">Image</th>
                            <th>Name</th>
                            <th>Price(&#8377; )</th>
                            <th>SKU</th>
                            <th>QTY</th>
                            <!-- <th>Seller</th> -->
                            <th>Status</th>
                            <th width="7%">Action</th>
                          </tr>

                          <tr
                            v-for="(product, index) in products"
                            :key="product._id"
                          >
                            <td>{{ index + 1 }}.</td>
                            <td>
                              <img
                                :src="
                                  product.imgurl
                                    ? product.imgurl[0]
                                    : '/no-image.jpg'
                                "
                                width="50"
                                height="50"
                                style="cursor: pointer"
                                @click="editProduct(product._id)"
                              />
                            </td>
                            <td>
                              <h6
                                @click="editProduct(product._id)"
                                style="cursor: pointer"
                              >
                                {{ product.name }}
                              </h6>
                            </td>
                            <td>
                              {{
                                getSellerInfo(
                                  product["Seller_Specific_Detail"][0],
                                  "default_price"
                                )
                              }}
                            </td>
                            <td>
                              {{
                                getSellerInfo(
                                  product["Seller_Specific_Detail"][0],
                                  "sku"
                                )
                              }}
                            </td>
                            <td>
                              {{
                                getSellerInfo(
                                  product["Seller_Specific_Detail"][0],
                                  "quantity"
                                )
                              }}
                            </td>
                            <!-- <td>
                              {{
                                getSellerInfo(
                                  product['Seller_Specific_Detail'][0],
                                  'customer_id'
                                )
                              }}
                            </td> -->
                            <td>
                              {{ product.is_approved ? "Approved" : "Pending" }}
                            </td>
                            <td>
                              <button
                                type=""
                                class="action-button"
                                :class="
                                  product.is_approved ? 'red-btn' : 'green-btn'
                                "
                                @click="
                                  updateProduct(
                                    product,
                                    !product.is_approved,
                                    product.deleted
                                  )
                                "
                              >
                                <i
                                  class="fa fa-check"
                                  :class="
                                    product.is_approved
                                      ? 'fa-times'
                                      : 'fa-check'
                                  "
                                  :title="
                                    product.is_approved ? 'Reject' : 'Approve'
                                  "
                                ></i>
                              </button>
                              <!-- <button
                                type="Rejected"
                                class="action-button red-btn"
                                @click="updateProduct('active', product.active)"
                              >
                                <i class="fa fa-times" title="Reject"></i>
                              </button> -->
                              <button
                                type=""
                                class="action-button red-btn"
                                @click="
                                  updateProduct(
                                    product,
                                    product.is_approved,
                                    !product.deleted
                                  )
                                "
                              >
                                <i class="fa fa-trash" title="Delete"></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
<script>
import UserService from "../../services/user.service";
import Spinner from "../../components/Spinner.vue";
import ProductService from "../../services/product-service";
export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      prods: [],
      status: false,
    };
  },
  computed: {
    products() {
      return this.prods
        .map((v) => {
          let p = { ...v };
          p["imgurl"] = p.Seller_Specific_Detail[0]?.imgurl.filter((x) => x);
          return p;
        })
        .filter((prod) => !prod.deleted);
    },
  },
  mounted() {
    this.getAllProducts();
  },
  methods: {
    async getAllProducts() {
      try {
        this.loading = true;
        const res = await UserService.getProducts();
        this.loading = false;
        this.prods = res.data.DATA;
        this.prods.forEach((product) => {
          if (!product.imgurl.length) {
            product.imgurl = product["Seller_Specific_Detail"][0].imgurl;
          }
        });
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    getSellerInfo(obj, key) {
      return obj ? obj[`${key}`] : "";
    },
    editProduct(productId) {
      this.$router.push("/admin/manage-product-sellers/" + productId);
    },
    async updateProduct(product, is_approved, deleted) {
      let payload = { category_ids: product.category, is_approved, deleted };
      try {
        this.loading = true;
        await ProductService.updateProduct(product._id, payload);
        await this.getAllProducts();
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.seller-list {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.seller-list td,
.seller-list th {
  border: 1px solid #ddd;
  padding: 8px;
}
.seller-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #77abf0;
  color: white;
}
</style>
