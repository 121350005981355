<template>
  <main class="main bg-white">
    <div class="full-wrapper">
      <div v-if="loading" class="loader-container ">
        <spinner />
      </div>
      <div v-else-if="isError" class="loader-container">
        <h5>There is no orders to show</h5>
      </div>
      <div v-else>
        <h4>Manage Orders</h4>
        <div class="row">
          <div class="col-md-12">
            <div class="right-panel">
              <div class="order-div">
                <p>Orders 1 to {{ ordersData.length }}</p>
              </div>
              <div class="order-data">
                <table cellpadding="0" cellspacing="0" class="data-table">
                  <tr>
                    <th></th>
                    <th>Order Date</th>
                    <th>Order Detail</th>
                    <th>Shipping</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="order in ordersData" :key="order._id">
                    <td>
                      <!-- <input type="checkbox" /> -->
                    </td>
                    <td>
                      {{ order.payment_method.created_on_utc_date }}
                      <!-- <span>06:32 PM</span> -->
                    </td>
                    <td>
                      <router-link :to="'/order/' + order._id">{{
                        order._id
                      }}</router-link
                      ><br />
                      <div
                        v-for="item in order.order_item"
                        :key="item.product_id"
                      >
                        {{ item.product_name }}
                        <p>
                          <span>QTY: {{ item.quantity }}</span>
                          <span
                            >Price: <i class="fa fa-inr"></i
                            >{{ item.price }}</span
                          >
                        </p>
                      </div>
                      Buyer: {{ order.billing_address.first_name }}<br />
                      Seller: {{ userInfo.firstname }}<br />
                      Billing Country: India
                    </td>
                    <td>
                      Standard<br />
                      <p>
                        Expected Ship Date<br /><span>{{ day2FromToday }}</span>
                      </p>
                      <p>
                        Deliver By<br /><span>{{ weekFromToday }}</span>
                      </p>
                    </td>
                    <td>
                      <!-- {{ order.order_status }} -->
                      Pending
                    </td>
                    <td>
                      <button
                        type="button"
                        class="order-btn"
                        @click="printSlip"
                      >
                        Print Packing Slip
                      </button>
                      <button type="button" class="order-btn ship-btn">
                        Confirm Shipment
                      </button>
                      <button type="button" class="order-btn">
                        Cancel Order
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import OrderService from '@/services/order-service';
const authMapGetters = createNamespacedHelpers('auth').mapGetters;
export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      isError: false,
      ordersData: [],
    };
  },
  computed: {
    ...authMapGetters(['userInfo']),
    weekFromToday() {
      return moment()
        .add(7, 'days')
        .format('MMMM Do YYYY');
    },
    day2FromToday() {
      return moment()
        .add(2, 'days')
        .format('MMMM Do YYYY');
    },
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.loading = true;
      OrderService.getOrdersByVendorId(this.userInfo.id)
        .then((res) => {
          this.loading = false;
          this.ordersData = res.data.DATA;
          if (this.ordersData.length == 0) {
            this.isError = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isError = true;
          console.log(err);
        });
    },
    printSlip() {
      try {
        window.print();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.loader-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style></style>
