export default function insert(arr, value) {
  arr.forEach((item) => {
    try {
      if (item._id === value.parent_category_id) {
        if (item['children']) item['children'] = [];
        item['children'].push(value);
        // return false;
      } else {
        if (item['children'] && item['children'].length > 0)
          this.insert(item['children'], value);
        // return true;
      }
    } catch (err) {
      console.log(err);
    }
  });
}
export function getAllParentsOfCategory(id, categories) {
  let mappingCategory = '';
  if (!id || id === '0' || id === '') return;
  let found = false;
  let ct = {};
  for (let i = 0; i < categories.length; i++) {
    ct = categories[i];
    if (ct._id === id) {
      mappingCategory = `${ct.name}>>${mappingCategory}`;
      found = true;
      break;
    }
  }
  if (found) return this.getAllParentsOfCategory(ct.parent_category_id);
  else return mappingCategory;
}
