<template>
  <div>
    <div>
      <div class="container bg-white pb-4">
        <div class="row">
          <div
            class="logohead d-flex justify-content-center align-items-center"
          >
            <img @click="logoJump" src="../../assets/images/logo.png" />
          </div>
        </div>
        <div class="row py-3">
          <form @submit.prevent="createAccount" class="formbox2">
            <div class="signupform">
              <h1>Create Account</h1>
              <div class="email-login">
                <label for="country">Country/Region</label>
                <select id="countryslect" v-model="country" required>
                  <option value="IN" selected>India</option>
                </select>

                <label for="email">Email</label>

                <input
                  type="email"
                  placeholder="Enter email address or member ID"
                  required
                  v-model="email"
                />
                <label for="psw">Password</label>
                <div class="show-password">
                  <input
                    :type="passwordFieldType"
                    placeholder="Enter Password"
                    name="psw"
                    required
                    maxlength="32"
                    pattern=".{8,}"
                    title="Eight or more characters"
                    v-model="password"
                  />
                  <button
                    type="button"
                    class="password-icon"
                    @click.prevent="showHidePassword"
                  >
                    <i
                      class="fa fa-eye"
                      v-if="showPassword"
                      aria-hidden="true"
                    ></i>
                    <i class="fa fa-eye-slash" v-else aria-hidden="true"></i>
                  </button>
                </div>
                <label for="psw">Confirm Password</label>
                <div class="show-password">
                  <input
                    :type="confirmPasswordFieldType"
                    placeholder="Enter Confirm Password"
                    name="conpsw"
                    required
                    maxlength="32"
                    pattern=".{8,}"
                    title="Eight or more characters"
                    v-model="confirmpassword"
                  />
                  <button
                    type="button"
                    class="password-icon"
                    @click.prevent="showHideConfirmPassword"
                  >
                    <i
                      class="fa fa-eye"
                      v-if="showConfirmPassword"
                      aria-hidden="true"
                    ></i>
                    <i class="fa fa-eye-slash" v-else aria-hidden="true"></i>
                  </button>
                </div>
                <div v-if="isSeller">
                  <label for="companyname">Company</label>
                  <input
                    type="text"
                    placeholder="Enter Your Company Name"
                    name="cname"
                    required
                    maxlength="100"
                    pattern="[a-zA-Z0-9-._\s]+"
                    title="text and number only"
                    v-model="companyname"
                  />
                </div>
                <div
                  class="row"
                  style="padding-left: 3px; padding-right: 8px; margin: auto"
                >
                  <div style="width: 50%">
                    <label for="firstname">First Name</label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      name="fname"
                      required
                      maxlength="100"
                      pattern="[a-zA-Z0-9]+"
                      title="text and number only"
                      v-model="firstname"
                    />
                  </div>

                  <div style="width: 50%">
                    <label for="companyname">Last Name</label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      name="lname"
                      required
                      maxlength="100"
                      pattern="[a-zA-Z0-9]+"
                      title="text and number only"
                      v-model="lastname"
                    />
                  </div>
                </div>

                <label for="companyname">Mobile</label>
                <div class="mask-code">
                  <span>+91</span>
                  <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    name="fname"
                    required
                    maxlength="10"
                    pattern="[7-9]{1}[0-9]{9}"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    title="Phone number with 7-9 and remaing 9 digit with 0-9"
                    v-model="mobileno"
                  />
                </div>
              </div>
              <label for="checkbox">
                <p style="font-size: 11px; line-height: 12px; font-weight: 500">
                  <input
                    id="checkbox"
                    type="checkbox"
                    required
                    v-model="confirmcreate"
                  />
                  &nbsp;While creating a website account: I agree to abide by
                  the worldmedicaltrade.com Membership Agreement- Willing to
                  receive emails from worldmedicaltrade.com members and services
                </p></label
              >
              <router-link
                style="
                  font-size: 11px;
                  text-align: right;
                  text-decoration: underline;
                "
                :to="isSeller ? '/signup' : '/seller/signup'"
              >
                {{ isSeller ? "Register as Customer" : "Register as Seller" }}
              </router-link>
              <br />

              <div>
                <button class="signbtn" type="submit">Agree & Register</button>
                <p class="d-flex justify-content-center align-items-center">
                  Already a member
                </p>
                <button
                  type="button"
                  class="signbtn"
                  @click.prevent="openLogin"
                >
                  Sign In
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
  </div>
</template>

<script>
import Spinner from "../Spinner.vue";
import UserService from "../../services/user.service";
export default {
  components: { Spinner },
  data() {
    return {
      country: "IN",
      confirmcreate: false,
      mobileno: "",
      lastname: "",
      firstname: "",
      companyname: "",
      password: "",
      email: "",
      confirmpassword: "",
      signupsucess: false,
      signupfail: false,
      message: "",
      loading: false,
      username: "",
      showPassword: false,
      showConfirmPassword: false,
      isSeller: this.$route.name === "SellerSignup",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
    confirmPasswordFieldType() {
      return this.showConfirmPassword ? "text" : "password";
    },
  },
  methods: {
    showHidePassword() {
      return (this.showPassword = !this.showPassword);
    },
    showHideConfirmPassword() {
      return (this.showConfirmPassword = !this.showConfirmPassword);
    },
    logoJump() {
      window.location = window.location.origin;
    },
    async createAccount() {
      if (!this.validated()) {
        return;
      }
      try {
        let user = {
          email: this.email,
          password: this.password,
          roles: [this.isSeller ? "VENDOR" : "CUSTOMER"],
          firstName: this.firstname,
          lastName: this.lastname,
          contactNo: this.mobileno,
          countryCode: "+91",
          // is_approved: false,
          // active: true,
          // deleted: false,
        };
        console.log(user);
        this.loading = true;
        const res = await UserService.signUp(user);
        this.showSuccessToast("User Registered Succesfully");
        setTimeout(() => {
          this.openLogin();
        }, 3000);
      } catch (err) {
        console.log(err.toJSON());
        this.loading = false;
        if (err.response) {
          this.showWarningToast(err.response.data.message);
        }
      }
    },
    hideModal() {
      this.signupsucess = false;
      this.signupfail = false;
    },
    openLogin() {
      this.$router.push("/login");
    },

    showWarningToast(msg) {
      this.$wkToast(msg, {
        className: ["my-toast", "wk-alert"],
        horizontalPosition: "right",
        verticalPosition: "top",
        duration: 3000,
        transition: "transition",
      });
    },

    showSuccessToast(msg) {
      this.$wkToast(msg, {
        className: ["my-toast", "wk-success"],
        horizontalPosition: "right",
        verticalPosition: "top",
        duration: 3000,
        transition: "transition",
      });
    },

    validated() {
      if (!this.isEmailValid()) {
        this.showWarningToast("Please Enter valid email");
        return false;
      }
      if (this.password == "") {
        this.showWarningToast("Please Enter Password");
        return false;
      }
      if (this.password.length < 8) {
        this.showWarningToast("Password must not be less than 8 characters");
        return false;
      }

      if (this.password.length > 32) {
        this.showWarningToast(
          "Password must not be greater than 32 characters"
        );
        return false;
      }
      if (this.password !== this.confirmpassword) {
        this.showWarningToast("Password and Confirm password not match");
        return false;
      }
      if (!this.isMobileValid(this.mobileno)) {
        this.showWarningToast("Please enter correct mobile number");
        return false;
      }
      if (this.firstname == "") {
        this.showWarningToast("Please enter first name");
        return false;
      }
      if (this.lastname == "") {
        this.showWarningToast("Please enter last name");
        return false;
      }
      debugger;
      if (this.isSeller && !this.companyname) {
        this.showWarningToast("Please enter company");
        return false;
      }
      return true;
    },

    isEmailValid() {
      return this.email == ""
        ? false
        : this.reg.test(this.email)
        ? true
        : false;
    },
    isMobileValid(mobile) {
      let reg = /^\d{10}$/;
      return mobile.match(reg);
    },
  },
};
</script>
<style scoped>
.modal {
  display: block;
}
.loading-container {
  width: 100%;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 5px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
}

.mask-code {
  padding: 5px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
}
.mask-code input {
  background-color: transparent;
  border: 0px solid;
  display: table-cell;
  width: 90%;
}

.mask-code input:focus {
  box-shadow: 0px 0px 0px 0px transparent;
  outline: 0;
}

input:focus {
  -webkit-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  -moz-box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 174, 239, 0.9);
  outline: 0;
}

select {
  padding: 8px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
  font-size: 12px;
}

select:focus {
  padding: 8px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  width: 94%;
}
.show-password {
  position: relative;
}
.password-icon {
  position: absolute;
  right: 25px;
  top: 8px;
  background: transparent;
  border: 0px;
  padding: 5px;
}
</style>
