<template>
<main class="main">
  <div style="height: 100vh" v-if="loading" class="main">
    <div class="loading-container-full">
      <spinner />
    </div>
  </div>
  <div v-else class="container">
    <div class="center-wrapper">
        <div class="row">
          <div class="col-md-12">
            <form class="form form-panel">
                <div class="tab tab-nav-boxed tab-nav-underline show-code-action">
                    <ul class="nav-tabs mb-0" role="tablist" style="list-style-type: none">
                        <li class="nav-item">
                            <a class="nav-link active" ref="defaultTab" id="link-1" href="#tab-1">Vital Info</a>
                        </li>
                        <template v-if="seller.product_attribute.length > 0">
                            <li v-for="(attr, index) in seller.product_attribute" :key="index" class="nav-item">
                                <a class="nav-link" :id="`link-variation-${index}`" :ref="`variations-${index}`" :href="`#tab-variation-${index}`">{{attr.name}} Variation</a>
                            </li>
                        </template>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-1">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="product-category">
                                            Category<span>*</span>
                                            <i data-title="Product Category Name" class="fa fa-question-circle-o"></i>
                                        </label>
                                        <input id="product-category" type="text" :value="ParentsMap" class="form-control form-control-md" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <label for="product-name">
                                        Product Name<span>*</span>
                                        <i data-title="Enter Product Name" class="fa fa-question-circle-o"></i>
                                    </label>
                                    <input type="text" id="product-name" name="product" class="form-control form-control-md" v-model="product.name" maxlength="100" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Brand Name</label>
                                        <input class="form-control form-control-md" v-model="seller.brand_name" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Manufacturer</label>
                                        <input class="form-control form-control-md" v-model="seller.manufacturer" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Manufacturer Part Name</label>
                                        <input class="form-control form-control-md" v-model="seller.manufacturer_part_name" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>PPU Count</label>
                                        <input class="form-control form-control-md" v-model="seller.ppu_count" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>PPU Count Unit Measure</label>
                                        <input class="form-control form-control-md" v-model="seller.ppu_count_unit_measure" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Product Expirable</label>
                                        <input class="form-control form-control-md" v-model="seller.product_expirable" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>SKU</label>
                                        <input class="form-control form-control-md" v-model="seller.sku" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Origin Country</label>
                                        <input class="form-control form-control-md" v-model="seller.origin_country" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Item Condition</label>
                                        <input class="form-control form-control-md" v-model="seller.item_condition" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>HSN Code</label>
                                        <input class="form-control form-control-md" v-model="seller.hsn_code" readonly/>
                                    </div>
                                </div>
                                <div class="row ml-4 mr-4">
                                    <label>Image</label>
                                    <!-- <div class="form-group"> -->
                                    <image-uploader
                                    v-for="(image, index) in imageUrlsSeller"
                                    :key="index"
                                    :url="image.url"
                                    :index="index"
                                    />
                                    <!-- </div> -->
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label><span>*</span>Quantity<i data-title="quantity" class="fa fa-question-circle-o"></i></label>
                                        <input type="number" name="quantity" placeholder="Quantity" class="form-control form-control-md" v-model="seller.quantity" max="1000000" maxlength="7" :oninput="maxLengthInput" min="1" required/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label><span>*</span>Seller Min Price<i data-title="" class="fa fa-question-circle-o"></i></label>
                                        <input type="number" name="seller_min_price" placeholder="Seller Min Price" class="form-control form-control-md"
                                            v-model="seller.seller_min_price" min="1" max="1000000"
                                            maxlength="7" :oninput="maxLengthInput" required
                                        />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <label for=""
                                        ><span>*</span>Default Price
                                        <i data-title="" class="fa fa-question-circle-o"></i
                                    ></label>
                                    <input
                                        type="number"
                                        name="default_price"
                                        placeholder="Default Price"
                                        class="form-control form-control-md"
                                        v-model="seller.default_price"
                                        min="1"
                                        :oninput="maxLengthInput"
                                        max="1000000"
                                        maxlength="7"
                                        required
                                    />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <label for=""
                                        ><span>*</span>Max Retail Price
                                        <i data-title="" class="fa fa-question-circle-o"></i
                                    ></label>
                                    <input
                                        type="number"
                                        id=""
                                        name="max_retail_price"
                                        placeholder="Max Retail Price"
                                        class="form-control form-control-md"
                                        v-model="seller.max_retail_price"
                                        min="1"
                                        :oninput="maxLengthInput"
                                        max="1000000"
                                        maxlength="7"
                                        required
                                    />
                                    </div>
                                </div>
                                <h4 class="title title-password ls-25 font-weight-bold mt-3">Product Tier Price</h4>
                                <div class="control-group">
                                    <div class="inc">
                                        <div class="controls">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                    <label for=""
                                                        >Quantity Upto
                                                        <i
                                                        data-title=""
                                                        class="fa fa-question-circle-o"
                                                        ></i
                                                    ></label>
                                                    <input
                                                        type="number"
                                                        id=""
                                                        name="quantity"
                                                        class="form-control form-control-md"
                                                        v-model="tierPrice.quantity"
                                                    />
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                    <label for=""
                                                        >Price
                                                        <i
                                                        data-title=""
                                                        class="fa fa-question-circle-o"
                                                        ></i
                                                    ></label>
                                                    <input
                                                        type="number"
                                                        id=""
                                                        name="price"
                                                        class="form-control form-control-md"
                                                        v-model="tierPrice.price"
                                                    />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                    <button
                                                        type="button"
                                                        id="append"
                                                        name="append"
                                                        class="btn btn-dark btn-rounded btn-sm"
                                                        @click="addMoreSellerTierPrice"
                                                    >
                                                        Add More
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <tier-price-view-form
                                        v-for="(tier, index) in seller.tier_price"
                                        :key="index"
                                        :quantity="tier.quantity"
                                        :price="tier.price"
                                        :index="index"
                                        @remove="removeTierPriceFromSeller"
                                    />
                                </div>
                                <h4 class="title title-password ls-25 font-weight-bold mt-3">Variations</h4>
                                <div class="control-group">
                                    <div id="varients">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Variant Will Be Based on</label>
                                                    <input type="text" name="variant_name" class="form-control form-control-md" placeholder="Eg. Color/Size/Packing" v-model="variantType"/>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Value for current product variant</label>
                                                    <input type="text" id="current_variant" name="price" class="form-control form-control-md" placeholder="Eg. Red" v-model="variantValue"/>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <button type="button" id="variant-append" name="variant-append" class="btn btn-dark btn-rounded btn-sm variant-append" @click="addMoreVariants">
                                                        Add Variant
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button v-if="seller.product_attribute.length > 0" type="button" class="next-tab" @click='nextTab(0)'>
                                Next
                            </button>
                            <button v-else type="button" class="next-tab" @click="submit">
                                Save
                            </button>
                        </div>
                        <template v-if="seller.product_attribute.length > 0">
                            <div v-for="(attr, index) in seller.product_attribute" :key="index" class="tab-pane" :id="`tab-variation-${index}`">
                                <div class="control-group">
                                    <div class="variant">
                                        <product-variant-form
                                            :index="index"
                                            :attr="attr"
                                            @remove="removeVariant"
                                            @variantName="onVariantNameChanged"
                                            @variantMinPrice="onVariantMinPriceChanged"
                                            @variantDefaultPrice="onVariantDefaultPriceChanged"
                                            @variantImages="onVariantImageChanged"
                                            @variantTierPrice="onVariantTierPriceChanged"
                                        />
                                    </div>
                                </div>
                                
                                <button v-if="(seller.product_attribute.length - 1) > index" type="button" class="next-tab" @click='nextTab(index+1)'>
                                    Next
                                </button>
                                <button v-else type="button" class="next-tab" @click="submit">
                                    Save
                                </button>
                                <button v-if="index==0" type="button" class="cancel-tab" onclick='$("#link-1").trigger("click");'>
                                    Cancel
                                </button>
                                <button v-else type="button" class="cancel-tab" @click='nextTab(index-1)'>
                                    Cancel
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</main>
</template>

<script>
import { s3NewFileUrl, S3Client, s3NewFileName, s3Upload } from "@/util/s3upload";
import ImageUploader from "@/components/util/ImageUploader.vue";
import CategoryService from "@/services/category-service";
import ProductService from "@/services/product-service";
import { createNamespacedHelpers } from "vuex";
import Spinner from "@/components/Spinner.vue";
import TierPriceViewForm from "@/components/seller/products/TierPriceViewForm.vue";
import ProductVariantForm from "@/components/seller/products/ProductVariantForm.vue";
const { mapGetters } = createNamespacedHelpers("category");
const authGetters = createNamespacedHelpers("auth").mapGetters;
export default {
  components: { ImageUploader, Spinner, TierPriceViewForm, ProductVariantForm },
  data() {
    return {
      categoryId: null,
      loading: false,
      product_id: this.$route.params.id,
      product:{},
      sellerRelatedId:"",
      seller:{
          product_attribute: [],
      },
      imageUrlsSeller:[],
      tierPrice: {
        quantity: null,
        price: null,
      },
      variantType: "",
      variantValue: "",
      variantName: "",
      variantMinSalePrice: 1,
      variantDefaultPrice: 1,
      variantQauntity: 1,
      variantPrice: 1,
      variantTierPrice: []
    };
  },
  computed: {
    ...authGetters(["userInfo"]),
    ...mapGetters(["categories", "categoryAllChildren"]),
  },
  async mounted() {
    if (this.product_id) {
        await this.getProduct();
    }
  },
  methods: {
    async getAllCategory() {
      try {
        let res = await CategoryService.getCategoryAllChildren();
        let data = res.data.DATA;
        if (data) {
          this.$store.dispatch("category/addCategoryAllChildren", data);
        }
        res = await CategoryService.getAllCategory();
        data = res.data.DATA;
        if (data) {
          this.$store.dispatch("category/addCategories", data);
        }
      } catch (err) {
        console.log(err);
      }
    },

    findCategoryMap(id) {
      const cat = this.categoryAllChildren.find((ct) => ct._id === id);
      if (cat) {
        this.categoryId = cat._id;
        this.ParentsMap = cat.ParentsMap;
      }
    },

    async getProduct(){
      try {
            this.loading = true;
            await this.getAllCategory();
            let res = await ProductService.getProductById(this.product_id);
            let data = res.data.DATA;
            if (data) {
            const prodBasicInfo = data[0];
            this.findCategoryMap(prodBasicInfo.category[0]);
            this.product_id = prodBasicInfo._id;
            this.product.name = prodBasicInfo.name;
            this.product.is_approved = prodBasicInfo.is_approved;
            this.product.active = prodBasicInfo.active;
            this.product.deleted = prodBasicInfo.deleted;

          res = await ProductService.searchSellerProduct(
            this.product_id,
            this.userInfo.id
          );
          data = res.data.DATA;
          if (data && data.length > 0) {
            this.sellerRelatedId = data[0]._id;
            const sellerInfo = data[0];
            this.seller = { ...sellerInfo };

            //set seller images
            sellerInfo.imgurl.forEach((url) => {
              this.imageUrlsSeller.push({
                url,
                file: null,
              });
            });
            //set seller variants images

            if (sellerInfo.product_attribute.length > 0) {
              sellerInfo.product_attribute.forEach((attr) => {
                let i = 0;
                attr.values[0].image_url.forEach((url) => {
                  this.seller.product_attribute[i].values[0].image_url.push({
                    url,
                    file: null,
                  });
                });
                i++;
              });

              this.seller.product_attribute.forEach((attr) => {
                attr.values[0].image_url = attr.values[0].image_url.filter(
                  (url) => typeof url !== "string"
                );
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },

    removeTierPriceFromSeller(index) {
      if (index > -1) {
        this.seller.tier_price.splice(index, 1);
      }
    },

    addMoreSellerTierPrice() {
      if (
        !this.tierPrice.quantity ||
        !this.tierPrice.price ||
        this.tierPrice.quantity > 1000000 ||
        this.tierPrice.quantity < 1 ||
        this.tierPrice.price > 1000000 ||
        this.tierPrice.price < 1
      ) {
        return this.$wkToast(
          "Please fill tier price qauntity and price between 1 to 1000000"
        );
      }
      this.seller.tier_price.push({
        quantity: this.tierPrice.quantity,
        price: this.tierPrice.price,
      });
      this.tierPrice.quantity = null;
      this.tierPrice.price = null;
    },

    addMoreVariants() {
      if (this.variantType === "" || this.variantValue === "") {
        return this.$wkToast("Please fill variant type and values");
      }
      if (this.variantType === "") {
        return this.$wkToast("Please fill variant type and value", 'warning');
      }

      let values = [];
      values.push({
        name: `${this.product.name}-${this.variantValue}`,
        seller_min_price: this.variantMinSalePrice,
        default_price: this.variantDefaultPrice,
        tier_price: this.variantTierPrice,
        image_url: [],
      });
      this.seller.product_attribute.push({
        name: this.variantType,
        description: this.variantValue,
        values,
      });
      this.variantType = "";
      this.variantValue = "";
      this.variantName = "";
      this.variantMinSalePrice = 1;
      this.variantDefaultPrice = 1;
      this.variantQauntity = 1;
      this.variantPrice = 1;
      this.variantTierPrice = [];
      if(this.seller.product_attribute.length > 0){
          setTimeout(() => {
            this.$refs[`variations-${this.seller.product_attribute.length-1}`].click();
          }, 500);
      }
    },

    removeVariant(index) {
      if (index > -1) {
        this.seller.product_attribute.splice(index, 1);
        if(index == 0){
            this.$refs.defaultTab.click();
        }else{
            this.$refs[`variations-${index-1}`].click();
        }
      }
    },

    onVariantNameChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].name = value;
    //   console.log({ value, index });
    },

    onVariantMinPriceChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].seller_min_price = value;
    },

    onVariantDefaultPriceChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].default_price = value;
    },

    onVariantImageChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].image_url = value;
    },

    onVariantTierPriceChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].tier_price = value;
    },

    nextTab(ind){
        this.$refs[`variations-${ind}`].click();
    },

    validated() {
      if (
        !this.seller.quantity ||
        this.seller.quantity > 1000000 ||
        this.seller.quantity < 1
      ) {
        this.$wkToast("Please Enter Product Quantity between 1 to  1000000");
        return false;
      }

      if (
        !this.seller.seller_min_price ||
        this.seller.seller_min_price > 1000000 ||
        this.seller.seller_min_price < 1
      ) {
        this.$wkToast("Please Enter Seller Minimum Price between 1 to 1000000");
        return false;
      }
      if (
        !this.seller.default_price ||
        this.seller.default_price > 1000000 ||
        this.seller.default_price < 1
      ) {
        this.$wkToast("Please Enter Seller Default Price between 1 to 1000000");
        return false;
      }
      if (
        !this.seller.max_retail_price ||
        this.seller.max_retail_price > 1000000 ||
        this.seller.max_retail_price < 1
      ) {
        this.$wkToast("Please Enter Max Retail Price between 1 to 1000000");
        return false;
      }

      return true;
    },

    async submit() {
        if (!this.validated()) return;
        try {
            this.loading = true;

            await this.uploadToS3();

            const sellerPayload = {...this.seller};
            sellerPayload.imgurl = this.imageUrlsSeller;
            sellerPayload.vendor_id = this.userInfo.id;

            await ProductService.updateActiveProductSeller(
              this.sellerRelatedId,
              sellerPayload
            );

            this.loading = false;
            this.$wkToast(`Product Update Successfully`);
            this.$router.push("/manage-inventory");
        } catch (err) {
            this.loading = false;
            this.$wkToast(`Product Update Failed`);
        }
    },

    async uploadToS3() {
      //variants images
      if (this.seller.product_attribute.length > 0) {
        const files = [];
        let count = 0;
        let counter = [];
        this.seller.product_attribute.forEach((attr) => {
          // counter.push(count);
          let inCount = 0;
          attr.values[0].image_url.forEach((image) => {
            if (image.file) {
              counter.push({ count, inCount });
              files.push(image.file);
              inCount++;
            }
          });
          count++;
        });

        this.seller.product_attribute.forEach((attr) => {
          attr.values[0].image_url = [];
        });

        let i = 0;
        for (let file of files) {
          let fileExtension = file.type.split("/")[1];
          if (fileExtension === "svg+xml") {
            fileExtension = "svg";
          }
          const s3NewFile = s3NewFileName();
          try {
            await s3Upload(file, s3NewFile);
          } finally {
            try {
              const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
              const ind = counter[i];
              this.seller.product_attribute[ind.count].values[0].image_url.push(
                imageurl
              );
              i++;
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
    loading-container-scoped {
        width: 100%;
        height: 100vh;
        background-color: #3f373700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>