<template>
  <div class="tab-pane active in" id="tab4-1">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>

    <div v-else-if="!self || (!showEdit && companyOverviewId)">
      <div class="row">
        <div class="col-md-6">
          <h5>Company Album</h5>
        </div>
        <div
          v-if="self"
          class="col-md-6 d-flex justify-content-end align-items-center"
          @click="showEdit = true"
        >
          <i class="fas fa-edit "></i>
        </div>
      </div>
      <div class="row">
        <image-uploader
          v-for="(url, index) in imageUrls"
          :key="index"
          :url="url"
          :index="index"
          class="ml-4"
        />
      </div>
      <div
        class="row"
        v-for="[key, value] in Object.entries(companyOverviews)"
        :key="key"
      >
        <div class="pt-4 pb-4">
          <h5>
            {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
          </h5>
          <span v-if="key === 'BusinessType'">{{ showBusinessType(value)}}</span>
          <span v-else-if="key === 'location'">
            <small><b>Latitude:</b>{{value.lat}}</small>, 
            <small><b>Longitude:</b>{{value.lng}}</small>
          </span>
          <span v-else>{{value}}</span>
        </div>
      </div>
    </div>
    <form v-else class="form account-details-form" @submit.prevent="save">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for=""><h5>Company Album</h5></label>
            <input
              type="file"
              id=""
              name=""
              class="form-control p-5"
              @change="onFileSelect"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <image-uploader
          v-for="(url, index) in imageUrls"
          :key="index"
          :url="url"
          :index="index"
          @delete-file="deleteFilefromFiles"
          showDelete="true"
          class="ml-4"
        />
      </div>
      <div class="row">
        <div
          class="col-md-6"
          v-for="key in Object.keys(companyOverviews)"
          :key="key"
        >
          <div class="form-group">
            <label for="" v-if="key !== 'location'"
              ><h5>
                {{
                  key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '')
                }}
              </h5></label
            >
            <div v-if="key === 'BusinessType'">
              <input
                list="busi"
                name="busi"
                class="form-control form-control-md"
                required
                v-model="selectedBusiness"
              />
              <datalist id="busi">
                <option v-for="b in business" :key="b" :value="b" />
              </datalist>
            </div>

            <div v-else-if="key === 'YearEstablished'">
              <select
                v-model="companyOverviews[key]"
                required
                class="form-control form-control-md"
              >
                <option
                  v-for="year in years"
                  :key="year"
                  :value="year"
                  selected
                  >{{ year }}</option
                >
              </select>
            </div>
            <template v-else-if="key === 'location'"></template>
            <input
              v-else
              :type="type(key)"
              id=""
              name=""
              class="form-control form-control-md"
              maxlength="100"
              pattern="[a-zA-Z0-9-._\s]+"
              title="text and number only"
              required
              v-model="companyOverviews[key]"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <label>Location: <small>Latitude: {{coverview.location.lat}}, Longitude: {{coverview.location.lng}}</small></label>
        <div class="col-md-12 mb-5" style="width: 100%; height: 400px">
          <GMapMap
              :center="{lat:21.7679, lng:78.8718}"
              :zoom="4"
              map-type-id="terrain"
              style="width: 100%; height: 400px"
          >
          <GMapMarker
            v-if="Object.keys(coverview.location).length > 0"
            :position="coverview.location"
            :clickable="true"
            :draggable="true"
            @drag="updateCoordinates"
          />
          </GMapMap>
        </div>
      </div>

      <button type="submit" class="btn btn-dark btn-rounded btn-sm mb-4">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import ImageUploader from '@/components/util/ImageUploader.vue';
import ProfileService from '@/services/profile.service';
import { s3NewFileUrl, S3Client, s3NewFileName, s3Upload } from '@/util/s3upload';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const { mapGetters } = createNamespacedHelpers('auth');
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;

export default {
  components: {
    ImageUploader,
    Spinner,
  },
  data() {
    return {
      loading: false,
      showEdit: false,
      imageUrls: [],
      companyOverviewId: '',
      sellerId: this.$route.params.id,
      files: [],
      fileUrls: [],
      coverview: {
        BusinessType: 1,
        MainProducts: 'Main Products',
        CountryRegion: 'India',
        Ownership: 'Ownership',
        TotalEmployees: 3,
        TotalAnnualRevenue: '234',
        YearEstablished: '3445',
        ProductCertification1: 'test m',
        ProductCertification2: 'manufacturer_part_name',
        Patent: 'asfas',
        Trademark: '90 cm',
        imgurl: [],
        address:'',
        location:{lat:26.9439056, lng:75.7384106}
      },
      business: ['Trading Company', 'Manufacturer', 'Distributer', 'Retailer'],
      selectedBusiness: 'Retailer',
    };
  },
  computed: {
    companyOverviews() {
      if (this.companyOverviewBySellerId) {
        const {
          _id,
          imgurl,
          customerid,
          ...co
        } = this.companyOverviewBySellerId;
        this.setCompanyOverviewId(_id);
        this.setImageUrls(imgurl);
        return co;
      }

      return {};
    },
    companyOverviewBySellerId() {
      if (this.companyOverview) {
        return this.companyOverview[this.sellerId || this.userInfo.id];
      }
      return null;
    },
    ...mapGetters(['userInfo']),
    ...profileMapGetters(['companyOverview']),
    self() {
      return this.userInfo.id === this.sellerId;
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
    },
  },
  mounted() {
    if (this.companyOverviewId == '') {
      this.getCompanyOverview();
    }
  },
  methods: {
    getCuurentLocation(){
      //do we support geolocation
      if(!("geolocation" in navigator)) {
        console.log('Geolocation is not available.');
        return;
      }
      // get position
      navigator.geolocation.getCurrentPosition(pos => {
        this.coverview.location = {lat:pos.coords.latitude, lng:pos.coords.longitude};
      }, err => {
        console.log("geolocation",err.message);
      })
    },
    updateCoordinates(pos){
      this.coverview.location = {
          lat: pos.latLng.lat(),
          lng: pos.latLng.lng(),
      };
    },
    showBusinessType(value) {
      return value < 3 && value > -1 ? this.business[value] : value;
    },
    type(key) {
      if (key === 'TotalAnnualRevenue' || key === 'TotalEmployees') {
        return 'number';
      } else if (key === 'YearEstablished') {
        return 'date';
      } else {
        return 'text';
      }
    },

    setCompanyOverviewId(id) {
      if (id) this.companyOverviewId = id;
    },
    setImageUrls(imgurl) {
      if (imgurl) this.imageUrls = imgurl;
    },
    getCompanyOverview() {
      this.loading = true;
      ProfileService.getCompanyOverview(this.sellerId || this.userInfo.id)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            // debugger;
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addCompanyOverview', cover);
          } else {
            this.getCuurentLocation();
            co = this.coverview;
            co.customerid = this.sellerId || this.userInfo.id;
            this.$store.dispatch('companyProfile/addCompanyOverview', co);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    onFileSelect(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = '';
        this.$wkToast('Please Select file less than 1mb');
        return;
      }

      this.files.push(file);
      const url = URL.createObjectURL(file);
      this.imageUrls.push(url);
      this.fileUrls.push(url);
    },
    deleteFilefromFiles(value) {
      let index = this.imageUrls.indexOf(value);
      if (index > -1) {
        this.imageUrls.splice(index, 1);
      }
      index = this.fileUrls.indexOf(value);
      if (index > -1) {
        this.fileUrls.splice(index, 1);
        this.files.splice(value, 1);
      }
    },
    async uploadToS3() {
      for (let file of this.files) {

        let fileExtension = file.type.split("/")[1];
        if (fileExtension === "svg+xml") {
          fileExtension = "svg";
        }
        const s3NewFile = s3NewFileName();
        try {
          await s3Upload(file, s3NewFile);
        } finally {
          const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
          this.imageUrls.push(imageurl);
        }
      }
    },
    validated() {
      return true;
    },
    async save() {
      // if (!this.validated()) {
      //   return;
      // }
      try {
        console.log('save start');
        this.loading = true;
        this.imageUrls = this.imageUrls.filter((s) => !s.includes('localhost'));
        await this.uploadToS3();
        const payload = {
          ...this.companyOverviews,
          imgurl: this.imageUrls,
          customerid: this.userInfo.id,
          location: this.coverview.location
        };
        if (!this.companyOverviewId || this.companyOverviewId == '') {
          // console.log('create co');
          await ProfileService.createCompanyOverview(payload);
        } else {
          await ProfileService.updateCompanyOverview(
            payload,
            this.companyOverviewId
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.showEdit = false;
      this.getCompanyOverview();
    },
  },
};
</script>
<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
.ui-datepicker-calendar {
  display: none;
}
</style>
<style>
.vue-map-container{
  width: 100%;
  height: -webkit-fill-available;
}
</style>