<template>
  <input
    type="file"
    class="form-control p-5"
    accept="image/*"
    @change="onFileSelect"
  />
</template>

<script>
export default {
  props: ['index'],
  methods: {
    onFileSelect(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = '';
        this.$wkToast('Please Select file less than 1mb');
        return;
      }
      this.$emit('onFileChange', { index: this.index || 0, file });
    },
  },
};
</script>

<style></style>
