<template>
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="center-wrapper">
        <div class="row">
          <div class="col-md-12">
            <form class="form form-panel">
              <div class="tab tab-nav-boxed tab-nav-underline show-code-action">
                <ul
                  class="nav-tabs mb-0"
                  role="tablist"
                  style="list-style-type: none"
                >
                  <li class="nav-item">
                    <a class="nav-link active" id="link-1" href="#tab-1"
                      >Vital Info</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="link-2" href="#tab-2">Variations</a>
                  </li>
                  <li class="nav-item" v-if="!isUpdate">
                    <a class="nav-link" id="link-3" href="#tab-3">Keywords</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="tab-1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Category<span>*</span>
                            <i
                              data-title="Enter Product Name"
                              class="fa fa-question-circle-o"
                            ></i
                          ></label>
                          <input
                            type="text"
                            placeholder="Product Category"
                            :value="ParentsMap"
                            class="form-control form-control-md"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            ><span>*</span>Product Name
                            <i
                              data-title="Enter Product Name"
                              class="fa fa-question-circle-o"
                            ></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="product"
                            placeholder="Product Name"
                            class="form-control form-control-md"
                            v-model="product.name"
                            maxlength="100"
                            required
                            :readonly="isUpdate"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            ><span>*</span>Quantity
                            <i
                              data-title="quantity"
                              class="fa fa-question-circle-o"
                            ></i
                          ></label>
                          <input
                            type="number"
                            id=""
                            name="quantity"
                            placeholder="Quantity"
                            class="form-control form-control-md"
                            v-model="seller.quantity"
                            max="1000000"
                            maxlength="7"
                            :oninput="maxLengthInput"
                            min="1"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            ><span>*</span>Seller Min Price
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="number"
                            name="seller_min_price"
                            placeholder="Seller Min Price"
                            class="form-control form-control-md"
                            v-model="seller.seller_min_price"
                            min="1"
                            max="1000000"
                            maxlength="7"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            ><span>*</span>Default Price
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="number"
                            name="default_price"
                            placeholder="Default Price"
                            class="form-control form-control-md"
                            v-model="seller.default_price"
                            min="1"
                            :oninput="maxLengthInput"
                            max="1000000"
                            maxlength="7"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            ><span>*</span>Max Retail Price
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="number"
                            id=""
                            name="max_retail_price"
                            placeholder="Max Retail Price"
                            class="form-control form-control-md"
                            v-model="seller.max_retail_price"
                            min="1"
                            :oninput="maxLengthInput"
                            max="1000000"
                            maxlength="7"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Brand Name
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name=""
                            placeholder="Brand Name"
                            class="form-control form-control-md"
                            v-model="seller.brand_name"
                            maxlength="100"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Manufacturer
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="manufacturer"
                            placeholder="Manufacturer"
                            class="form-control form-control-md"
                            v-model="seller.manufacturer"
                            maxlength="100"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Manufacturer Part Name
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="manufacturer_part_name"
                            placeholder="Manufacturer Part Name"
                            class="form-control form-control-md"
                            v-model="seller.manufacturer_part_name"
                            maxlength="100"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >PPU Count
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="number"
                            id=""
                            name="ppu_count"
                            placeholder="PPU Count"
                            class="form-control form-control-md"
                            v-model="seller.ppu_count"
                            maxlength="10"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >PPU Count Unit Measure
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="ppu_count_unit_measure"
                            placeholder="PPU Count Unit Measure"
                            class="form-control form-control-md"
                            v-model="seller.ppu_count_unit_measure"
                            maxlength="100"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Product Expirable
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="product_expirable"
                            placeholder="yes or no"
                            class="form-control form-control-md"
                            v-model="seller.product_expirable"
                            maxlength="3"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >SKU
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="sku"
                            placeholder="SKU"
                            class="form-control form-control-md"
                            v-model="seller.sku"
                            maxlength="100"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Origin Country
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="origin_country"
                            placeholder="Orihin Country"
                            class="form-control form-control-md"
                            v-model="seller.origin_country"
                            :oninput="maxLengthInput"
                            readonly
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Item Condition
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="item_condition"
                            placeholder="Item Condition"
                            class="form-control form-control-md"
                            v-model="seller.item_condition"
                            :oninput="maxLengthInput"
                            maxlength="32"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >HSN Code
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="text"
                            id=""
                            name="hsn_code"
                            placeholder="HSN Code"
                            class="form-control form-control-md"
                            v-model="seller.hsn_code"
                            maxlength="100"
                            :oninput="maxLengthInput"
                            required
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            ><span>*</span>Images
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <input
                            type="file"
                            id=""
                            name=""
                            class="form-control p-5"
                            @change="onSellerFilesSelect"
                            accept="image/*"
                            required
                          />
                        </div>
                      </div>
                      <div class="row ml-4 mr-4">
                        <!-- <div class="form-group"> -->
                        <image-uploader
                          v-for="(image, index) in imageUrlsSeller"
                          :key="index"
                          :url="image.url"
                          :index="index"
                          :showDelete="true"
                          @delete-file="onDeleteSellerFiles"
                        />
                        <!-- </div> -->
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Short Description
                            <i
                              data-title="Short Description"
                              class="fa fa-question-circle-o"
                            ></i
                          ></label>
                          <div class="editor">
                            <!-- <textarea
                              id="editor-1"
                              name=""
                              class="form-control form-control-md"
                              required
                            ></textarea> -->
                            <ckeditor
                              :editor="editor"
                              v-model="seller.short_description"
                              :config="editorConfig"
                              class="form-control form-control-md"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" style="margin-top: 1.5rem">
                        <div class="form-group">
                          <label for=""
                            >Full Description
                            <i
                              data-title="Full desription"
                              class="fa fa-question-circle-o"
                            ></i
                          ></label>
                          <div class="editor">
                            <!-- <textarea
                              id="editor-2"
                              name=""
                              class="form-control form-control-md"
                              required
                            ></textarea> -->
                            <ckeditor
                              :editor="editor1"
                              v-model="seller.full_description"
                              :config="editorConfig"
                              class="form-control form-control-md"
                            />
                          </div>
                        </div>
                      </div>

                      <!-- Mandatory fields and documnts -->
                      <div
                        v-if="
                          mandatoryFields.length || mandatoryDocuments.length
                        "
                      >
                        <h4>Mandatdory Fields</h4>
                        <div
                          class="col-md-12"
                          v-for="(man, i) in mandatoryFields"
                          :key="i"
                        >
                          <div class="form-group">
                            <label for=""
                              >{{ man.required_field_name }}
                              <i
                                data-title=""
                                class="fa fa-question-circle-o"
                              ></i
                            ></label>
                            <input
                              :type="
                                man.required_field_type === 'Calendar'
                                  ? 'date'
                                  : man.required_field_type
                              "
                              :placeholder="man.required_field_name"
                              class="form-control form-control-md"
                              maxlength="100"
                              v-model="mandatoryFieldsInput[i].value"
                              :oninput="maxLengthInput"
                              required
                            />
                          </div>
                        </div>

                        <!-- <div
                          class="col-md-12"
                          v-for="(man, i) in mandatoryDocuments"
                          :key="i"
                        >
                          <div class="form-group">
                            <label for=""
                              >{{ man.required_field_name }}
                              <i
                                data-title=""
                                class="fa fa-question-circle-o"
                              ></i
                            ></label>
                            <input
                              type="file"
                              :placeholder="man.required_field_name"
                              class="form-control form-control-md"
                              accept="image/*"
                              required
                            />
                          </div>
                        </div> -->
                      </div>

                      <h4
                        class="title title-password ls-25 font-weight-bold mt-3"
                      >
                        Product Tier Price
                      </h4>

                      <div class="control-group">
                        <div class="inc">
                          <div class="controls">
                            <div class="row">
                              <div class="col-md-5">
                                <div class="form-group">
                                  <label for=""
                                    >Quantity Upto
                                    <i
                                      data-title=""
                                      class="fa fa-question-circle-o"
                                    ></i
                                  ></label>
                                  <input
                                    type="number"
                                    id=""
                                    name="quantity"
                                    class="form-control form-control-md"
                                    v-model="tierPrice.quantity"
                                  />
                                </div>
                              </div>
                              <div class="col-md-5">
                                <div class="form-group">
                                  <label for=""
                                    >Price
                                    <i
                                      data-title=""
                                      class="fa fa-question-circle-o"
                                    ></i
                                  ></label>
                                  <input
                                    type="number"
                                    id=""
                                    name="price"
                                    class="form-control form-control-md"
                                    v-model="tierPrice.price"
                                  />
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group">
                                  <button
                                    type="button"
                                    id="append"
                                    name="append"
                                    class="btn btn-dark btn-rounded btn-sm"
                                    @click="addMoreSellerTierPrice"
                                  >
                                    Add More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <tier-price-view-form
                          v-for="(tier, index) in seller.tier_price"
                          :key="index"
                          :quantity="tier.quantity"
                          :price="tier.price"
                          :index="index"
                          @remove="removeTierPriceFromSeller"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      class="next-tab"
                      onclick='$("#link-2").trigger("click");'
                    >
                      Next
                    </button>

                    <button
                      type="button"
                      class="cancel-tab"
                      @click="$emit('cancel')"
                    >
                      Cancel
                    </button>
                  </div>
                  <div class="tab-pane" id="tab-2">
                    <div id="varients">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for=""
                              >Variant Will Be Based on
                              <i
                                data-title=""
                                class="fa fa-question-circle-o"
                              ></i
                            ></label>
                            <input
                              type="text"
                              id=""
                              name="variant_name"
                              class="form-control form-control-md"
                              placeholder="Eg. Color/Size/Packing"
                              v-model="variantType"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for=""
                              >Value for current product variant
                              <i
                                data-title=""
                                class="fa fa-question-circle-o"
                              ></i
                            ></label>
                            <input
                              type="text"
                              id="current_variant"
                              name="price"
                              class="form-control form-control-md"
                              placeholder="Eg. Red"
                              v-model="variantValue"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <button
                              type="button"
                              id="variant-append"
                              name="variant-append"
                              class="
                                btn btn-dark btn-rounded btn-sm
                                variant-append
                              "
                              @click="addMoreVariants"
                            >
                              Add Variant
                            </button>
                          </div>
                        </div>
                      </div>

                      <h4
                        class="title title-password ls-25 font-weight-bold mt-3"
                      >
                        Variants of The Product
                      </h4>
                      <div class="control-group">
                        <div class="variant">
                          <product-variant-form
                            v-for="(attr, index) in seller.product_attribute"
                            :key="index"
                            :index="index"
                            :attr="attr"
                            @remove="removeVariant"
                            @variantName="onVariantNameChanged"
                            @variantMinPrice="onVariantMinPriceChanged"
                            @variantDefaultPrice="onVariantDefaultPriceChanged"
                            @variantImages="onVariantImageChanged"
                            @variantTierPrice="onVariantTierPriceChanged"
                          />
                          <div class="controls"></div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      class="next-tab"
                      @click="submit"
                      v-if="isUpdate"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="next-tab"
                      onclick='$("#link-3").trigger("click");'
                      v-else
                    >
                      Next
                    </button>
                    <button
                      type="button"
                      class="cancel-tab"
                      onclick='$("#link-1").trigger("click");'
                    >
                      Cancel
                    </button>
                  </div>
                  <div class="tab-pane" id="tab-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Page Title
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <div class="editor">
                            <textarea
                              id=""
                              name="title"
                              placeholder="Page Title"
                              v-model="pageTitle"
                              class="form-control form-control-md"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Page Keywords
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <div class="editor">
                            <textarea
                              id=""
                              name="keywords"
                              v-model="pageKeywords"
                              placeholder="Page Keywords"
                              class="form-control form-control-md"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for=""
                            >Page Description
                            <i data-title="" class="fa fa-question-circle-o"></i
                          ></label>
                          <div class="editor">
                            <textarea
                              id=""
                              name="description"
                              placeholder="Page Description"
                              class="form-control form-control-md"
                              v-model="pageDescription"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="next-tab" @click="submit">
                      Save
                    </button>
                    <button
                      type="button"
                      class="cancel-tab"
                      onclick='$("#link-2").trigger("click");'
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import TierPriceViewForm from "./TierPriceViewForm.vue";
import ImageUploader from "@/components/util/ImageUploader.vue";
import ProductVariantForm from "./ProductVariantForm.vue";
import { createNamespacedHelpers } from "vuex";
import ProductService from "@/services/product-service";
import CategoryService from "@/services/category-service";
import {
  s3NewFileUrl,
  S3Client,
  s3NewFileName,
  s3Upload,
} from "@/util/s3upload";
import Spinner from "@/components/Spinner.vue";
import { warning } from "@/util/ToastUtil";
import { alphaNumericWithSpace } from "@/util/regexUtil";
import ProductDraftService from "@/services/product-draft-service";
const { mapGetters } = createNamespacedHelpers("auth");
const draftMapGetters = createNamespacedHelpers("productDrafts").mapGetters;
const categoryMapGetters = createNamespacedHelpers("category").mapGetters;

export default {
  components: {
    ckeditor: CKEditor.component,
    ImageUploader,
    TierPriceViewForm,
    ProductVariantForm,
    Spinner,
  },

  props: ["category", "name"],

  data(props) {
    return {
      categoryId: props?.category?._id,
      parentsMap: props?.category?.ParentsMap,
      product_id: this.$route.params.id,
      draftId: this.$route?.params?.draft_id,

      loading: false,

      //mandatory fields for product from category

      mandatoryFields: [],
      mandatoryFieldsInput: [],
      mandatoryDocuments: [],

      //pageDescription

      pageTitle: "",
      pageDescription: "",
      pageKeywords: "",

      // images
      imageUrlsSeller: [],

      variantFiles: [],
      variantImageUrls: [],

      //ckeditor
      editor: ClassicEditor,
      editor1: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },

      // product tier price
      tierPrice: {
        quantity: null,
        price: null,
      },

      // product variants

      variantType: "",
      variantValue: "",
      variantName: "",
      variantMinSalePrice: 1,
      variantDefaultPrice: 1,
      variantQauntity: 1,
      variantPrice: 1,
      variantTierPrice: [],

      //product

      product: {
        name: props?.name || "",
        is_approved: false,
        active: true,
        deleted: false,
      },

      //seller
      sellerRelatedId: "",
      seller: {
        short_description: "",
        full_description: "",
        quantity: null,
        seller_min_price: null,
        default_price: null,
        brand_name: "",
        manufacturer: "",
        manufacturer_part_name: "",
        ppu_count: null,
        ppu_count_unit_measure: "",
        product_expirable: "",
        sku: "",
        origin_country: "India",
        item_condition: "",
        hsn_code: "",
        max_retail_price: null,
        fullfillment_channel: "apollo",
        imgurl: [],

        tier_price: [],
        seller_geo_location: {
          latitude: "28.4228859",
          longitude: "76.8496637",
        },
        specification_attribute: [],
        product_specification_detail: "Product Sepecifc Details",
        product_attribute: [],
      },

      completeWithIssue: false,
      completed: false,
      maxLengthInput:
        "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isAdmin"]),
    ...draftMapGetters(["drafts"]),
    ...categoryMapGetters(["categories", "categoryAllChildren"]),
    isUpdate() {
      return this.product_id ? true : false;
    },
  },
  async mounted() {
    if (this.product_id) {
      await this.searchProduct();
    }
    if (this.category) {
      this.categoryId = this.category._id;
      this.ParentsMap = this.category.ParentsMap;
      this.getMandatoryFields();
      if (!this.product_id) {
        setTimeout(() => {
          this.seller.full_description = this.category?.product_template;
        }, 100);
      }
    }
    if (this.name) {
      this.product.name = this.name;
      // console.log("productId", this.productId, this.product_id);
    }
    if (this.draftId) {
      // debugger;
      this.getDraft();
    }
  },
  methods: {
    getMandatoryFields() {
      const cat = this.categories.find((ct) => ct._id === this.categoryId);
      if (cat) {
        this.mandatoryFields = cat.mandatory_for_product;
        this.mandatoryFieldsInput =
          this.mandatoryFields.length > 0
            ? this.mandatoryFields.map((v) => {
                return {
                  field_id: v._id,
                  field: v.required_field_name,
                  value: "",
                };
              })
            : [];
        this.mandatoryDocuments = cat.mandatory_for_seller;
      }
    },

    getDraft() {
      if (this.draftId) {
        // const draft = this.drafts.find((d) => {
        //   return d.id.toString() === this.draftId;
        // });
        // if (draft) {
        //   this.product = draft.product;
        //   this.seller = draft.seller;
        // }
        ProductDraftService.getVendorDraftById(this.userInfo.id, this.draftId)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              var draft_data = res.data.DATA;
              this.product.name = draft_data.product_name;
              this.pageTitle = draft_data.page_title;
              this.pageDescription = draft_data.page_description;
              this.pageKeywords = draft_data.page_keywords;
              this.seller.short_description = draft_data.short_description;
              this.seller.full_description = draft_data.full_description;
              this.seller.quantity = draft_data.quantity;
              this.seller.seller_min_price = draft_data.seller_min_price;
              this.seller.default_price = draft_data.default_price;
              this.seller.brand_name = draft_data.brand_name;
              this.seller.manufacturer = draft_data.manufacturer;
              this.seller.manufacturer_part_name =
                draft_data.manufacturer_part_name;
              this.seller.ppu_count = draft_data.ppu_count;
              this.seller.ppu_count_unit_measure =
                draft_data.ppu_count_unit_measure;
              this.seller.product_expirable = draft_data.product_expirable;
              this.seller.sku = draft_data.sku;
              this.seller.origin_country = draft_data.origin_country;
              this.seller.item_condition = draft_data.item_condition;
              this.seller.hsn_code = draft_data.hsn_code;
              this.seller.max_retail_price = draft_data.max_retail_price;
              
              if (draft_data.images) {
                this.seller.imgurl.push(draft_data.images);
                this.imageUrlsSeller.push({
                  url: draft_data.images,
                  file: null,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async getAllCategory() {
      try {
        let res = await CategoryService.getCategoryAllChildren();
        let data = res.data.DATA;
        if (data) {
          this.$store.dispatch("category/addCategoryAllChildren", data);
        }
        res = await CategoryService.getAllCategory();
        data = res.data.DATA;
        if (data) {
          this.$store.dispatch("category/addCategories", data);
        }
      } catch (err) {
        console.log(err);
      }
    },

    findCategoryMap(id) {
      const cat = this.categoryAllChildren.find((ct) => ct._id === id);
      debugger;
      if (cat) {
        this.categoryId = cat._id;
        this.ParentsMap = cat.ParentsMap;
        this.getMandatoryFields();
      }
    },
    //search product
    async searchProduct() {
      try {
        this.loading = true;
        await this.getAllCategory();
        let res = await ProductService.getProductById(this.product_id);
        let data = res.data.DATA;
        if (data) {
          const prodBasicInfo = data[0];
          // debugger;
          this.findCategoryMap(prodBasicInfo.category[0]);
          this.product_id = prodBasicInfo._id;
          this.product.name = prodBasicInfo.name;
          this.product.is_approved = prodBasicInfo.is_approved;
          this.product.active = prodBasicInfo.active;
          this.product.deleted = prodBasicInfo.deleted;

          res = await ProductService.searchSellerProduct(
            this.product_id,
            this.userInfo.id
          );
          data = res.data.DATA;
          if (data && data.length > 0) {
            this.sellerRelatedId = data[0]._id;
            const sellerInfo = data[0];
            this.seller = { ...sellerInfo };

            //set seller images
            sellerInfo.imgurl.forEach((url) => {
              this.imageUrlsSeller.push({
                url,
                file: null,
              });
            });
            //set seller variants images

            if (sellerInfo.product_attribute.length > 0) {
              sellerInfo.product_attribute.forEach((attr) => {
                let i = 0;
                attr.values[0].image_url.forEach((url) => {
                  this.seller.product_attribute[i].values[0].image_url.push({
                    url,
                    file: null,
                  });
                });
                i++;
              });

              this.seller.product_attribute.forEach((attr) => {
                attr.values[0].image_url = attr.values[0].image_url.filter(
                  (url) => typeof url !== "string"
                );
              });
            }
            // console.log("seller", this.seller);
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },

    async onSellerFilesSelect(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = "";
        this.$wkToast("Please Select file less than 1mb");
        return;
      }

      this.imageUrlsSeller.push({
        url: URL.createObjectURL(file),
        file,
      });
    },

    onDeleteSellerFiles(value) {
      let index = -1;
      for (let i = 0; i < this.imageUrlsSeller.length; i++) {
        if (this.imageUrlsSeller[i].url === value) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        this.imageUrlsSeller.splice(index, 1);
      }
    },

    addMoreSellerTierPrice() {
      if (
        !this.tierPrice.quantity ||
        !this.tierPrice.price ||
        this.tierPrice.quantity > 1000000 ||
        this.tierPrice.quantity < 1 ||
        this.tierPrice.price > 1000000 ||
        this.tierPrice.price < 1
      ) {
        return this.$wkToast(
          "Please fill tier price qauntity and price between 1 to 1000000"
        );
      }
      this.seller.tier_price.push({
        quantity: this.tierPrice.quantity,
        price: this.tierPrice.price,
      });
      this.tierPrice.quantity = null;
      this.tierPrice.price = null;
    },

    removeTierPriceFromSeller(index) {
      if (index > -1) {
        this.seller.tier_price.splice(index, 1);
      }
    },

    addMoreVariants() {
      // debugger;
      if (this.variantType === "" || this.variantValue === "") {
        return this.$wkToast("Please fill variant type and values");
      }
      if (this.variantType === "") {
        return this.$wkToast("Please fill variant type and value", warning);
      }

      let values = [];
      values.push({
        name: `${this.product.name}-${this.variantValue}`,
        seller_min_price: this.variantMinSalePrice,
        default_price: this.variantDefaultPrice,
        tier_price: this.variantTierPrice,
        image_url: [],
      });
      this.seller.product_attribute.push({
        name: this.variantType,
        description: this.variantValue,
        values,
      });
      this.variantType = "";
      this.variantValue = "";
      this.variantName = "";
      this.variantMinSalePrice = 1;
      this.variantDefaultPrice = 1;
      this.variantQauntity = 1;
      this.variantPrice = 1;
      this.variantTierPrice = [];
    },

    removeVariant(index) {
      if (index > -1) {
        this.seller.product_attribute.splice(index, 1);
      }
    },

    async submit() {
      if (!this.validated()) return;
      let created = "Created";
      try {
        this.loading = true;

        //upload images
        await this.uploadToS3();

        const prodPayload = {
          category_ids: [this.categoryId],
          name: this.product.name,
          is_approved: this.isAdmin,
          default_price: this.seller.default_price,
          imgurl: this.imageUrlsSeller,
          active: true,
          deleted: false,
          page_title: this.pageTitle || "title",
          page_keywords: this.pageKeywords || "keyword",
          page_description: this.pageDescription || "page desc",
          mandatoryFields:
            this.mandatoryFieldsInput.length > 0
              ? this.mandatoryFieldsInput
              : [],
        };

        const sellerPayload = {
          ...this.seller,
        };
        sellerPayload.imgurl = this.imageUrlsSeller;
        sellerPayload.vendor_id = this.userInfo.id;

        // console.log({ prodPayload });
        // console.log({ sellerPayload });

        //create product
        let res = null;

        if (this.isUpdate) {
          if (this.isAdmin) {
            res = await ProductService.updateProduct(
              this.product_id,
              prodPayload
            );
          }
          sellerPayload.product_id = this.product_id;
          if (this.sellerRelatedId === "") {
            res = await ProductService.createProductSeller(sellerPayload);
          } else {
            res = await ProductService.updateProductSeller(
              this.sellerRelatedId,
              sellerPayload
            );
          }
          created = "Updated";
        } else {
          res = await ProductService.createProduct(prodPayload);
          let data = res.data.DATA;
          const id = data._id;
          sellerPayload.product_id = id;
          res = await ProductService.createProductSeller(sellerPayload);

          if (this.draftId) {
            await ProductDraftService.deleteVendorDraftById(this.userInfo.id, this.draftId);
          }
        }

        //create product seller

        this.loading = false;
        this.completed = true;
        this.$wkToast(`Product ${created} Successfully`);
        this.$router.push("/productcatalog");
      } catch (err) {
        this.loading = false;
        this.completeWithIssue = true;
        this.$wkToast(`Product ${created} Failed`);
        // console.log(err);
      }
    },

    async uploadToS3() {
      // Seller Product images
      let filesSeller = [];
      const localUrl = [];
      this.imageUrlsSeller.forEach((image) => {
        if (image.file) filesSeller.push(image.file);
        else localUrl.push(image.url);
      });
      this.imageUrlsSeller = localUrl;
      for (let file of filesSeller) {
        let fileExtension = file.type.split("/")[1];
        if (fileExtension === "svg+xml") {
          fileExtension = "svg";
        }
        const s3NewFile = s3NewFileName();
        try {
          await s3Upload(file, s3NewFile);
        } finally {
          const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
          this.imageUrlsSeller.push(imageurl);
        }
      }
      //variants images
      // console.log({ filesSeller });
      if (this.seller.product_attribute.length > 0) {
        const files = [];
        let count = 0;
        let counter = [];
        this.seller.product_attribute.forEach((attr) => {
          // counter.push(count);
          let inCount = 0;
          attr.values[0].image_url.forEach((image) => {
            if (image.file) {
              counter.push({ count, inCount });
              files.push(image.file);
              inCount++;
            }
          });
          count++;
        });

        console.log({ files });
        console.log({ counter });

        this.seller.product_attribute.forEach((attr) => {
          attr.values[0].image_url = [];
        });

        let i = 0;
        for (var file of files) {
          let fileExtension = file.type.split("/")[1];
          if (fileExtension === "svg+xml") {
            fileExtension = "svg";
          }
          const s3NewFile = s3NewFileName();
          try {
            await s3Upload(file, s3NewFile);
          } finally {
            try {
              const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
              const ind = counter[i];
              this.seller.product_attribute[ind.count].values[0].image_url.push(
                imageurl
              );
              console.log("file pushed", i, ind.count);
              i++;
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    },

    validated() {
      if (
        this.product.name === "" ||
        !alphaNumericWithSpace.test(this.product.name) ||
        this.product.name.length > 100
      ) {
        this.$wkToast(
          "Please Enter Product Name Alphanumeric without any special character max length 100"
        );
        return false;
      }
      if (
        !this.seller.quantity ||
        this.seller.quantity > 1000000 ||
        this.seller.quantity < 1
      ) {
        this.$wkToast("Please Enter Product Quantity between 1 to  1000000");
        return false;
      }

      if (
        !this.seller.seller_min_price ||
        this.seller.seller_min_price > 1000000 ||
        this.seller.seller_min_price < 1
      ) {
        this.$wkToast("Please Enter Seller Minimum Price between 1 to 1000000");
        return false;
      }
      if (
        !this.seller.default_price ||
        this.seller.default_price > 1000000 ||
        this.seller.default_price < 1
      ) {
        this.$wkToast("Please Enter Seller Default Price between 1 to 1000000");
        return false;
      }
      if (
        !this.seller.max_retail_price ||
        this.seller.max_retail_price > 1000000 ||
        this.seller.max_retail_price < 1
      ) {
        this.$wkToast("Please Enter Max Retail Price between 1 to 1000000");
        return false;
      }

      if (
        this.seller.brand_name === "" ||
        !alphaNumericWithSpace.test(this.seller.brand_name) ||
        this.seller.brand_name > 100
      ) {
        this.$wkToast(
          "Please Enter Brand Name Alphanumeric without any special character"
        );
        return false;
      }
      if (
        this.seller.manufacturer === "" ||
        !alphaNumericWithSpace.test(this.seller.manufacturer) ||
        this.seller.manufacturer > 100
      ) {
        this.$wkToast(
          "Please Enter Manufacturer Alphanumeric without any special character"
        );
        return false;
      }
      if (
        this.seller.manufacturer_part_name === "" ||
        !alphaNumericWithSpace.test(this.seller.manufacturer_part_name) ||
        this.seller.manufacturer_part_name > 100
      ) {
        this.$wkToast(
          "Please Enter Manufacturer Part Name Alphanumeric without any special character"
        );
        return false;
      }
      if (!this.seller.ppu_count) {
        this.$wkToast("Please Enter PPU count");
        return false;
      }
      if (
        this.seller.ppu_count_unit_measure === "" ||
        !alphaNumericWithSpace.test(this.seller.ppu_count_unit_measure) ||
        this.seller.ppu_count_unit_measure > 100
      ) {
        this.$wkToast("Please Enter ppu Count UnitMeasure");
        return false;
      }
      if (this.seller.product_expirable === "") {
        this.$wkToast("Please Enter Product Expirable");
        return false;
      }
      if (this.seller.sku === "") {
        this.$wkToast("Please Enter SKU");
        return false;
      }
      if (this.seller.origin_country === "") {
        this.$wkToast("Please Enter Origin Country");
        return false;
      }
      if (this.seller.item_condition === "") {
        this.$wkToast("Please Enter Item Condition");
        return false;
      }
      if (this.seller.hsn_code === "") {
        this.$wkToast("Please Enter HSN code");
        return false;
      }
      if (this.seller.short_description === "") {
        this.$wkToast("Please Enter Product Short Description");
        return false;
      }
      if (this.seller.full_description === "") {
        this.$wkToast("Please Enter Product Full Description");
        return false;
      }
      if (this.imageUrlsSeller.length == 0) {
        this.$wkToast(
          `Please Enter Product Image${this.imageUrlsSeller.length}`
        );
        return false;
      }

      return true;
    },

    onVariantNameChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].name = value;
      // console.log({ value, index });
    },

    onVariantMinPriceChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].seller_min_price = value;
    },

    onVariantDefaultPriceChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].default_price = value;
    },

    onVariantImageChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].image_url = value;
    },

    onVariantTierPriceChanged({ value, index }) {
      this.seller.product_attribute[index].values[0].tier_price = value;
    },

    //save for drafts

    saveForDrafts() {
      return {
        id: Date.parse(new Date()),
        categoryId: this.categoryId,
        product: { ...this.product },
        seller: { ...this.seller },
        product_id: this.product_id,
        vendor_id: this.userInfo.id,
        date_modified: new Date().toLocaleDateString(),
        completeWithIssue: this.completeWithIssue,
      };
    },
  },

  beforeUnmount() {
    if (
      !this.isUpdate &&
      !this.completed &&
      this.product.name !== "" &&
      this.seller.sku !== ""
    ) {
      this.$store.dispatch("productDrafts/addToDrafts", this.saveForDrafts());
    }
  },
};
</script>

<style>
.ck-editor__editable {
  height: 250px;
}
</style>
