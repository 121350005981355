<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="document-field">
                        <p>All Sellers:</p>
                        <table
                          cellpadding="0"
                          cellspacing=""
                          class="seller-list"
                        >
                          <tr>
                            <th>#</th>
                            <th>Seller Name</th>
                            <th>Contact No</th>
                            <th>E-mail</th>
                            <th>Address</th>
                            <th>About Seller</th>
                            <th>Status</th>
                            <th width="7%">Profile</th>
                            <th width="7%">Action</th>
                          </tr>

                          <tr v-for="(user, index) in users" :key="user._id">
                            <td>{{ index + 1 }}.</td>
                            <td>{{ user.firstname + ' ' + user.lastname }}</td>
                            <td>{{ user.phonenumber }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.address }}</td>
                            <td>{{ user.about }}</td>
                            <td>
                              {{ user.is_approved ? 'Approved' : 'Pending' }}
                            </td>
                            <td>
                              <router-link :to="'/profile/' + user._id"
                                >view</router-link
                              >
                            </td>
                            <td>
                              <button
                                type=""
                                class="action-button "
                                :class="
                                  user.is_approved ? 'red-btn' : 'green-btn'
                                "
                                @click="
                                  updateSeller(
                                    user,
                                    !user.is_approved,
                                    user.deleted
                                  )
                                "
                              >
                                <i
                                  class="fa"
                                  :class="
                                    user.is_approved ? 'fa-times' : 'fa-check'
                                  "
                                  :title="
                                    !user.is_approved ? 'Approve' : 'DisApprove'
                                  "
                                ></i>
                              </button>
                              <button
                                class="action-button red-btn"
                                @click="
                                  updateSeller(
                                    user,
                                    user.is_approved,
                                    !user.deleted
                                  )
                                "
                              >
                                <i class="fa fa-trash" title="Reject"></i>
                              </button>
                              <!-- <button type="" class="action-button red-btn">
                                <i class="fa fa-trash" title="Delete"></i>
                              </button> -->
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
  <!-- End of Main -->
</template>
<script>
import SellerService from '../../services/seller-service';
import { success, warning } from '../../util/ToastUtil';
import Spinner from '../../components/Spinner.vue';

export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      sellers: [],
      status: false,
    };
  },
  computed: {
    users() {
      return this.sellers.filter((seller) => !seller.deleted);
    },
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      try {
        this.loading = true;
        const res = await SellerService.getAllSeller();
        this.sellers = res.data.DATA;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateSeller(seller, is_approved, deleted) {
      try {
        this.loading = true;
        const payload = {
          is_approved,
          deleted,
        };
        const res = await SellerService.updateSeller(seller._id, payload);
        if (res.status == 200) {
          this.$wkToast('Seller updated successfully', success);
          seller.is_approved = is_approved;
          seller.deleted = deleted;
        }
      } catch (err) {
        console.log(err);
        this.$wkToast('Seller updation failed', warning);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.seller-list {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.seller-list td,
.seller-list th {
  border: 1px solid #ddd;
  padding: 8px;
}
.seller-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #77abf0;
  color: white;
}
</style>
