<template>
  <div class="tab-pane active in" id="tab4-3">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>
    <div v-else-if="productionCapacity">
      <div
        class="row"
        v-for="key in Object.keys(productionCapacity)"
        :key="key"
      >
        <h4 class="title title-password ls-25 font-weight-bold col-md-6">
          {{ key.split(/(?=[A-Z])/).reduce((s, str) => s + str + ' ', '') }}
        </h4>

        <div
          class="row"
          v-for="value in productionCapacity[key]"
          :key="value.name"
        >
          <div class="pt-4 pb-4">
            <h6>
              {{
                value.name
                  .split(/(?=[A-Z])/)
                  .reduce((s, str) => s + str + ' ', '')
              }}
            </h6>
            <span>{{ value.des }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-15">Not Available</div>
  </div>
</template>

<script>
import ProfileService from '@/services/profile.service';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { Spinner },
  props: ['vendorId'],
  data() {
    return {
      loading: false,
      productionCapacityId: '',
      production: {
        ProductionEquipment: [
          { name: 'Name', des: 'Vacuum Reactor' },
          { name: 'No', des: 'Confidential' },
        ],
        FactoryInformation: [
          { name: 'Factory Size', des: '30,000-50,000 square meters' },
          {
            name: 'Factory Country/Region',
            des:
              'No. 1269, Chibi Ave., Chibi City, Xianning City, Hubei Province, China',
          },
        ],
        AnnualProductionCapacity: [
          { name: 'Product Name', des: 'Empty Capsules' },
          { name: 'Production Line Capacity', des: '45,000,000 Pcs Per Day' },
        ],
        ProductionLine: [
          {
            name: 'Production Line',
            des: 'Fully automatic Capsule Production Line',
          },
          { name: 'Supervisor', des: '6' },
        ],
      },
    };
  },
  computed: {
    productionCapacity() {
      if (this.productionCapacityBySellerId) {
        const { _id, customerid, ...co } = this.productionCapacityBySellerId;
        this.setProductionCapacityId(_id);
        return co;
      }

      return null;
    },
    productionCapacityBySellerId() {
      if (this.productionCapacities) {
        return this.productionCapacities[this.vendorId];
      }
      return null;
    },
    ...profileMapGetters(['productionCapacities']),
  },
  mounted() {
    if (this.productionCapacityId == '') {
      this.getProductionCapacity();
    }
  },
  methods: {
    setProductionCapacityId(id) {
      if (id) this.productionCapacityId = id;
    },
    getProductionCapacity() {
      this.loading = true;
      ProfileService.getProductionCapacity(this.vendorId)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let co = res.data.DATA;
          if (co) {
            const {
              created_on_utc_date,
              modification_notes,
              __v,
              deleted,
              ...cover
            } = co;
            this.$store.dispatch('companyProfile/addProductionCapacity', cover);
          } else {
            co = null; //this.production;
            debugger;
            co.customerid = this.vendorId;
            this.$store.dispatch('companyProfile/addProductionCapacity', co);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
