<template>
  <div
    class="product-form product-variation-form product-color-swatch"
    v-if="variants.color.length > 0"
  >
    <label>Color:</label>

    <div
      class="d-flex align-items-center product-variations"
      v-for="(variant, i) in variants.color"
      :key="i"
    >
      <a
        class="size "
        @click="updateVariant(variant)"
        :class="variant.activeClass ? 'isActive' : ''"
        :style="'background:' + variant.desc"
      >
      </a>
    </div>
  </div>
  <div
    class="product-form product-variation-form product-size-swatch"
    v-if="variants.size.length > 0"
  >
    <label class="mb-1">Size:</label>
    <div
      class="flex-wrap d-flex align-items-center product-variations"
      v-for="(variant, i) in variants.size"
      :key="i"
    >
      <button
        class="size "
        @click="updateVariant(variant)"
        :class="variant.activeClass ? 'isActive' : ''"
      >
        {{ variant.desc }}
      </button>
    </div>
  </div>

  <div
    class="product-form product-variation-form product-size-swatch"
    v-if="variants.packing.length > 0"
  >
    <label class="mb-1">Packing:</label>
    <div
      class="flex-wrap d-flex align-items-center product-variations"
      v-for="(variant, i) in variants.packing"
      :key="i"
    >
      <a
        class="size"
        @click="updateVariant(variant)"
        :class="variant.activeClass ? 'isActive' : ''"
        >{{ variant.desc }}</a
      >
    </div>
  </div>

  <div class="variation-price" v-if="price">
    <span>&#8377; {{ price }}</span>
  </div>
</template>

<script>
export default {
  props: ['product', 'index'],
  data() {
    return {
      price: null,
      isActive: false,
      activeIndex: null,
    };
  },
  computed: {
    variants() {
      const temp = {
        color: [],
        size: [],
        packing: [],
      };
      try {
        this.product?.Seller_Specific_Detail[
          this.index
        ]?.product_attribute.forEach((attr) => {
          if (attr.name.toLowerCase() === 'color') {
            temp.color.push({
              desc: attr.description,
              type: 'color',
              activeClass: false,
              ...attr.values[0],
            });
          }
          if (attr.name.toLowerCase() === 'size') {
            temp.size.push({
              desc: attr.description,
              type: 'size',
              activeClass: false,
              ...attr.values[0],
            });
          }
          if (attr.name.toLowerCase() === 'packing') {
            temp.packing.push({
              desc: attr.description,
              type: 'packing',
              activeClass: false,
              ...attr.values[0],
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
      console.log({ temp });
      return temp;
    },
  },
  mounted() {
    console.log('this.variants', this.variants);
    console.log(this.product);
  },
  methods: {
    updateVariant(variant) {
      this.price = variant.default_price;
      Object.keys(this.variants).forEach((k) => {
        this.variants[k].forEach((v) => {
          v.activeClass = v == variant;
        });
      });
      // variant.activeClass = true;
      let indices = -1;
      let i = 0;
      this.product['Seller_Specific_Detail'][
        this.index
      ].product_attribute.forEach((attr) => {
        if (attr.name === variant.type && attr.description === variant.desc) {
          indices = i;
          return;
        }
        i++;
      });
      this.$emit('changeVariant', indices);
    },
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
}

.variation-price {
  display: block;
}
.isActive {
  border: 2px solid;
  border-color: #2a9cf5;
  color: #2a9cf5;
}
.prod-variations {
  border: 1px solid rgb(157, 156, 248);
  padding: 10px;
}
</style>
