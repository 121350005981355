<template>
  <div class="tab-pane active" id="tab4-6">
    <div v-if="loading" class="loading-container">
      <spinner />
    </div>

    <div v-else-if="!self || (!showEdit && factoryInspectionId)">
      <div
        v-for="(key, index) in Object.keys(factoryinspectionReport)"
        :key="key"
      >
        <div class="row">
          <div class="col-md-6">
            <h5>{{ key }}</h5>
          </div>
          <div
            class="col-md-6 d-flex justify-content-end align-items-center"
            @click="showEdit = true"
            v-if="self && index == 0"
          >
            <i class="fas fa-edit "></i>
          </div>
        </div>
        <div class="row">
          <image-uploader
            v-for="url in factoryinspectionReport[key]"
            :key="url"
            :url="url"
            class="ml-4"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for=""><h5>Supplier Assesment</h5></label>
            <input
              type="file"
              id=""
              name=""
              class="form-control p-5"
              accept="image/*"
              @change="onFile1Select"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <image-uploader
          v-for="url in factoryinspectionReport['SupplierAssesment']"
          :key="url"
          :url="url"
          class="ml-4"
          showDelete="true"
          @delete-file="deleteFilefromFiles1"
        />
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="form-group">
            <label for=""
              ><h5>Main Product Lines Verification Report</h5></label
            >
            <input
              type="file"
              id=""
              name=""
              class="form-control p-5"
              accept="image/*"
              @change="onFile2Select"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <image-uploader
          v-for="url in factoryinspectionReport[
            'MainProductLinesVerificationReport'
          ]"
          :key="url"
          :url="url"
          class="ml-4"
          showDelete="true"
          @delete-file="deleteFilefromFiles2"
        />
      </div>
      <button
        type="button"
        class="btn btn-dark btn-rounded btn-sm mt-4 mb-4"
        @click="save"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ImageUploader from '@/components/util/ImageUploader.vue';
import ProfileService from '@/services/profile.service';
import { s3NewFileUrl, S3Client, s3NewFileName, s3Upload } from '@/util/s3upload';
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner.vue';
const { mapGetters } = createNamespacedHelpers('auth');
const profileMapGetters = createNamespacedHelpers('companyProfile').mapGetters;
export default {
  components: { ImageUploader, Spinner },
  data() {
    return {
      showEdit: false,
      loading: false,
      factoryInspectionId: '',
      sellerId: this.$route.params.id,
      ireports: {
        SupplierAssesment: [],
        MainProductLinesVerificationReport: [],
      },
      files1: [],
      files2: [],
      fileUrls1: [],
      fileUrls2: [],
      imageurl: '',
      newFIRUrls: [],
    };
  },
  computed: {
    self() {
      return this.sellerId === this.userInfo.id;
    },
    factoryinspectionReport() {
      if (this.factoryInspectionReportBySellerId) {
        const {
          _id,
          imgurl,
          customerid,
          ...co
        } = this.factoryInspectionReportBySellerId;
        this.setfactoryInspectionId(_id);
        return co;
      }

      return {};
    },
    factoryInspectionReportBySellerId() {
      if (this.factoryInspectionReports) {
        return this.factoryInspectionReports[this.sellerId || this.userInfo.id];
      }
      return null;
    },
    ...mapGetters(['userInfo']),
    ...profileMapGetters(['factoryInspectionReports']),
  },
  mounted() {
    if (this.factoryInspectionId == '') {
      this.getFactoryInspecction();
    }
  },
  methods: {
    setfactoryInspectionId(id) {
      if (id) this.factoryInspectionId = id;
    },
    async getFactoryInspecction() {
      try {
        this.loading = true;
        const res = await ProfileService.getFactoryInspecctionBySellerId(
          this.sellerId || this.userInfo.id
        );
        console.log({ res });
        let co = res.data.DATA;
        if (co) {
          const {
            created_on_utc_date,
            modification_notes,
            __v,
            deleted,
            ...cover
          } = co;
          this.$store.dispatch(
            'companyProfile/addFactoryInspectionReport',
            cover
          );
        } else {
          co = { ...this.ireports };
          co.customerid = this.sellerId || this.userInfo.id;
          this.$store.dispatch('companyProfile/addFactoryInspectionReport', co);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    onFile1Select(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = '';
        this.$wkToast('Please Select file less than 1mb');
        return;
      }
      this.files1.push(file);
      const url = URL.createObjectURL(file);
      this.fileUrls1.push(url);
      this.factoryinspectionReport['SupplierAssesment'].push(url);
    },
    onFile2Select(e) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.target.value = '';
        this.$wkToast('Please Select file less than 1mb');
        return;
      }
      this.files2.push(file);
      const url = URL.createObjectURL(file);
      this.fileUrls2.push(url);
      this.factoryinspectionReport['MainProductLinesVerificationReport'].push(
        url
      );
    },
    deleteFilefromFiles1(value) {
      let index = this.factoryinspectionReport['SupplierAssesment'].indexOf(
        value
      );
      if (index > -1)
        this.factoryinspectionReport['SupplierAssesment'].splice(index, 1);
      index = this.fileUrls1.indexOf(value);
      if (index > -1) {
        this.files1.splice(index, 1);
        this.fileUrls1.splice(index, 1);
      }
    },
    deleteFilefromFiles2(value) {
      let index = this.factoryinspectionReport[
        'MainProductLinesVerificationReport'
      ].indexOf(value);
      if (index > -1)
        this.factoryinspectionReport[
          'MainProductLinesVerificationReport'
        ].splice(index, 1);
      index = this.fileUrls2.indexOf(value);
      if (index > -1) {
        this.files2.splice(index, 1);
        this.fileUrls2.splice(index, 1);
      }
    },

    validated() {
      if (
        !this.factoryinspectionReport['SupplierAssesment'].length ||
        !this.factoryinspectionReport['MainProductLinesVerificationReport']
          .length
      ) {
        this.$wkToast('Please select any assessment reports');
      }
    },
    async save() {
      if (!this.validated()) {
        return;
      }
      try {
        this.loading = true;

        this.factoryinspectionReport[
          'SupplierAssesment'
        ] = this.factoryinspectionReport['SupplierAssesment'].filter(
          (s) => !s.includes('localhost')
        );
        this.factoryinspectionReport[
          'MainProductLinesVerificationReport'
        ] = this.factoryinspectionReport[
          'MainProductLinesVerificationReport'
        ].filter((s) => !s.includes('localhost'));

        await this.uploadToS3();

        if (
          this.factoryinspectionReport['SupplierAssesment'].length ||
          this.factoryinspectionReport['MainProductLinesVerificationReport']
            .length
        ) {
          const payload = {
            customerid: this.userInfo.id,
            ...this.factoryinspectionReport,
          };
          console.log({ payload });
          if (!this.factoryinspectionReport || this.factoryInspectionId == '')
            await ProfileService.createFactoryInspection(payload);
          else
            await ProfileService.updateFactoryInspection(
              payload,
              this.factoryInspectionId
            );
          this.getFactoryInspecction();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.showEdit = false;
    },
    async uploadToS3() {
      for (let file of this.files1) {
        let fileExtension = file.type.split("/")[1];
        if (fileExtension === "svg+xml") {
          fileExtension = "svg";
        }
        const s3NewFile = s3NewFileName();
        try {
          await s3Upload(file, s3NewFile);
        } finally {
          const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
          this.factoryinspectionReport['SupplierAssesment'].push(imageurl);
        }
      }
      for (const file of this.files2) {
        let fileExtension = file.type.split("/")[1];
        if (fileExtension === "svg+xml") {
          fileExtension = "svg";
        }
        const s3NewFile = s3NewFileName();
        try {
          await s3Upload(file, s3NewFile);
        } finally {
          const imageurl = `${s3NewFileUrl}/${s3NewFile}.${fileExtension}`;
          this.factoryinspectionReport[
            'MainProductLinesVerificationReport'
          ].push(imageurl);
        }
      }
    },
  },
};
</script>

<style scoped>
.loading-container {
  width: 70vw;
  height: 100vh;
  background-color: #3f373700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
