<template>
  <!-- Start of Main-->
  <main class="main">
    <div v-if="loading" class="loading-container-full">
      <spinner />
    </div>
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="category-panel">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="document-field">
                        <p>New Category Request:</p>
                        <table
                          cellpadding="0"
                          cellspacing=""
                          class="seller-list"
                        >
                          <tr>
                            <th>#</th>
                            <th>Category</th>
                            <th width="15%">Description</th>
                            <th width="7%">Status</th>
                            <th width="7%">Action</th>
                          </tr>

                          <tr
                            v-for="(req, index) in filteredRequest"
                            :key="req._id"
                          >
                            <td>{{ index + 1 }}.</td>
                            <td>{{ req.category_name }}</td>
                            <td>{{ req.category_description }}</td>
                            <td style="text-transform: capitalize">
                              {{ req.is_approved ? "Approved" : req.status }}
                            </td>
                            <td>
                              <!-- <button
                                type="button"
                                class="action-button green-btn"
                                @click="updateRequest(req, true, false)"
                              > -->
                              <button
                                type="button"
                                class="action-button green-btn"
                                @click="openModal(req)"
                              >
                                <i class="fa fa-eye" title="Approved"></i>
                              </button>
                              <!-- <button
                                type="button"
                                class="action-button red-btn"
                                @click="update"
                              >
                                <i class="fa fa-times" title="Rejected"></i>
                              </button> -->
                              <button
                                type="button"
                                class="action-button red-btn"
                                @click="updateRequest(req, false, true)"
                              >
                                <i class="fa fa-trash" title="Delete"></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- start model -->
    <Modal :model-value="modelShow" :close="closeModal">
      <div class="modal">
        <div class="row">
          <div class="col-md-12">
            <h5 class="modal-title">Verify Seller Category Requests</h5>
          </div>
          <div class="col-md-12 text-left">
            <p><b>Name</b>: {{ modelData.category_name }}</p>
            <p><b>Description</b> : {{ modelData.category_description }}</p>
            <div class="d-flex flex-column mb-2">
              <div class="cat-heading">Suggest Category</div>
              <treeselect
                v-model="value"
                :multiple="false"
                :options="options"
                placeholder="Selec a category"
                noChildrenText="No Sub-Category"
                @select="onSelectCategory"
              />
            </div>
            <p v-if="mapping != ''"><b>Category</b> : {{ mapping }}</p>
            <div class="d-flex flex-column mb-2">
              <label>Rejected Reason</label>
              <textarea
                class="form-control"
                rows="2"
                v-model="reject_reason"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                @click="updateRequest(modelData, false, false, 'reject')"
              >
                Reject
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="updateRequest(modelData, true, false, 'accept')"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!-- end model -->
  </main>
  <!-- End of Main -->
</template>

<script>
import CategoryService from "../../services/category-service";
import { success, warning } from "../../util/ToastUtil";
import Spinner from "../../components/Spinner.vue";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("category");

export default {
  components: { Treeselect, Spinner },
  data() {
    return {
      loading: false,
      categoryRequests: [],
      status: false,
      modelShow: false,
      modelData: {},
      mappingCategory: "",
      reject_reason: "",
      value: "",
    };
  },
  computed: {
    ...mapGetters(["categories"]),
    filteredRequest() {
      return this.categoryRequests.filter(
        (cr) => !cr.is_approved && !cr.deleted
      );
    },
    mapping() {
      this.resetMappingCategory();
      if (this.value) this.getAllParentsOfCategory(this.value);
      return this.mappingCategory;
    },
    options() {
      let x = this.categories.map((ct) => ct);
      return this.getAllCatChild(x);
      // let y = [];
      // let z = [];
      // for (let i = 0; i < x.length; i++) {
      //   x[i].children = [];
      //   x[i].id = x[i]._id;
      //   x[i].label = x[i].name;
      //   if (x[i].is_main_category || !x[i].parent_category_id) {
      //     x[i].parent_category_id = "0";
      //   }
      //   if (x[i].parent_category_id === x[i]._id) continue;
      //   if (x[i]._id === this.selectCat) continue;
      //   if (x[i].is_main_category) {
      //     y.push(x[i]);
      //   } else {
      //     z.push(x[i]);
      //   }
      // }
      // z.forEach((item) => {
      //   this.insert(y, item);
      // });
      // console.log({ y });
      // return y;
    },
  },
  mounted() {
    this.getAllCategoryRequest();
  },
  methods: {
    getAllCatChild(data){
      var result = [];
      for (var par of data) {
        if (par.is_main_category) {
          result.push({ ...par, children: this.get_childrens(data, par), id: par._id, label: par.name });
        }
      }
      return result;
    },
    get_childrens(data, item) {
      return data.filter((v) => v.parent_category_id === item._id).map((x) => {
        return data.filter((v) => v.parent_category_id === x._id).length > 0 ? { ...x, id: x._id, label: x.name, children: this.get_childrens(data, x) } : { ...x, id: x._id, label: x.name, children: [] };
      })
    },
    resetMappingCategory() {
      this.mappingCategory = "";
    },
    getAllParentsOfCategory(id) {
      if (!id || id === "0") return;
      let found = false;
      let ct = {};
      for (let i = 0; i < this.categories.length; i++) {
        ct = this.categories[i];
        if (ct._id === id) {
          this.mappingCategory !== ""
            ? (this.mappingCategory = `${ct.name} >> ${this.mappingCategory}`)
            : (this.mappingCategory = ct.name);
          found = true;
          break;
        }
      }
      if (found) this.getAllParentsOfCategory(ct.parent_category_id);
    },
    getChildrenCategories(categoryId) {
      return this.categories.filter((ct) => {
        if (ct.parent_category_id && ct.parent_category_id === categoryId) {
          ct.hasChildren = true;
          return true;
        } else {
          ct.hasChildren = false;
          return false;
        }
      });
    },
    closeModal() {
      this.modelShow = false;
    },
    openModal(data) {
      this.modelData = data;
      this.modelShow = true;
      this.mappingCategory = "";
      this.value = "";
    },
    async getAllCategoryRequest() {
      try {
        this.loading = true;
        const res = await CategoryService.getAllCategoryRequests();
        console.log({ res });
        this.categoryRequests = res.data.DATA;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateRequest(req, is_approved, deleted, status = "") {
      if (is_approved && !this.mappingCategory) {
        this.$wkToast("Select Category for suggessions!", warning);
      } else if (status == "reject" && this.reject_reason == '') {
        this.$wkToast("Please write rejected reason!", warning);
      } else {
        this.loading = true;
        try {
          const res = await CategoryService.updateCategoryRequest(req._id, {
            is_approved,
            deleted,
            status,
            suggestion: is_approved ? this.mappingCategory : this.reject_reason,
          });
          if (res.status == 200) {
            this.closeModal();
            this.$wkToast("Category request updated succesfully", success);
            await this.getAllCategoryRequest();
          } else {
            this.$wkToast("Category request updation failed", warning);
          }
        } catch (err) {
          console.log(err);
        }
        this.loading = false;
      }
    },
    insert(arr, value) {
      arr.forEach((item) => {
        if (item) {
          if (item._id === value.parent_category_id) {
            if (item["children"]) item["children"] = [];
            item["children"].push(value);
            // return false;
          } else {
            if (item["children"] && item["children"].length > 0)
              this.insert(item["children"], value);
            // return true;
          }
        }
      });
    },
    onSelectCategory() {
      const id = this.value;
      const cat = this.categories.find((c) => c._id === id);
      if (cat) {
        const requiredFields = cat.mandatory_for_product.map((item) => {
          return {
            required_field_name: item.required_field_name,
            required_field_type: item.required_field_type,
          };
        });

        const requiredDocuments = cat.mandatory_for_seller.map((item) => {
          return {
            required_field_name: item.required_field_name,
            required_field_type: item.required_field_type,
          };
        });
        this.requiredFields = requiredFields;
        this.requiredDocuments = requiredDocuments;
      }
    },
  },
};
</script>
<style scoped>
.seller-list {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.seller-list td,
.seller-list th {
  border: 1px solid #ddd;
  padding: 8px;
}

/* .seller-list tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* .seller-list tr:hover {
  background-color: #ddd;
} */

.seller-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #77abf0;
  color: white;
}
.modal {
  width: 400px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  text-align: center;
}
</style>
