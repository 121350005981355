<template>
  <main class="main">
    <div class="container">
      <div class="cart">
        <div class="row">
          <section class="side-tab-section">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <div class="tab tab-vertical tab-nav-solid">
                  <div class="tab-content" style="margin-left: 10%">
                    <div class="tab-pane active in">
                      <form
                        class="form account-details-form"
                        @submit="onSubmit"
                      >
                        <div class="row">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label for="csv">Upload CSV File *</label>
                              <input
                                type="file"
                                id="csv"
                                name=""
                                class="form-control p-5"
                                accept=".csv"
                                @change="onFileSelect"
                                required
                              />
                            </div>
                            <div>
                              <a href="/wmt_seller_product.csv">Click here</a>
                              to download sample csv file for product upload.
                            </div>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="submit"
                              class="
                                mt-4
                                w-100
                                btn btn-dark btn-rounded btn-sm
                                mb-4
                              "
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="tab-pane active in">
                      <ul
                        v-if="errors.length > 0"
                        class="list-group list-group-flush"
                      >
                        <li
                          v-for="(err, index) in errors"
                          :key="index"
                          class="list-group-item"
                        >
                          <b>Row {{ err.row }}:</b> &nbsp;
                          <span v-html="err.message"></span>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-pane active in">
                      <table cellpadding="0" cellspacing="0">
                        <tr>
                          <th>S. No.</th>
                          <th>Category</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Short Description</th>
                          <th>Full Description</th>
                          <th>Image</th>
                        </tr>
                        <tr v-if="!results || results?.length == 0">
                          <td>1</td>
                          <td>Covid</td>
                          <td>Covishield</td>
                          <td>15</td>
                          <td>250.00</td>
                          <td>product short desc...</td>
                          <td>product full desc...</td>
                          <td><img src="/no-image.jpg" /></td>
                        </tr>
                        <tr v-for="(res, index) in results" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ res.category }}</td>
                          <td>{{ res.product_name }}</td>
                          <td>{{ res.quantity }}</td>
                          <td>{{ res.default_price }}</td>
                          <td>{{ res.short_description }}</td>
                          <td>{{ res.full_description }}</td>
                          <td><img :src="res.images || '/no-image.jpg'" /></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Papa from "papaparse";
import ProductDraftService from "../../../services/product-draft-service";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("auth");

export default {
  data() {
    return {
      results: [],
      required_field: [
        "category",
        "product_name",
        "quantity",
        "default_price",
        "seller_min_price",
        "max_retail_price",
        "brand_name",
        "manufacturer",
        "manufacturer_part_name",
        "ppu_count",
        "ppu_count_unit_measure",
        "product_expirable",
        "sku",
        "origin_country",
        "item_condition",
        "short_description",
        "full_description",
        "page_title",
        "page_keywords",
        "page_description",
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    errors() {
      let ers = this.results.map((v, i) => {
        var err = [];
        for (const key in v) {
          if (
            this.required_field.includes(key) &&
            v[key].toString().trim() == ""
          ) {
            err.push(`Column <b>${key}</b> is required!`);
          }
        }
        return err.length > 0 ? { row: i + 1, message: err.join(" | ") } : null;
      });
      return ers.filter(n => n);
    },
  },
  methods: {
    onFileSelect(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.results = Papa.parse(reader.result, {
            header: true,
            skipEmptyLines: true,
          }).data;
          // console.log(this.results);
        };
        reader.readAsText(file);
      } else {
        this.results = [];
      }
    },

    onSubmit(e) {
      e.preventDefault();
      console.log(this.errors);
      if (this.results.length > 0 && this.errors.length == 0) {
        ProductDraftService.uploadCsvData(this.userInfo.id, {
          data: this.results,
        })
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.$router.push({
                path: "/product-drafts",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
