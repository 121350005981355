<template>
  <div class="product-gallery mt-4">
    <div class="" v-if="images">
      <!-- <img :src="image" :data-zoom-image="image" alt="" class="zoom-image" /> -->
      <!-- <image-magnifier
        :src="images[selectedIndex]"
        :zoom-src="images[selectedIndex]"
        width="400"
        height="300"
        zoom-width="400"
        zoom-height="300"
      /> -->
      <!-- <div class="pic-box"> -->
      <pic-zoom :url="images[selectedIndex]" :scale="2"></pic-zoom>
      <!-- </div> -->
    </div>
    <!-- <div class=" d-flex">
      <button class="thumb-up">
        <i class="w-icon-angle-left"></i>
      </button>
      <div class="product-thumbs row cols-4 gutter-sm">
        <div v-for="(image, index) in images" :key="image">
          <div
            :class="selectedIndex == index ? ' active' : ''"
            @click.prevent="activate(index)"
          >
            <img :src="image" alt="" width="50" height="50" />
          </div>
        </div>
      </div>

      <button class="thumb-up">
        <i class="w-icon-angle-right"></i>
      </button>
    </div> -->

    <carousel :items-to-show="4" :wrapAround="false">
      <slide v-for="(slide, index) in images" :key="slide">
        <div
          :class="selectedIndex == index ? ' active' : ''"
          @click.prevent="activate(index)"
        >
          <img :src="slide" alt="" />
        </div>
      </slide>
      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import PicZoom from '@/components/util/PicZoom.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
export default {
  components: {
    // ImageMagnifier,
    PicZoom,
    Carousel,
    Slide,
    Navigation,
  },
  props: ['images'],
  data() {
    return {
      selectedIndex: 0,
      vcClrPrimary: 'blue',
      vcClrWhite: 'blue',
    };
  },
  methods: {
    activate(value) {
      this.selectedIndex = value;
    },
  },
  mounted() {
    console.log('images', this.images);
  },
};
</script>

<style scoped>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid rgb(199, 26, 26);
}
/* .zoom-image {
  width: 30px;
  height: 30px;
} */
.product-image {
  width: 400px;
  height: max-content;
}
.thumbs {
  width: 400px;
  height: 100px;
}
.active {
  border: 1px;
  border-style: solid;
  border-color: #2a9cf5;
}
.thumb-up {
  background: transparent;
  border: none;
}
</style>
