<template>
  <main class="main">
    <div class="container-small">
      <div class="cart">
        <div class="row">
          <div class="col-md-6 mb-4">
            <p class="font-14 mt-10 mb-1">Have a Prescription? Upload here</p>
            <div class="row">
              <div class="col-md-12 mb-4">
                <div class="fileUpload btn btn-primary">
                  <span><i class="fa fa-camera"></i> Add New</span>
                  <input
                    type="file"
                    class="upload"
                    ref="file"
                    @change="onFileSelect"
                  />
                </div>
              </div>
              <div class="col-6 col-md-4 mb-4" v-if="urls.length">
                <button type="submit" class="prescription-upload">
                  <i class="fa fa-check"></i>
                </button>
                <img :src="urls[urls.length - 1]" class="prescription-img" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <section class="color-accordion-section mt-10">
              <div class="row">
                <div class="col-md-12 mb-md-1">
                  <div
                    class="accordion accordion-bg accordion-gutter-md accordion-border"
                  >
                    <div class="card">
                      <div class="card-header">
                        <a href="#collapse5-1" class="expand"
                          >Valid Prescription Guide</a
                        >
                      </div>
                      <div
                        class="card-body collapsed"
                        id="collapse5-1"
                        style="display: none;"
                      >
                        <p class="mb-0">
                          Fringilla urna porttitor rhoncus dolor purus. Luctus
                          venenatis rhoncus dolor lectus Diam maecenas ultricies
                          mi eget mauris. Nibh tellus rhoncus dolormolestie nunc
                          Ultrices eros in cursus rhoncus dolorturpis massa
                          tincidunt. Ante in nibh mauri lectus. Etiam non quam
                          lacus suspendisse faucibus.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <p class="font-13">
              Government regulations require a valid prescription
            </p>
            <div class="attached-prescription mt-2">
              <h5>Attached Prescriptions <span class="pull-right">1</span></h5>
              <div class="row">
                <div
                  class="col-6 col-md-4 mb-4"
                  v-for="(url, index) in urls"
                  :key="url"
                >
                  <button
                    type="submit"
                    class="prescription-close"
                    @click="deleteFiles(index)"
                  >
                    <!-- <i class="fa fa-window-close"></i> -->
                    X
                  </button>
                  <img :src="url" class="prescription-img" />
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn-continue mt-4 mb-10"
              @click="continuePrescription"
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      urls: [],
    };
  },
  methods: {
    onFileSelect(e) {
      debugger;
      const file = e.target.files[0];
      this.urls.push(URL.createObjectURL(file));
    },
    deleteFiles(index) {
      this.urls.splice(index, 1);
    },
    continuePrescription() {
      if (!this.urls.length) alert('please select prescription');
      else this.$router.push('/cart/address');
    },
  },
};
</script>

<style></style>
